import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import isEqual from 'react-fast-compare';
import isMapEqual from 'fast-deep-equal/es6'
import { ErrorService } from '../services/Error.service';
import { useSnackbar } from 'notistack';

type AutosaveProps<T> = {
    debounceMs?: number;
    initialAttachments?: Map<string, File | null>;
    currentAttachments?: Map<string, File | null>;
    initialMark?: number;
    currentMark?: number;
    submitting: boolean;
    formReadonly?: boolean;
    onSave: (formData: T, attachments: { key: string; value: File | null; }[], mark?: number) => Promise<void>;
};

export const Autosave = <T,>({
    debounceMs = 3000,
    initialAttachments,
    currentAttachments,
    initialMark,
    currentMark,
    submitting,
    formReadonly,
    onSave
}: AutosaveProps<T>): JSX.Element => {
    const [formValues, setFormValues] = useState<T | undefined>();
    const [attachments, setAttachments] = useState<Map<string, File | null>>();
    const [mark, setMark] = useState<number>();

    const formContext: FormikContextType<T> = useFormikContext<T>();
    const { enqueueSnackbar } = useSnackbar();

    const enqueueErrorSnackbar = (serverError: string) => enqueueSnackbar(serverError, {
        variant: 'error',
        autoHideDuration: 5000
    });

    const debouncedSave = useRef(
        useCallback(
            debounce(async (formValues: T, currentAttachments?: Map<string, File | null>, currentMark?: number) => {
                try {
                    const currentAttachmentsArray = Array.from(currentAttachments ?? [])
                        .map(([key, value]) => ({ key, value }));
                    const attachmentsToSave = currentAttachmentsArray.filter(x => Boolean(x.value));

                    await onSave(formValues, attachmentsToSave, currentMark);
                    
                    setFormValues(formValues);
                    currentAttachments && setAttachments(new Map(currentAttachments));
                    currentMark && setMark(currentMark);
                }
                catch (error) {
                    enqueueErrorSnackbar(ErrorService.parseError(error));
                }
            }, debounceMs),
            [onSave, debounceMs]
        )
    ).current;

    useEffect(() => setFormValues(formContext?.initialValues), [formContext?.initialValues]);
    useEffect(() => setAttachments(initialAttachments), [initialAttachments]);
    useEffect(() => setMark(initialMark), [initialMark]);

    useEffect(() => {
        const formValuesChanged = !formReadonly && !formContext?.isSubmitting && (formContext?.dirty || (formValues && !isEqual(formContext?.values, formValues)));
        const formAttachmentsChanged = attachments && !isMapEqual(currentAttachments, attachments);
        const markChanged = initialMark !== undefined && mark !== undefined && currentMark != mark;

        if (!submitting && (formValuesChanged || formAttachmentsChanged || markChanged)) {
            const mark = markChanged ? currentMark : undefined;
            debouncedSave(formContext?.values, currentAttachments, mark);
        }
        else {
            debouncedSave.cancel();
        }

        return debouncedSave.cancel;
    }, [debouncedSave, formContext?.values, currentAttachments, currentMark, submitting]);

    return (<></>);
};
