import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { FinanceCoordinatorRoute } from '../../routes/FinanceCoordinatorRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { FinanceCoordinatorCompetitionsScreen } from '../FinanceCoordinator/FinanceCoordinatorCompetitionsScreen';
import { CompetitionRankingListScreen } from '../Common/CompetitionRankingListScreen';
import { FinanceCoordinatorSubmissionDetailsScreen } from '../FinanceCoordinator/FinanceCoordinatorSubmissionDetailsScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const FinanceCoordinatorScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <FinanceCoordinatorRoute exact path={`${match.path}/competitions`} component={FinanceCoordinatorCompetitionsScreen} />
        <FinanceCoordinatorRoute
          exact
          path={`${match.path}/competitions/:id/ranking-list`}
          component={CompetitionRankingListScreen}
        />
        <FinanceCoordinatorRoute exact path={`${match.path}/submissions/:id`} component={FinanceCoordinatorSubmissionDetailsScreen} />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const FinanceCoordinatorScreens = withStyles(styles)(FinanceCoordinatorScreensComponent);
