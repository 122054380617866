import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { useTranslator } from '../i18n/useTranslator';
import { Link } from './Link';
import { RootState } from '../store/rootReducer';
import { UserService } from '../services/User.service';

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '0.8125rem',
    },
    icon: {
      marginRight: 15,
      fontSize: 35,
    },
    profile: {
      fontWeight: 600,
      color: palette.common.white,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    logout: {
      fontWeight: 500,
      fontSize: 12,
      marginTop: 5,
      cursor: 'pointer',
      color: palette.common.white,
      display: 'block',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps & {
    className?: string;
  };

const SidebarUserAvatarComponent: React.FunctionComponent<Props> = ({ classes, className, history }) => {
  const { firstName, lastName } = useSelector((state: RootState) => state.user);

  const { t } = useTranslator();

  const handleLogout = async () => {
    await UserService.logout();

    history.push('/login');
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Link className={classes.profile} to={'/profile'}>
        <AccountCircle className={classes.icon} />
      </Link>
      <div>
        <Link className={classes.profile} to={'/profile'}>
          {`${firstName} ${lastName}`}
        </Link>
        <div className={classes.logout} onClick={handleLogout}>
          {t('logout')}
        </div>
      </div>
    </div>
  );
};

export const SidebarUserAvatar = withStyles(styles)(withRouter(SidebarUserAvatarComponent));
