import { SubmissionStatusEnum } from '../enums/SubmissionStatusEnum';

export const getSubmissionStatusLabel = (submissionStatus: SubmissionStatusEnum) => {
  switch (submissionStatus) {
    case SubmissionStatusEnum.Draft:
      return 'submissionDraft';
    case SubmissionStatusEnum.New:
      return 'submissionNew';
    case SubmissionStatusEnum.Invalid:
      return 'submissionInvalid';
    case SubmissionStatusEnum.Rejected:
      return 'submissionRejected';
    case SubmissionStatusEnum.Review:
      return 'submissionReview';
    case SubmissionStatusEnum.Won:
      return 'submissionWon';
    case SubmissionStatusEnum.Closed:
      return 'submissionClosed';
  }

  return '';
};
