import React from 'react';
import { Formik, FormikConfig } from 'formik';

type Props<Values> = FormikConfig<Values>;

export class Form<Values> extends React.Component<Props<Values>> {
  render(): React.ReactNode {
    const { children, ...restProps } = this.props;

    return <Formik {...restProps}>{children}</Formik>;
  }
}
