import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import Color from 'color';
import { useSelector } from 'react-redux';

import { ReactComponent as Logo } from '../assets/nfp-logo.svg';
import { SidebarUserAvatar } from './SidebarUserAvatar';
import { AdminSidebarContent } from './sidebar-content/AdminSidebarContent';
import { AuthorSidebarContent } from './sidebar-content/AuthorSidebarContent';
import { RootState } from '../store/rootReducer';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { ReviewerSidebarContent } from './sidebar-content/ReviewerSidebarContent';
import { ScientificCouncilMemberSidebarContent } from './sidebar-content/ScientificCouncilMemberSidebarContent';
import { FinanceCoordinatorSidebarContent } from './sidebar-content/FinanceCoordinatorSidebarContent';
import { BoardMemberSidebarContent } from './sidebar-content/BoardMemberSidebarContent';
import { CompetitionCoordinatorSidebarContent } from './sidebar-content/CompetitionCoordinatorSidebarContent';
import { CompetitionCommitteeMemberSidebarContent } from './sidebar-content/CompetitionCommitteeMemberSidebarContent';
import { CompetitionJuryMemberSidebarContent } from './sidebar-content/CompetitionJuryMemberSidebarContent';

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      background: 'linear-gradient(to bottom, #054895 0%,#73acd7 100%)',
      display: 'flex',
      flexDirection: 'column',
      color: palette.common.white,
    },
    userAvatar: {
      position: 'absolute',
      bottom: 0,
      paddingTop: 20,
      paddingBottom: 20,
      marginLeft: 20,
      marginRight: 20,
      width: 'calc(100% - 40px)',
      borderTop: `1px solid ${Color(palette.common.white).alpha(0.3).rgb().string()}`,
    },
    logo: {
      width: '100%',
      height: 'auto',
      maxWidth: 140,
      marginTop: 20,
      marginLeft: 20,
    },
    content: {
      marginTop: 40,
      marginBottom: 100,
      overflowY: 'auto',
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
};

const SidebarComponent: React.FunctionComponent<Props> = ({ classes, className }) => {
  const { role } = useSelector((state: RootState) => state.user);

  const getSidebarContent = () => {
    if (role === RoleEnum.Admin) {
      return <AdminSidebarContent />;
    }

    if (role === RoleEnum.CompetitionCoordinator) {
      return <CompetitionCoordinatorSidebarContent />;
    }

    if (role === RoleEnum.Author) {
      return <AuthorSidebarContent />;
    }

    if (role === RoleEnum.Reviewer) {
      return <ReviewerSidebarContent />;
    }

    if (role === RoleEnum.ScientificCouncilMember) {
      return <ScientificCouncilMemberSidebarContent />;
    }

    if (role === RoleEnum.CompetitionCommitteeMember) {
      return <CompetitionCommitteeMemberSidebarContent />;
    }

    if (role === RoleEnum.CompetitionJuryMember) {
      return <CompetitionJuryMemberSidebarContent />;
    }

    if (role === RoleEnum.FinanceCoordinator) {
      return <FinanceCoordinatorSidebarContent />;
    }

    if (role === RoleEnum.BoardMember) {
      return <BoardMemberSidebarContent />;
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Logo className={classes.logo} />
      <div className={classes.content}>{getSidebarContent()}</div>
      <SidebarUserAvatar className={classes.userAvatar} />
    </div>
  );
};

export const Sidebar = withStyles(styles)(SidebarComponent);
