import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import { useTranslator} from '../i18n/useTranslator';
import classNames from 'classnames';

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      padding: '0px 0px 0px 0px',
      color: 'gray'
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
};

const RodoMessageComponent: React.FunctionComponent<Props> = ({ classes, className }) => {
  const { t } = useTranslator();

  return(
    <div className={classNames(classes.root, className)}>
        <a href="https://polpharma.pl/naukowa-fundacja-polpharmy/fundacja-polityka-prywatnosci/#!polityka-prywatnosci-rodo" target="_blank" rel="noopener noreferrer">{t('iTerms')}</a>
    </div>
  )
};

export const RodoMessage = withStyles(styles)(RodoMessageComponent);
