import { ApiService } from './Api.service';
import { ApiResponse } from '../shared/types/ApiResponse';
import { NotificationTemplate } from '../shared/types/NotificationTemplate';
import { NotificationTemplateFormData } from '../shared/types/NotificationTemplateFormData';

export const NotificationTemplateService = {
  resource: 'NotificationTemplates',
  getAll: () => {
    return ApiService.get<ApiResponse<Array<NotificationTemplate>>>(`${NotificationTemplateService.resource}`, { withCredentials: true });
  },
  getOne: (id: NotificationTemplate['id']) => {
    return ApiService.get<ApiResponse<NotificationTemplate>>(`${NotificationTemplateService.resource}/${id}`, { withCredentials: true });
  },
  update: (id: NotificationTemplate['id'], data: NotificationTemplateFormData) => {
    return ApiService.put<ApiResponse<NotificationTemplate>>(`${NotificationTemplateService.resource}/${id}`, data, {
      withCredentials: true,
    });
  },
};
