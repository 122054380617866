import React from 'react';
import { createStyles, Typography, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = () =>
  createStyles({
    root: {},
    label: {
      marginBottom: 5,
    },
    value: {
      fontWeight: 600,
    },
    multilineText: {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      whiteSpace: 'pre-wrap',
      marginBottom: '10px !important'
    }
  });

type Props = WithStyles<typeof styles> & {
  label: string;
  value: string | number;
  multiline?: boolean;
};

const LabelValueComponent: React.FunctionComponent<Props> = ({ classes, label, value, multiline }) => {
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      {multiline ? (
        <Typography className={classes.multilineText}>
          <pre className={classNames(classes.multilineText, classes.value)}>
            {value}
          </pre>
        </Typography>
      ) : (
        <div className={classes.value}>{value}</div>
      )}
    </div>
  );
};

export const LabelValue = withStyles(styles)(LabelValueComponent);
