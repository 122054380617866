import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';

import { List } from '../../List';
import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { Button } from '../../Button';
import { Box } from '../../Box';
import { UserService } from '../../../services/User.service';
import { User } from '../../../shared/types/User';
import { Message } from '../../Message';
import { ErrorService } from '../../../services/Error.service';
import { BoxLoader } from '../../BoxLoader';
import { Link } from '../../Link';
import { Select } from '../../inputs/Select';
import { Competition } from '../../../shared/types/Competition';
import { CompetitionService } from '../../../services/Competition.service';
import { ListAction } from '../../ListAction';
import { DeleteOutlined, FormatListBulletedTwoTone, RestoreFromTrashOutlined } from '@material-ui/icons';
import { RoleEnum } from '../../../shared/enums/RoleEnum';
import { TextField } from '../../inputs/TextField';
import { red, blue } from '@material-ui/core/colors';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

const styles = ({ custom, palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    indent: {
      marginRight: 10
    },
    buttons: {
      display: 'flex',
      'justify-content': 'flex-end'
    },
    'button-clear': {
      alignItems: 'right',
      marginRight: 10,
      marginTop: 10,
      backgroundColor: palette.error.main
    },
    'button-search': {
      alignItems: 'right',
      marginTop: 10
    },
    actionsWrapper: {
      display: 'inline-flex'
    },
    iconDelete: {
      color: red[500],
      marginLeft: 5
    },
    iconRestore: {
      color: blue[500],
      marginLeft: 5
    },
    disableDeleteButton: {
      pointerEvents: 'none',
      '& svg': {
        color: custom.gray.g_4
      }
    }
  });

type Props = WithStyles<typeof styles>;

const AdminUsersScreenComponent: React.FunctionComponent<Props> = ({classes}) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<Array<User>>([]);
  const [competitions, setCompetitions] = useState<Array<Competition>>([]);
  const [selectedRole, setRole] = useState<string>('');
  const [selectedCompetition, setCompetition] = useState<string>('');
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');

  const { userId } = useSelector((state: RootState) => state.user)
  const { t } = useTranslator();

  const getUserIsDeletedInfo = (isActive: boolean) => isActive ? '' : ` (${t('deleted').toLowerCase()})`;

  const listHeader = [{
    key: 'fullName',
    text: t('fullName'),
  }, {
    key: 'role',
    text: t('role'),
  }, {
    key: 'email',
    text: t('email'),
  }, {
    key: 'action',
    text: '',
    pullRight: true,
  }];

  const mapRowsToListRows = () =>
    users.map((user) => ({
      id: user.userId,
      items: [{
        key: 'fullName',
        content: <Link to={`/admin/users/${user.userId}`}>{`${user.firstName} ${user.lastName}${getUserIsDeletedInfo(user.isActive)}`}</Link>,
      }, {
        key: 'role',
        content: t(user.role),
      }, {
        key: 'email',
        content: user.email,
      }, {
        key: 'action',
        content: (
          <div className={classes.actionsWrapper}>
            <ListAction to={`/admin/users/${user.userId}/submissions`} title={t('competitions')}>
              <FormatListBulletedTwoTone />
            </ListAction>
            {user.isActive ? (
              <ListAction
                className={user.userId === userId ? classes.disableDeleteButton : ''}
                title={user.userId === userId ? '' : t('deleteUser')}
                onClick={() => handleDeactivateUserClick(user.userId)}
                to={'#'}
              >
                <DeleteOutlined className={classes.iconDelete} />
              </ListAction>
            ) : (
              <ListAction
                title={t('restoreUser')}
                onClick={() => handleActivateUserClick(user.userId)}
                to={'#'}
              >
                <RestoreFromTrashOutlined className={classes.iconRestore} />
              </ListAction>
            )}
          </div>
        ),
      }]
    }));

  const handleActivateUserClick = (userId: string) => {
    (async () => {
      await updateUserIsActiveField(userId, true);
    })()
  };

  const updateUserIsActiveField = async (userId: string, value: boolean) => {
    setServerError('');

    try {
      if (userId) {
        await UserService.updateUserIsActiveField(userId, value);

        fetchUsers();
      }
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }
  };

  const handleDeactivateUserClick = (userId: string) => {
    setIsConfirmDialogOpen(true);
    setUserIdToDelete(userId);
  };

  const handleCloseConfirmationDialog = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && await updateUserIsActiveField(userIdToDelete, false);
      setUserIdToDelete('');
    })()
  };

  const fetchUsers = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result },
        } = await UserService.getAll();

        setUsers(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  const mapCompetitionToSelect = (): any => {
    let data = competitions.map((competition) => ({
      value: competition.id,
      label: competition.name
    }));
    return data;
  };
  
  const fetchCompetitions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result },
        } = await CompetitionService.getAll();

        setCompetitions(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchUsers, []);
  useEffect(fetchCompetitions, []);

  const filterUsers = async () => {
    if (!selectedRole && !selectedCompetition && !searchPhrase) {
      fetchUsers();
      return;
    }
    (async () => {
        setServerError('');
        setLoading(true);
  
        try {
          const {
            data: { result },
          } = await UserService.getUsersByFilter(selectedRole!, selectedCompetition!, searchPhrase!);

          setUsers(result);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
        setLoading(false);
      })();
    };

  const clearFilters = async () => {
    setRole('');
    setCompetition('');
    setSearchPhrase('');
    await fetchUsers();
  };

  const onEnterPress = async (e: React.KeyboardEvent) => {
    if (e.key.toLowerCase() !== 'enter') {
      return;
    }
    e.preventDefault();
    await filterUsers();
  };

  return (
    <div>
      <SectionHeader title={t('users')} right={<Button to={'/admin/users/add'}>{t('addUser')}</Button>} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
          <Box> {loading ? <BoxLoader /> :
            <div>
              <div className={classes.root}>
                <Select
                  className={classes.indent}
                  name={'competitions'}
                  label={t('competitions')}
                  fullWidth
                  size={'small'}
                  defaultValue=''
                  value={selectedCompetition}
                  items={mapCompetitionToSelect()}
                  onChange={(e: React.ChangeEvent<any>) => setCompetition(e.target.value)}
                />
                <Select
                  className={classes.indent}
                  name={'role'}
                  label={t('role')}
                  fullWidth
                  size={'small'}
                  defaultValue=''
                  value={selectedRole}
                  items={[
                    { value: RoleEnum.Author, label: t('participant') },
                    { value: RoleEnum.Reviewer, label: t('reviewer') },
                    { value: RoleEnum.ScientificCouncilMember, label: t('scientificCouncil') },
                    { value: RoleEnum.FinanceCoordinator, label: t('financeCoordinator') },
                    { value: RoleEnum.BoardMember, label: t('management') },
                    { value: RoleEnum.Admin, label: t('admin') },
                    { value: RoleEnum.CompetitionCoordinator, label: t('competitionCoordinator') },
                    { value: RoleEnum.CompetitionCommitteeMember, label: t('competitionCommitteeMember') },
                    { value: RoleEnum.CompetitionJuryMember, label: t('competitionJuryMember') }
                  ]}
                  onChange={(e: React.ChangeEvent<any>) => setRole(e.target.value)}
                />
                <TextField
                  label={t('searchPhrase')}
                  size={'small'}
                  fullWidth
                  value={searchPhrase}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchPhrase(e.target.value)}
                  onKeyDown={onEnterPress}
                />
              </div>
              <div className={classes.buttons}>
                <Button className={classes['button-clear']} loading={false} disabled={false} onClick={clearFilters}>
                  { t('clearFilters') }
                </Button>
                <Button className={classes['button-search']} loading={false} disabled={false} onClick={filterUsers}>
                  { t('search') }
                </Button>
              </div>
              <List headerCells={listHeader} rows={mapRowsToListRows()} />
            </div>}
          </Box>
        )}

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={(confirmed) => handleCloseConfirmationDialog(confirmed)}
        dialogTitle={t('confirmUserDeletion')}
      />
    </div>
  );
};

export const AdminUsersScreen = withStyles(styles)(AdminUsersScreenComponent);
