import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '&:not(:first-child)': {
        borderTop: `1px solid ${custom.gray.g_0}`,
      },
    },
  });

type Props = WithStyles<typeof styles> & {};

const ListRowComponent: React.FunctionComponent<Props> = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>;
};

export const ListRow = withStyles(styles)(ListRowComponent);
