import produce from 'immer';

import { UserActions } from './actions';
import { ActionType } from '../actionTypes';
import { User } from '../../shared/types/User';
import { RoleEnum } from '../../shared/enums/RoleEnum';

export type UserState = User;

const initialState: UserState = {
  userId: '',
  firstName: '',
  lastName: '',
  role: RoleEnum.Author,
  email: '',
  phoneNumber: '',
  scientificFacility: '',
  academicTitle: '',
  specialty: '',
  acceptedRODO: false,
  isActive: true
};

const userReducer = (state: UserState = initialState, action: UserActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.SET_USER:
        return Object.assign({}, draft, action.payload);
    }
  });

export default userReducer;
