import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import MaterialFormControl, { FormControlProps as MaterialFormControlProps } from '@material-ui/core/FormControl';

const styles = () => createStyles({});

type Props = MaterialFormControlProps & WithStyles<typeof styles>;

const FormControlComponent: React.FunctionComponent<Props> = ({ ...restProps }) => {
  return <MaterialFormControl {...restProps} />;
};

export const FormControl = withStyles(styles)(FormControlComponent);
