import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';

import { NotesTile } from './NotesTile';
import { SubmissionService } from '../services/Submissions.service';
import { Note } from '../shared/types/Note';
import { ErrorService } from '../services/Error.service';
import { AddNote } from './AddNote';
import { BoxLoader } from './BoxLoader';
import { Message } from './Message';
import { useTranslator } from '../i18n/useTranslator';
import { NoteStatusEnum } from '../shared/enums/NoteStatusEnum';

const styles = () =>
  createStyles({
    root: {},
    tile: {
      '&:not(:first-child)': {
        marginTop: 20,
      },
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const NotesComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [notes, setNotes] = useState<Array<Note>>([]);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const fetchNotes = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const {
          data: { result },
        } = await SubmissionService.getNotes(match.params.id);

        setNotes(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchNotes, []);

  return (
    <div className={classes.root}>
      {loading ? (
        <BoxLoader />
      ) : (
        <>
          <AddNote
            submissionId={match.params.id}
            initValues={notes.find(note => note.status === NoteStatusEnum.Autosaved)}
            onSaved={fetchNotes}
          />
          {serverError ? (
            <Message type={'ERROR'}>{t(serverError)}</Message>
          ) : (
            notes
              .filter(note => note.status === NoteStatusEnum.Submitted)
              .map(note =>
                <NotesTile
                  className={classes.tile}
                  {...note}
                  key={note.id}
                  submissionId={match.params.id}
                  onDeleted={fetchNotes}
                />
              )
          )}
        </>
      )}
    </div>
  );
}

export const Notes = withStyles(styles)(withRouter(NotesComponent));
