import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { TrophyVariantOutline } from 'mdi-material-ui';

import { SidebarMenuItem } from '../SidebarMenuItem';
import { useTranslator } from '../../i18n/useTranslator';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const CompetitionCoordinatorSidebarContentComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <div>
      <SidebarMenuItem icon={TrophyVariantOutline} label={t('competitions')} to={'/competition-coordinator/competitions'} />
    </div>
  );
};

export const CompetitionCoordinatorSidebarContent = withStyles(styles)(CompetitionCoordinatorSidebarContentComponent);
