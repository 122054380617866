import React, { useState, useEffect } from "react";
import { createStyles, WithStyles, withStyles, Grid } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import partition from "lodash/partition";

import { useTranslator } from "../../../i18n/useTranslator";
import { SectionHeader } from "../../SectionHeader";
import { SubmissionDetails } from "../../SubmissionDetails";
import { SubmissionService } from "../../../services/Submissions.service";
import { Submission } from "../../../shared/types/Submission";
import { Box } from "../../Box";
import { BoxLoader } from "../../BoxLoader";
import { Message } from "../../Message";
import { ErrorService } from "../../../services/Error.service";
import { SubmissionAttachment } from "../../../shared/types/SubmissionAttachment";
import { RoleEnum } from "../../../shared/enums/RoleEnum";
import { AuthorSubmissionInstructions } from "../../AuthorSubmissionInstructions";
import { StageService } from "../../../services/Stage.service";
import { SubmissionStage } from "../../../shared/types/SubmissionStage";
import { SubmissionStatusEnum } from "../../../shared/enums/SubmissionStatusEnum";
import { AdditionalDocumentation } from "../../AdditionalDocumentation";
import { CompetitionCategoryEnum } from "../../../shared/enums/CompetitionCategoryEnum";

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const AuthorSubmissionDetailsScreenComponent: React.FunctionComponent<
  Props
> = ({ match }) => {
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState<Submission | null>(null);
  const [authorAttachments, setAuthorAttachments] = useState<
    Array<SubmissionAttachment>
  >([]);
  const [activeStage, setActiveStage] = useState<SubmissionStage>();

  const { t } = useTranslator();

  const fetchSubmission = () => {
    (async () => {
      setLoading(true);
      setServerError("");

      try {
        const {
          data: { result },
        } = await SubmissionService.getOne(match.params.id);

        setSubmission(result);

        try {
          const {
            data: { result: submissionAttachments },
          } = await SubmissionService.getAuthorAttachments(result.id);

          const [, author] = partition(submissionAttachments, {
            role: RoleEnum.Reviewer,
          });

          setAuthorAttachments(author);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  const fetchActiveStage = () => {
    (async () => {
      setLoading(true);
      setServerError("");
      if (submission?.status === SubmissionStatusEnum.Won) {
        try {
          const {
            data: { result },
          } = await StageService.getActiveStage(match.params.id);
          setActiveStage(result);
        } catch (error) {
          if (ErrorService.parseStatusCode(error) !== 404) {
            setServerError(ErrorService.parseError(error));
          }
        }
        setLoading(false);
      }
    })();
  };

  useEffect(fetchSubmission, []);
  useEffect(fetchActiveStage, [submission]);

  return (
    <div>
      <SectionHeader title={t("submissionDetails")} />
      {serverError ? (
        <Message type={"ERROR"}>{t(serverError)}</Message>
      ) : loading ? (
        <BoxLoader />
      ) : submission ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box>
              <SubmissionDetails
                submission={submission}
                activeStage={activeStage}
                authorAttachments={authorAttachments}
                reviewerAttachments={[]}
                onChanged={fetchSubmission}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <AuthorSubmissionInstructions />
            
            {submission.competition.category === CompetitionCategoryEnum.Person ? (
              <AdditionalDocumentation
                submission={submission}
                onChanged={fetchSubmission}
              />
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export const AuthorSubmissionDetailsScreen = withStyles(styles)(
  AuthorSubmissionDetailsScreenComponent
);
