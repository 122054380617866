import React from 'react';
import { createStyles, WithStyles, withStyles, Theme, Grid, FormControlLabel, Checkbox } from '@material-ui/core';

import { useTranslator } from '../i18n/useTranslator';
import { TextField } from './inputs/TextField';
import { FileInput } from './inputs/FileInput';
import { Button } from './Button';
import { Select } from './inputs/Select';
import { Autocomplete } from './inputs/Autocomplete';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { useFormikContext } from 'formik';
import { CompetitionFormData } from '../shared/types/CompetitionFormData';
import { BillingMethodEnum } from '../shared/enums/BillingMethodEnum';
const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      '&:not(:first-child)': {
        borderTop: `1px solid ${custom.gray.g_0}`,
        paddingTop: 20,
      },
      '&:not(:last-child)': {
        paddingBottom: 20,
      },
    },
    checkboxWrapper: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      padding: '7px 10.5px'
    },
    checkbox: {
      padding: '0 7px 0 0'
    },
    checkboxFormControlLabel: {
      margin: 0
    },
    removeDocumentButton: {
      marginTop: 10,
    },
    fileMimeTypeError: {
      marginBottom: 15
    }
  });

type Props = WithStyles<typeof styles> & {
  documentName: string;
  specimen: File | null;
  role: string;
  isRequired: boolean;
  isForAllReviews?: boolean;
  visibleForRoles: RoleEnum[];
  onRemove: () => void;
  onDocumentNameChange: (value: string) => void;
  onDocumentFileChange: (value: File | null) => void;
  onRoleChange: (value: string) => void;
  onIsRequiredChange: (value: boolean) => void;
  onIsForAllReviewsChange?: (value: boolean) => void;
  onVisibleForRolesChange: (value: RoleEnum[]) => void;
};

const DocumentFormRowComponent: React.FunctionComponent<Props> = ({
  classes,
  documentName,
  specimen,
  role,
  isRequired,
  isForAllReviews: isForAllReviews,
  visibleForRoles,
  onDocumentNameChange,
  onDocumentFileChange,
  onRoleChange,
  onIsRequiredChange,
  onIsForAllReviewsChange,
  onVisibleForRolesChange,
  onRemove,
}) => {
  const { t } = useTranslator();
  const { values } = useFormikContext<CompetitionFormData>();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid container spacing={2} item xs={9} alignContent={'flex-start'}>
          <Grid item xs={4}>
            <TextField
              label={t('documentName')}
              fullWidth
              size={'small'}
              value={documentName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDocumentNameChange(e.target.value)}
            />
          </Grid>
          <Grid container spacing={2} item xs={8}>
            <Grid item xs={4}>
            <FileInput
              label={t('templateFile')}
              fullWidth
              value={specimen}
              onChange={onDocumentFileChange}
            />
            </Grid>
            <Grid item xs={4}>
            <Select
              name={'role'}
              label={t('role')}
              fullWidth
              size={'small'}
              value={role}
              items={[
                { label: t('Author'), value: RoleEnum.Author },
                { label: t('Reviewer'), value: RoleEnum.Reviewer },
              ]}
              onChange={(e: React.ChangeEvent<any>) => onRoleChange(e.target.value)}
            />
            </Grid>
            <Grid item xs={4}>
              <Select
                name={'isRequired'}
                label={t('isRequired')}
                fullWidth
                size={'small'}
                value={isRequired}
                items={[
                  { label: t('isRequired'), value: "true" },
                  { label: t('notRequired'), value: "false" },
                ]}
                onChange={(e: React.ChangeEvent<any>) => onIsRequiredChange(e.target.value)}
              />
            </Grid>
          </Grid>
          {values.billingMethod === BillingMethodEnum.PerCompetition &&
          role === RoleEnum.Reviewer && (
            <Grid item xs={4}>
              <div className={classes.checkboxWrapper}>
                <FormControlLabel
                  className={classes.checkboxFormControlLabel}
                  control={
                    <Checkbox
                      defaultChecked={isForAllReviews}
                      onChange={(e) => onIsForAllReviewsChange && onIsForAllReviewsChange(e.target.checked)}
                      className={classes.checkbox}
                    />}
                  label={t('documentForAllReviews')} 
                />
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            multiple
            options={[
              RoleEnum.ScientificCouncilMember,
              RoleEnum.BoardMember,
              RoleEnum.CompetitionCommitteeMember,
              RoleEnum.CompetitionJuryMember
            ]}
            getOptionLabel={(option) => `${t(option)}`}
            renderOption={(option) => (
              <span>
                {t(option)}
              </span>
            )}
            renderInput={(params) => <TextField {...params} label={t('visibleForRoles')} />}
            value={visibleForRoles}
            onChange={(e: React.ChangeEvent<{}>, value: Array<RoleEnum>) => onVisibleForRolesChange(value)}
          />    
        </Grid>
      </Grid>

      <Button className={classes.removeDocumentButton} variant={'outlined'} size={'small'} onClick={onRemove}>
        {t('removeDocument')}
      </Button>
    </div>
  );
};

export const DocumentFormRow = withStyles(styles)(DocumentFormRowComponent);
