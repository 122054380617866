import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { Autocomplete } from './inputs/Autocomplete';
import { useTranslator } from '../i18n/useTranslator';
import { TextField } from './inputs/TextField';
import { UserService } from '../services/User.service';
import { User } from '../shared/types/User';
import { Button } from './Button';
import { Submission } from '../shared/types/Submission';
import { SubmissionService } from '../services/Submissions.service';
import { List } from './List';
import { ListAction } from './ListAction';
import { DeleteOutlined } from '@material-ui/icons';
import { Message } from './Message';
import { ErrorService } from '../services/Error.service';
import red from '@material-ui/core/colors/red';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { ConfirmationDialog } from './ConfirmationDialog';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    submissionReviewersListHeader: {
      height: 10
    },
    submissionReviewersList: {
        fontSize: '1rem',
        padding: 10,
        maxWidth: 250
    },
    heading: {
      marginTop: 20,
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    saveButton: {
      alignSelf: 'flex-end',
      marginTop: 10,
    },
    iconDelete: {
        fontSize: 20,
        color: red[500],
        marginLeft: 5
    }
  });

type Props = WithStyles<typeof styles> & {
  submissionId: Submission['id'];
  forceRefreshReviewersList: number;
  reviewerAttachments: SubmissionAttachment[];
  onSaveHandler: (users: Array<User>) => void;
};

const ReviewerAutocompleteComponent: React.FunctionComponent<Props> = ({
  classes,
  submissionId,
  reviewerAttachments,
  forceRefreshReviewersList,
  onSaveHandler
}) => {
  const [serverError, setServerError] = useState('');
  const [reviewers, setReviewers] = useState<Array<User>>([]);
  const [reviewersToSave, setReviewersToSave] = useState<Array<User>>([]);
  const [submissionReviewers, setSubmissionReviewers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');

  const { t } = useTranslator();

  const fetchReviewers = () => {
    (async () => {
      setLoading(true);

      const {
        data: { result },
      } = await UserService.getReviewers();
      setReviewers(result);

      setLoading(false);
    })();
  };

  const fetchSubmissionReviewers = async () => {
    const {
      data: { result },
    } = await SubmissionService.getReviewers(submissionId);

    setSubmissionReviewers(result);
  };

  const handleChange = async (event: React.ChangeEvent<{}>, value: Array<User>) => {
    setReviewersToSave(value);
  };

  const handleSaveAction = async () => {
    (async () => {
      setSaving(true);
      await onSaveHandler(reviewersToSave);
      fetchSubmissionReviewers();
      setReviewersToSave([]);
      setSaving(false);
    })();
  };

  const init = () => {
    fetchReviewers();
    fetchSubmissionReviewers();
  };

  useEffect(init, []);
  useEffect(() => { fetchSubmissionReviewers() }, [forceRefreshReviewersList]);

  const deleteReviewer = async () => {
    setServerError('');

    try {
      if (submissionId && userIdToDelete){
        await SubmissionService.deleteReviewer(submissionId, userIdToDelete);

        fetchSubmissionReviewers();
      }
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }
  };

  const listHeader = [{
    key: 'fullName',
    text: ''
  }, {
    key: 'action',
    text: ''
  }];

  const mapRowsToReviewerListRows = () =>
    submissionReviewers.map(reviewer => ({
      id: reviewer.userId,
      items: [{
        key: 'fullName',
        content: `${reviewer.firstName} ${reviewer.lastName}`
      }, {
        key: 'action',
        content: !reviewerAttachments.find(attachment => attachment.attachments.some(attach => attach.senderId === reviewer.userId)) &&
          <ListAction
            title={t('delete')} 
            to='#'
            onClick={() => handleDeleteReviewerClick(reviewer.userId)}
          >
            <DeleteOutlined className={classes.iconDelete} />
          </ListAction>
      }]
  }));

  const handleDeleteReviewerClick = (userId: string) => {
    setIsConfirmDialogOpen(true);
    setUserIdToDelete(userId);
  };

  const handleCloseConfirmationDialog = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && await deleteReviewer();
      setUserIdToDelete('');
    })()
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        options={reviewers.filter(
          (reviewer) =>
            !submissionReviewers.find((r) => r.userId === reviewer.userId) && !reviewersToSave.find((r) => r.userId === reviewer.userId)
        )}
        noOptionsText={t('noReviewers')}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        renderOption={(option) => (
          <>
            <span>
              {option.firstName} {option.lastName}
            </span>
          </>
        )}
        renderInput={(params) => <TextField {...params} label={t('pickReviewer')} />}
        loading={loading}
        disabled={saving}
        value={reviewersToSave}
        onChange={handleChange}
      />
      <Button className={classes.saveButton} disabled={!reviewersToSave.length || saving} onClick={handleSaveAction}>
        {t('save')}
      </Button>
      {Boolean(submissionReviewers.length) && (
        <>
          <div className={classes.heading}>{t('attachedReviewers')}</div>
          <List
            headerCells={listHeader}
            headerClassName={classes.submissionReviewersListHeader}
            rows={mapRowsToReviewerListRows()}
            className={classes.submissionReviewersList}
          />
        </>
      )}
      {serverError && (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      )}

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={(confirmed) => handleCloseConfirmationDialog(confirmed)}
        dialogTitle={t('confirmReviewerDeletionFromSubmission')}
      />
    </div>
  );
};

export const ReviewerAutocomplete = withStyles(styles)(ReviewerAutocompleteComponent);
