import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ custom, palette }: Theme) =>
  createStyles({
    root: {},
    ERROR: {
      backgroundColor: palette.error.light,
      color: palette.error.contrastText,
      padding: '10px 20px',
      borderRadius: custom.radius.r_0,
    },
    INFO: {
      backgroundColor: palette.info.light,
      color: palette.info.contrastText,
      padding: '10px 20px',
      borderRadius: custom.radius.r_0,
    },
  });

type Props = WithStyles<typeof styles> & {
  type: 'ERROR' | 'INFO';
  className?: string;
};

const MessageComponent: React.FunctionComponent<Props> = ({ classes, className, children, type }) => {
  return <div className={classNames(classes.root, classes[type], className)}>{children}</div>;
};

export const Message = withStyles(styles)(MessageComponent);
