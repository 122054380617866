import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { Form } from '../Form';
import { FieldTextField } from '../form-fields/FieldTextField';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { repeatNewPassword, password } from '../../shared/validators';
import { ErrorService } from '../../services/Error.service';
import { FormError } from '../FormError';
import { UserService } from '../../services/User.service';
import { RootState } from '../../store/rootReducer';
import { FormSuccess } from '../FormSuccess';

const styles = () =>
  createStyles({
    button: {
      alignSelf: 'flex-end',
    },
  });

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

type FormValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const PasswordChangeFormComponent: React.FunctionComponent<Props> = ({ classes }) => {
  const { userId } = useSelector((state: RootState) => state.user);
  const [serverError, setServerError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      oldPassword: password(t),
      newPassword: password(t),
      newPasswordRepeat: repeatNewPassword(t),
    });

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
  };

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    setServerError('');
    setSuccessMessage('');
    setSubmitting(true);

    try {
      await UserService.changePassword(userId, {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });

      resetForm();
      setSuccessMessage('passwordChangeSuccess');
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }

    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <FormContent>
          <FieldTextField name={'oldPassword'} label={t('oldPassword')} type={'password'} />
          <FieldTextField name={'newPassword'} label={t('newPassword')} type={'password'} />
          <FieldTextField name={'newPasswordRepeat'} label={t('repeatNewPassword')} type={'password'} />

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <FormSuccess show={Boolean(successMessage)} message={t(successMessage)} />

          <Button className={classes.button} type={'submit'} loading={isSubmitting}>
            {t('changePassword')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const PasswordChangeForm = withStyles(styles)(withRouter(PasswordChangeFormComponent));
