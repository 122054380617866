import * as Yup from 'yup';

import { TFunction } from '../i18n/useTranslator';
import { BillingMethodEnum } from './enums/BillingMethodEnum';

export const name = (t: TFunction) => Yup.string().required(t('fieldIsRequired')).max(64, t('maximumFieldLength'));

export const surname = (t: TFunction) => Yup.string().required(t('fieldIsRequired')).max(64, t('maximumFieldLength'));

export const scienceUnit = (t: TFunction) =>
  Yup.string().when('role', {
    is: (role) => role === 'Author' || role === 'Reviewer',
    then: Yup.string().required(t('fieldIsRequired')),
  });

export const phone = (t: TFunction) => Yup.string().max(15, t('maximumFieldLength'));

export const email = (t: TFunction) =>
  Yup.string().email(t('wrongEmailAddress')).required(t('fieldIsRequired')).max(64, t('maximumFieldLength'));

export const password = (t: TFunction) =>
  Yup.string()
    .required(t('fieldIsRequired'))
    .matches(/^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*?!=_]).*$/, t('invalidPassword'));

export const agreement = (t: TFunction) => Yup.boolean().oneOf([true], t('youMustAgreeToTerms'));

export const reviewerSpecialization = (t: TFunction) =>
  Yup.string().when('role', {
    is: (role) => role === 'Reviewer',
    then: Yup.string().required(t('fieldIsRequired')),
  });

export const specialty = (t: TFunction) => Yup.string().required(t('fieldIsRequired')).max(64, t('maximumFieldLength'));

export const competitionStartDate = (t: TFunction) => Yup.date().required(t('fieldIsRequired'));

export const competitionEndDate = (t: TFunction) =>
  Yup.date()
    .required(t('fieldIsRequired'))
    .when('startDate', (startDate: string, schema: any) => startDate && schema.min(startDate, t('endDateShouldBeAfterStartDate')));

export const competitionName = (t: TFunction) => Yup.string().required(t('fieldIsRequired'));

export const requiredConsent = (t: TFunction, required: boolean) =>
  required ?
    Yup.string().required(t('fieldIsRequired'))
    : Yup.string().optional();

export const billingMethod = (t: TFunction, hasDocumentForAllReviews: boolean) =>
  hasDocumentForAllReviews ?
    Yup.number().oneOf([BillingMethodEnum.PerCompetition], t('atLeastOneDocumentIsForAllReviews'))
    : Yup.number();

export const stageName = (t: TFunction) => Yup.string().required(t('fieldIsRequired'));

export const emailTemplateTitle = (t: TFunction) => Yup.string().required(t('fieldIsRequired'));

export const emailTemplateBody = (t: TFunction) => Yup.string().required(t('fieldIsRequired'));

export const repeatNewPassword = (t: TFunction) =>
  Yup.string()
    .required(t('fieldIsRequired'))
    .oneOf([Yup.ref('newPassword')], t('passwordMustMatch'))
    .matches(/^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*?!=_]).*$/, t('invalidPassword'));

export const projectName = (t: TFunction) => Yup.string().required(t('fieldIsRequired'));
export const unitInfo = (t: TFunction) => Yup.string().required(t('fieldIsRequired'));
export const academicTitle = (t: TFunction) => Yup.string().required(t('fieldIsRequired'));
export const acceptedRequiredConsent = (t: TFunction, required: boolean) => {
  return required ?
    Yup.boolean().oneOf([true], t('consentIsRequired'))
    : Yup.boolean().optional();
};

export const estimatedFinishTime = (t: TFunction, required: boolean) => {
  return required ?
    Yup.number()
      .required(t('fieldIsRequired'))
      .typeError(t('incorrectNumber'))
      .positive(t('incorrectNumber'))
    : Yup.object().nullable(true);
}

export const estimatedFunds = (t: TFunction, required: boolean) => {
  return required ? 
    Yup.number()
      .required(t('fieldIsRequired'))
      .typeError(t('incorrectNumber'))
      .positive(t('incorrectNumber'))
    : Yup.object().nullable(true);
}
