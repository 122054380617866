import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { UnloggedLayout } from '../layouts/UnloggedLayout';
import { Box } from '../Box';
import { BoxHeader } from '../BoxHeader';
import { useTranslator } from '../../i18n/useTranslator';
import { Button } from '../Button';

const styles = () =>
  createStyles({
    loginButton: {
      marginTop: 20,
    },
  });

type Props = WithStyles<typeof styles>;

const SuccessRegistrationScreenComponent: React.FunctionComponent<Props> = ({ classes }) => {
  const { t } = useTranslator();

  return (
    <UnloggedLayout>
      <Box>
        <BoxHeader title={t('registrationSuccess')} />
        <div>{t('activateYourAccount')}</div>
        <Button className={classes.loginButton} to={'/login'}>
          {t('login')}
        </Button>
      </Box>
    </UnloggedLayout>
  );
};

export const SuccessRegistrationScreen = withStyles(styles)(SuccessRegistrationScreenComponent);
