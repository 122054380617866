import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { Box } from './Box';
import { useTranslator } from '../i18n/useTranslator';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { SubmissionOtherAttachments } from './SubmissionOtherAttachments';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { BoxSection } from './BoxSection';
import { MultipleFileInput } from './inputs/MultipleFileInput';
import { keyBy, mapValues } from 'lodash';
import { SubmissionOtherAttachment } from '../shared/types/SubmissionOtherAttachment';
import { ErrorService } from '../services/Error.service';
import { SubmissionService } from '../services/Submissions.service';
import { Button } from './Button';
import { BoxLoader } from './BoxLoader';
import { Submission } from '../shared/types/Submission';
import { CompetitionCategoryEnum } from '../shared/enums/CompetitionCategoryEnum';
import { AttachmentStatusEnum } from '../shared/enums/AttachmentStatusEnum';
import { AllowedMimeTypes } from '../shared/AllowedMimeTypes';

const styles = () => createStyles({
    sendDocumentsButton: {
        alignSelf: 'flex-end',
        marginTop: 20,
    },
    additionalDocumentationBox: {
        marginTop: 10
    },
    isSticky: {
        position: 'fixed',
        top: 0,
        zIndex: 999
    }
});

type Props = WithStyles<typeof styles> & {
    submission: Submission;
    onChanged?: () => void;
};

const AdditionalDocumentationComponent: React.FunctionComponent<Props> = ({ 
    classes,
    submission,
    onChanged
}) => {
    const [serverError, setServerError] = useState('');
    const [saving, setSaving] = useState(false);
    const [otherAttachments, setOtherAttachments] = useState<Array<SubmissionOtherAttachment>>([]);
    const [otherAttachmentsLoading, setOtherAttachmentsLoading] = useState(false);

    const { role } = useSelector((state: RootState) => state.user);
    const { t } = useTranslator();

    const [otherAttachmentsToUpdate, setOtherAttachmentsToUpdate] = useState<{
        [key: string]: File | null;
    }>(
        mapValues(
            keyBy(
                otherAttachments,
                "id"
            ),
            () => null
        )
    );

    const fetchOtherAttachments = () => {
        (async () => {
            try {
                const {
                    data: { result: otherAttachments }
                } = await SubmissionService.getOtherAttachments(submission.id);
    
                setOtherAttachments(otherAttachments);
            } catch (error) {
                setServerError(ErrorService.parseError(error));
            }
        })();
    };
    
    useEffect(() => {
        fetchOtherAttachments();
    }, []);
    
    const handleMultipleFileChange = async (files: File[]) => {
        const promises = [];
        setOtherAttachmentsLoading(true);
    
        for (const file of files) {
            if (file) {
                const formData = new FormData();
    
                formData.append("file", file);
                formData.append("parentId", submission.id);
                formData.append("status", AttachmentStatusEnum.Autosaved.toString())
        
                promises.push(SubmissionService.addFile(formData));
            }
        }
    
        await Promise.all(promises);
    
        fetchOtherAttachments();
        setOtherAttachmentsLoading(false);
    };

    const updateOtherAttachments = async () => {
        const promises = [];
    
        const attachmentsArray = Object.keys(otherAttachmentsToUpdate).map((key) => ({
            attachmentId: key,
            file: otherAttachmentsToUpdate[key],
        }));
    
        for (const file of attachmentsArray) {
            if (!file.file) continue;
            
            const formData = new FormData();
        
            formData.append("file", file.file);
            formData.append("parentId", submission.id);
        
            promises.push(SubmissionService.updateFile(file.attachmentId, formData));
        }
    
        await Promise.all(promises);
    };

    const handleOtherAttachmentChange = (attachmentId: string, file: File | null) => {
        setOtherAttachmentsToUpdate({
            ...otherAttachmentsToUpdate,
            [attachmentId]: file
        });
    };

    const handleSaveOtherAttachments = async () => {
        setServerError("");
        setSaving(true);
    
        try {
            await updateOtherAttachments();
    
            onChanged && onChanged();
        } catch (error) {
            setServerError(ErrorService.parseError(error));
        } finally {
            setSaving(false);
        }
    };

    const handleSubmitOtherAttachments = async () => {
        setServerError("");
        setSaving(true);
    
        try {
            await SubmissionService.submitOtherAttachments(submission.id);
    
            onChanged && onChanged();
        } catch (error) {
            setServerError(ErrorService.parseError(error));
        } finally {
            setSaving(false);
        }
    };

    const getAdditionalDocumentationSectionTitle = () => 
        submission.competition.category === CompetitionCategoryEnum.Person ? 
            t("candidateAdditionalDocumentation") : t("anyFile");
    
    return (
        role === RoleEnum.Author || role === RoleEnum.Reviewer || otherAttachments.length ? (
            <Box className={classes.additionalDocumentationBox}>
                <BoxSection title={getAdditionalDocumentationSectionTitle()}>
                    {role === RoleEnum.Author || role === RoleEnum.Reviewer ? 
                        <MultipleFileInput
                            onChange={handleMultipleFileChange}
                            allowedMimeTypes={AllowedMimeTypes.AdditionalDocumentation}
                            allowedFileExtensionsLabel={t('allowedExtensions')}
                        /> : null}
                    {otherAttachmentsLoading ? <BoxLoader /> : null}
                    <SubmissionOtherAttachments
                        submissionOtherAttachments={otherAttachments} 
                        attachmentsToUpdate={otherAttachmentsToUpdate} 
                        onDelete={fetchOtherAttachments} 
                        onChange={handleOtherAttachmentChange} 
                    />
                    
                    {(role === RoleEnum.Admin ||
                    role === RoleEnum.CompetitionCoordinator) ? ( 
                        <Button
                            className={classes.sendDocumentsButton}
                            loading={saving}
                            disabled={saving || !Object.keys(otherAttachmentsToUpdate).filter(key => Boolean(otherAttachmentsToUpdate[key])).length}
                            onClick={handleSaveOtherAttachments}
                        >
                            {t("save")}
                        </Button>
                    ) : (
                        <Button
                            className={classes.sendDocumentsButton}
                            loading={saving}
                            disabled={saving || otherAttachments.every(attachment => attachment.status !== AttachmentStatusEnum.Autosaved)}
                            onClick={handleSubmitOtherAttachments}
                        >
                            {t("save")}
                        </Button>
                    )}
                </BoxSection>
            </Box>
        ) : null
    );
};

export const AdditionalDocumentation = withStyles(styles)(AdditionalDocumentationComponent);
