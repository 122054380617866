import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { RoleEnum } from '../shared/enums/RoleEnum';
import { Badge } from './Badge';
import { useTranslator } from '../i18n/useTranslator';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      width: 'fit-content',
      'background-color': '#4bb3fd59 !important',
      color: '#00487C !important'
    },
  });

type Props = WithStyles<typeof styles> & {
  roles: Array<RoleEnum>;
  className?: string;
};

const RoleVisibilityBadgeComponent: React.FunctionComponent<Props> = ({ classes, className, roles }) => {
  const { t } = useTranslator();

  return (
    roles.length ?
    <Badge className={classNames(classes.root, className)} color={'#00487C'}>
      {t('visibleForRoles')}: {roles.map(r => t(r)).join(', ')}
    </Badge> : null
  );
};

export const RoleVisibilityBadge = withStyles(styles)(RoleVisibilityBadgeComponent);
