import { TOptions, StringMap, i18n } from 'i18next';
import { Namespace, UseTranslationOptions, useTranslation } from 'react-i18next';

export type TFunction = (key: string, options?: TOptions<StringMap> | string) => string;

type UseTranslationResponse = [TFunction, i18n, boolean] & {
  t: TFunction;
  i18n: i18n;
  ready: boolean;
};

type UseTranslationFn = (ns?: Namespace, options?: UseTranslationOptions) => UseTranslationResponse;

export const useTranslator = useTranslation as UseTranslationFn;
