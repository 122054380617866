import React from 'react';
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import { Provider } from 'react-redux';

import theme from './styles/theme';
import { AdminScreens } from './components/screens/wrappers/AdminScreens';
import { RegitrationScreen } from './components/screens/RegistrationScreen';
import { LoginScreen } from './components/screens/LoginScreen';
import { SuccessRegistrationScreen } from './components/screens/SuccessRegistrationScreen';
import { AuthorScreens } from './components/screens/wrappers/AuthorScreens';
import { BoardMemberScreens } from './components/screens/wrappers/BoardMemberScreens';
import { FinanceCoordinatorScreens } from './components/screens/wrappers/FinanceCoordinatorScreens';
import { ReviewerScreens } from './components/screens/wrappers/ReviewerScreens';
import { ScientificCouncilMemberScreens } from './components/screens/wrappers/ScientificCouncilMemberScreens';
import { ProfileScreen } from './components/screens/ProfileScreen';
import { PrivateRoute } from './components/PrivateRoute';
import { ConfirmEmailScreen } from './components/screens/ConfirmEmailScreen';
import { ResetPasswordScreen } from './components/screens/ResetPasswordScreen';
import store from './store';
import { PasswordResetEmailSentScreen } from './components/screens/PasswordResetEmailSentScreen';
import { CreatePasswordScreen } from './components/screens/CreatePasswordScreen';
import { CreatePasswordSuccessScreen } from './components/screens/CreatePasswordSuccessScreen';
import { ResetPasswordErrorScreen } from './components/screens/ResetPasswordErrorScreen';
import { CompetitionCoordinatorScreens } from './components/screens/wrappers/CompetitionCoordinatorScreens';
import { CompetitionCommitteeMemberScreens } from './components/screens/wrappers/CompetitionCommitteeMemberScreens';
import { CompetitionJuryMemberScreens } from './components/screens/wrappers/CompetitionJuryMemberScreens';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Provider store={store}>
        <ThemeProvider theme={createMuiTheme(theme)}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route exact path={'/'} component={LoginScreen} />
              <Route path={'/login'} component={LoginScreen} />
              <Route exact path={'/registration'} component={RegitrationScreen} />
              <Route exact path={'/password/reset'} component={ResetPasswordScreen} />
              <Route exact path={'/password/reset/email'} component={PasswordResetEmailSentScreen} />
              <Route path={'/registration/success'} component={SuccessRegistrationScreen} />
              <Route path={'/confirm'} component={ConfirmEmailScreen} />
              <Route path={'/password/reset/new'} component={CreatePasswordScreen} />
              <Route path={'/password/reset/success'} component={CreatePasswordSuccessScreen} />
              <Route path={'/password/reset/error'} component={ResetPasswordErrorScreen} />
              <Route path={'/admin'} component={AdminScreens} />
              <Route path={'/author'} component={AuthorScreens} />
              <Route path={'/board-member'} component={BoardMemberScreens} />
              <Route path={'/finance-coordinator'} component={FinanceCoordinatorScreens} />
              <Route path={'/reviewer'} component={ReviewerScreens} />
              <Route path={'/scientific-council-member'} component={ScientificCouncilMemberScreens} />
              <Route path={'/competition-committee-member'} component={CompetitionCommitteeMemberScreens} />
              <Route path={'/competition-jury-member'} component={CompetitionJuryMemberScreens} />
              <Route path={'/competition-coordinator'} component={CompetitionCoordinatorScreens} />
              <PrivateRoute exact path={'/profile'} component={ProfileScreen} />
            </Switch>
          </Router>
        </ThemeProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
