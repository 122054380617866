import React, { useState } from 'react';

import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { useTranslator } from '../../i18n/useTranslator';
import Dropzone from 'react-dropzone';
import { Message } from '../Message';
import { blue } from '@material-ui/core/colors';

const styles = () =>
  createStyles({
    icon: {
      color: blue[500],
      margin: '0 20px 0 0',
      'font-size': '32px',
      width: '32px',
      height: '32px'
    },
    label: {
      display: 'flex',
      'flex-direction': 'row',
      padding: '20px',
      'align-items': 'center'
    },
    'allowed-extensions': {
      display: 'block',
      color: '#6c757d'
    },
    'click-control': {
      cursor: 'pointer',
      color: 'inherit',
      '&:hover': {
        'text-decoration': 'underline'
      }
    }
  });

type Props = WithStyles<typeof styles> & {
  allowedMimeTypes: string[],
  allowedFileExtensionsLabel: string,
  onChange?: (value: File[]) => void;
};

const MultipleFileInputComponent: React.FunctionComponent<Props> = ({classes, allowedMimeTypes, allowedFileExtensionsLabel, onChange}) => {
  const [fileMimeTypeError, setFileMimeTypeError] = useState(false);

  const { t } = useTranslator();

  const handleFileChange = (files: File[]) => {
    onChange && onChange(files);
  };

  return (
    <div>
      <Dropzone onDrop={handleFileChange} 
                accept={allowedMimeTypes} 
                onDropRejected={() => setFileMimeTypeError(true)} 
                onDropAccepted={() => setFileMimeTypeError(false)}>
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={classes.label}>
                <CloudUploadOutlined className={classes.icon}/>
                <div>
                  <span>
                    {t('multiFileInputLabelStart')} <strong><a className={classes['click-control']} role="button">{t('multiFileInputLabelClickHere')}</a></strong> {t('multiFileInputLabelEnd')}
                  </span>
                  <small className={classes['allowed-extensions']}> 
                    {allowedFileExtensionsLabel}
                  </small>
                </div>
              </div>
            </div>
            {fileMimeTypeError ? <Message type={'ERROR'}>{t('invalidFileExtension')} {allowedFileExtensionsLabel}</Message> : null}
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export const MultipleFileInput = withStyles(styles)(MultipleFileInputComponent);