import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    title: {
      fontSize: 18,
      marginTop: 0,
      marginBottom: 20,
    },
  });

type Props = WithStyles<typeof styles> & {
  title?: string;
};

const BoxHeaderComponent: React.FunctionComponent<Props> = ({ classes, title }) => {
  return <div>{title ? <h4 className={classes.title}>{title}</h4> : null}</div>;
};

export const BoxHeader = withStyles(styles)(BoxHeaderComponent);
