import React from 'react';
import { createStyles, WithStyles, withStyles, Tooltip, Theme } from '@material-ui/core';

import { Link } from './Link';

const styles = ({ custom, palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& a': {
        color: custom.gray.g_4,
        '&:hover': {
          color: palette.primary.main,
        },
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  to: string;
  title: string;
  listData?: string;
  className?: string;
  onClick?: (value: any) => void;
};

const ListActionComponent: React.FunctionComponent<Props> = ({
  classes,
  children,
  ...props
}) => {
  return (
    <Tooltip title={props.title}>
      <div className={classes.root}>
        {props.onClick ? (
          <Link
            className={props.className}
            onClick={() => props.onClick? props.onClick(props.listData): {}}
            to={'#'}
          >
            {children}
          </Link>
        ) : (
          <Link
            className={props.className}
            to={props.to}
          >
            {children}
          </Link>
        )}
      </div>
    </Tooltip>
  );
};

export const ListAction = withStyles(styles)(ListActionComponent);
