import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../i18n/useTranslator';
import { List } from './List';
import { ListRow } from '../shared/types/ListRow';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & {
  rows: Array<ListRow>;
};

const SubmissionsListComponent: React.FunctionComponent<Props> = ({ rows }) => {
  const { t } = useTranslator();

  const listHeader = [
    {
      key: 'submissionName',
      text: t('submissionName'),
    },
    {
      key: 'submissionDate',
      text: t('submissionDate'),
    },
  ];

  return rows.length ? <List headerCells={listHeader} rows={rows} /> : <span>{t('none')}</span>;
};

export const SubmissionsList = withStyles(styles)(SubmissionsListComponent);
