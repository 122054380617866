import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import partition from 'lodash/partition';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { ErrorService } from '../../../services/Error.service';
import { Message } from '../../Message';
import { BoxLoader } from '../../BoxLoader';
import { SubmissionDetails } from '../../SubmissionDetails';
import { SubmissionService } from '../../../services/Submissions.service';
import { SubmissionAttachment } from '../../../shared/types/SubmissionAttachment';
import { ScreenToolbar } from '../../ScreenToolbar';
import { RoleEnum } from '../../../shared/enums/RoleEnum';
import { SubmissionFull } from '../../../shared/types/SubmissionFull';
import { CompetitionCategoryEnum } from '../../../shared/enums/CompetitionCategoryEnum';
import { AdditionalDocumentation } from '../../AdditionalDocumentation';

const styles = () =>
  createStyles({
    box: {
      marginBottom: 20,
    },
  });

type Props = WithStyles<typeof styles> & RouteComponentProps<{ id: string }>;

const FinanceCoordinatorSubmissionDetailsScreenComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState<SubmissionFull | null>(null);
  const [authorAttachments, setAuthorAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [reviewerAttachments, setReviewerAttachments] = useState<Array<SubmissionAttachment>>([]);

  const { t } = useTranslator();

  const fetchSubmission = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const {
          data: { result },
        } = await SubmissionService.getOneFull(match.params.id);

        setSubmission(result);

        try {
          const {
            data: { result: submissionAttachments },
          } = await SubmissionService.getAllAttachments(result.submission.id);

          const [reviewer, author] = partition(submissionAttachments, { role: RoleEnum.Reviewer });

          setReviewerAttachments(reviewer);
          setAuthorAttachments(author);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchSubmission, []);

  return (
    <div>
      <SectionHeader title={t('submissionDetails')} />
      {submission ? (
        <ScreenToolbar
          backLink={{
            to: `/finance-coordinator/competitions/${submission.submission.competition.id}/ranking-list`,
            title: t('backToRankingList'),
          }}
        />
      ) : null}
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : loading ? (
        <BoxLoader />
      ) : submission ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <SubmissionDetails
              submission={submission.submission}
              authorAttachments={authorAttachments}
              reviewerAttachments={reviewerAttachments}
              marks={submission.assessments}
              summedMarks={submission.summedMarks}
              onChanged={fetchSubmission}
            />
          </Grid>
          <Grid item xs={4}>  
            {submission.submission.competition.category === CompetitionCategoryEnum.Person ? (
              <AdditionalDocumentation
                submission={submission.submission}
                onChanged={fetchSubmission}
              />
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export const FinanceCoordinatorSubmissionDetailsScreen = withStyles(styles)(FinanceCoordinatorSubmissionDetailsScreenComponent);
