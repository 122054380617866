import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { Form } from '../Form';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { FieldTextField } from '../form-fields/FieldTextField';
import { FieldSelect } from '../form-fields/FieldSelect';
import { CompetitionService } from '../../services/Competition.service';
import { competitionStartDate, competitionEndDate, competitionName, requiredConsent, billingMethod } from '../../shared/validators';
import { BoxHeader } from '../BoxHeader';
import { DocumentationConfigurator } from '../DocumentationConfigurator';
import { CompetitionFormData } from '../../shared/types/CompetitionFormData';
import { CompetitionTypeEnum } from '../../shared/enums/CompetitionTypeEnum';
import { Competition } from '../../shared/types/Competition';
import { ErrorService } from '../../services/Error.service';
import { FormError } from '../FormError';
import { RoleEnum } from '../../shared/enums/RoleEnum';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { CompetitionCategoryEnum } from '../../shared/enums/CompetitionCategoryEnum';
import { CompetitionCoordinatorsSelector } from '../CompetitionCoordinatorsSelector';
import { BillingMethodEnum } from '../../shared/enums/BillingMethodEnum';
import { AttachmentTemplate } from '../../shared/types/AttachmentTemplate';

const styles = () =>
  createStyles({
    documentationConfigurator: {
      marginBottom: 20,
    },
    saveButton: {
      alignSelf: 'flex-end',
    },
    alert: {
      marginBottom: 20
    }
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<void> & {
    isCompetitionEdit?: boolean;
    initValues?: Competition;
    competitionId?: Competition['id'];
    attachmentTemplates?: Array<AttachmentTemplate>;
    setBillingMethod?: (billingMethod: BillingMethodEnum) => void;
  };

const CompetitionFormComponent: React.FunctionComponent<Props> = ({
  classes,
  competitionId,
  history,
  initValues,
  isCompetitionEdit,
  attachmentTemplates,
  setBillingMethod
}) => {
  const [savedCompetitionId, setSavedCompetitionId] = useState('');
  const [serverError, setServerError] = useState('');
  const [competitionCategory, setCompetitionCategory] = useState(CompetitionCategoryEnum.Project);
  const [hasDocumentForAllReviews, setHasDocumentForAllReviews] = useState<boolean>();
  let setFormFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  
  const { t } = useTranslator();

  useEffect(() => {
    if (isCompetitionEdit) return;

    if (competitionCategory === CompetitionCategoryEnum.Person) {
      setFormFieldValue && setFormFieldValue('requiredConsent', t('defaultRequiredConsent'));
    }
    else if (competitionCategory === CompetitionCategoryEnum.Project) {
      setFormFieldValue && setFormFieldValue('requiredConsent', '');
    }
  }, [competitionCategory])

  const { role } = useSelector((state: RootState) => state.user);
  const path = role === RoleEnum.Admin ? 'admin' 
    : role === RoleEnum.CompetitionCoordinator ? 'competition-coordinator' 
    : '';

  const initialValues: CompetitionFormData = {
    name: initValues ? initValues.name : '',
    edition: initValues ? initValues.edition : '',
    startDate: initValues ? moment(initValues.startDate).format('YYYY-MM-DD') : '',
    endDate: initValues ? moment(initValues.endDate).format('YYYY-MM-DD') : '',
    type: initValues ? initValues.type : CompetitionTypeEnum.ResearchProjectCompetition,
    category: initValues ? initValues.category : competitionCategory,
    billingMethod: initValues ? initValues.billingMethod : BillingMethodEnum.PerReview,
    isActive: initValues ? initValues.isActive : true,
    attachmentTemplates: [],
    requiredConsent: initValues ? initValues.requiredConsent : '',
    competitionCoordinatorUserIds: []
  };

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      startDate: competitionStartDate(t),
      endDate: competitionEndDate(t),
      name: competitionName(t),
      requiredConsent: requiredConsent(t, competitionCategory === CompetitionCategoryEnum.Person),
      billingMethod: billingMethod(t, Boolean(hasDocumentForAllReviews))
    });

  const saveAttachments = async (competitionId: Competition['id'], attachmentTemplates: Array<any>) => {
    const promises = [];

    for (const file of attachmentTemplates.filter((a) => a.name)) {
      const formData = new FormData();

      formData.append('name', file.name);
      formData.append('role', file.role);
      formData.append('isRequired', file.isRequired);
      formData.append('isForAllReviews', file.isForAllReviews.toString());
    
      file.visibleForRoles.forEach((r: RoleEnum) => formData.append('visibilityRoles', r));

      if (file.specimen) {
        formData.append('specimenFile', file.specimen);
      }

      promises.push(CompetitionService.addFile(competitionId, formData));
    }

    return Promise.all(promises);
  };

  const handleSubmit = async (values: CompetitionFormData, { setSubmitting }: FormikHelpers<CompetitionFormData>) => {
    setSubmitting(true);
    setServerError('');

    try {
      if (savedCompetitionId) {
        if (values.attachmentTemplates) {
          await saveAttachments(savedCompetitionId, values.attachmentTemplates);
        }

        if (values.competitionCoordinatorUserIds) {
          await CompetitionService.addCompetitionCoordinators(savedCompetitionId, values.competitionCoordinatorUserIds);
        }

        history.push(`/${path}/competitions`);

        return;
      }

      if (competitionId) {
        await CompetitionService.update(competitionId, values);
      } else {
        const newCompetitionId = uuidv4();
        const { attachmentTemplates, competitionCoordinatorUserIds, ...data } = values;

        await CompetitionService.add({ id: newCompetitionId, ...data });

        setSavedCompetitionId(newCompetitionId);

        if (attachmentTemplates) {
          await saveAttachments(newCompetitionId, attachmentTemplates);
        }

        if (competitionCoordinatorUserIds) {
          await CompetitionService.addCompetitionCoordinators(newCompetitionId, competitionCoordinatorUserIds);
        }
      }

      history.push(`/${path}/competitions`);
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }

    setSubmitting(false);
  };

  const updateHasDocumentForAllReviews = (formAttachmentTemplates?: Competition['attachmentTemplates']) => {
    const hasDocumentForAllReviews = formAttachmentTemplates?.some(template => template.isForAllReviews) ||
      attachmentTemplates?.some(template => template.isForAllReviews);
    setHasDocumentForAllReviews(hasDocumentForAllReviews);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting, setFieldValue, values }) => {
        setFormFieldValue = setFieldValue;
        setCompetitionCategory(values.category);
        setBillingMethod && setBillingMethod(values.billingMethod);
        updateHasDocumentForAllReviews(values.attachmentTemplates)

        return (
          <FormContent>
            <BoxHeader title={t('generalInfo')} />
            <FieldTextField name={'name'} label={t('competitionName')} fullWidth />
            <FieldTextField name={'edition'} label={t('edition')} fullWidth />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FieldTextField
                  name={'startDate'}
                  type={'date'}
                  label={t('startDate')}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FieldTextField
                  name={'endDate'}
                  type={'date'}
                  label={t('endDate')}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            
            <FieldSelect
              name={'type'}
              label={t('competitionType')}
              items={[
                { label: t('researchProjectCompetition'), value: CompetitionTypeEnum.ResearchProjectCompetition },
                { label: t('scholarshipProgram'), value: CompetitionTypeEnum.ScholarshipProgram },
                { label: t('mobileTechnics'), value: CompetitionTypeEnum.MobileTechnics },
              ]}
              disabled={isCompetitionEdit}
            />

            <FieldSelect 
              name={'category'}
              label={t('competitionCategory')}
              items={[
                { label: t('competitionCategoryProject'), value: CompetitionCategoryEnum.Project },
                { label: t('competitionCategoryPerson'), value: CompetitionCategoryEnum.Person }
              ]}
              disabled={isCompetitionEdit}
            />
            
            {values.category === CompetitionCategoryEnum.Person && (
              <FieldTextField 
                name={'requiredConsent'}
                label={t('requiredConsentLabel')}
                disabled={isCompetitionEdit}
                multiline
                fullWidth
              />
            )}

            <FieldSelect 
              name={'billingMethod'}
              label={t('billingMethod')}
              items={[
                { label: t('billingMethodPerReview'), value: BillingMethodEnum.PerReview },
                { label: t('billingMethodPerCompetition'), value: BillingMethodEnum.PerCompetition }
              ]}
            />

            <FieldSelect
              name={'isActive'}
              label={t('startStatus')}
              items={[
                { label: t('active'), value: true },
                { label: t('inactive'), value: false },
              ]}
            />

            {!isCompetitionEdit && (
              <CompetitionCoordinatorsSelector 
                competitionId={competitionId}
                setFieldValue={setFieldValue}
                isUpdate={false}
              />)}
            
            {!isCompetitionEdit && (
              <>
                <BoxHeader title={t('documentation')} />
                <DocumentationConfigurator className={classes.documentationConfigurator} setFieldValue={setFieldValue} />
              </>
            )}

            <FormError show={Boolean(serverError)} error={t(serverError)} />

            <Button className={classes.saveButton} type={'submit'} loading={isSubmitting} disabled={!dirty}>
              {competitionId ? t('save') : t('addCompetition')}
            </Button>
          </FormContent>
        );
      }}
    </Form>
  );
};

export const CompetitionForm = withStyles(styles)(withRouter(CompetitionFormComponent));
