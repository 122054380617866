import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { CompetitionJuryMemberRoute } from '../../routes/CompetitionJuryMemberRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { CompetitionJuryMemberCompetitionsScreen } from '../CompetitionJuryMember/CompetitionJuryMemberCompetitionsScreen';
import { CompetitionRankingListScreen } from '../Common/CompetitionRankingListScreen';
import { SubmissionDetailsScreen } from '../Common/ScientificCouncilMemberSubmissionDetailsScreen';
import { ReviewersScreen } from '../Common/ReviewersScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const CompetitionJuryMemberScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <CompetitionJuryMemberRoute exact path={`${match.path}/competitions`} component={CompetitionJuryMemberCompetitionsScreen} />
        <CompetitionJuryMemberRoute
          exact
          path={`${match.path}/competitions/:id/ranking-list`}
          component={CompetitionRankingListScreen}
        />
        <CompetitionJuryMemberRoute
          exact
          path={`${match.path}/submissions/:id`}
          component={SubmissionDetailsScreen}
        />
        <CompetitionJuryMemberRoute
          exact
          path={`${match.path}/reviewers`}
          component={ReviewersScreen}
        />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const CompetitionJuryMemberScreens = withStyles(styles)(CompetitionJuryMemberScreensComponent);
