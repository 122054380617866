import { ApiService } from './Api.service';
import { ApiResponse } from '../shared/types/ApiResponse';
import { FoundationInNumbers } from '../shared/types/FoundationInNumbers';

export const ReportsService = {
  resource: 'Report',
  getFoundationInNumbers: () => {
    return ApiService.get<ApiResponse<Array<FoundationInNumbers>>>(`${ReportsService.resource}/foundation-in-numbers`, { withCredentials: true });
  }
}
