import { ApiService } from './Api.service';
import { ApiResponse } from '../shared/types/ApiResponse';
import { Competition } from '../shared/types/Competition';
import { CompetitionFormData } from '../shared/types/CompetitionFormData';
import { AttachmentTemplate } from '../shared/types/AttachmentTemplate';
import { User } from '../shared/types/User';

export const CompetitionService = {
  resource: 'Competition',
  getAll: () => {
    return ApiService.get<ApiResponse<Array<Competition>>>(CompetitionService.resource, { withCredentials: true });
  },
  getForCompetitionCoordinator: () => {
    return ApiService.get<ApiResponse<Array<Competition>>>(`${CompetitionService.resource}/competition-coordinator`, { withCredentials: true });
  },
  getActive: () => {
    return ApiService.get<ApiResponse<Array<Competition>>>(`${CompetitionService.resource}/active`, { withCredentials: true });
  },
  getActiveForView: () => {
    return ApiService.get<ApiResponse<Array<Competition>>>(`${CompetitionService.resource}/active-for-view`, { withCredentials: true });
  },  
  getInactive: () => {
    return ApiService.get<ApiResponse<Array<Competition>>>(`${CompetitionService.resource}/inactive`, { withCredentials: true });
  },
  getOne: (competitionId: Competition['id']) => {
    return ApiService.get<ApiResponse<Competition>>(`${CompetitionService.resource}/${competitionId}`, { withCredentials: true });
  },
  add: (data: CompetitionFormData) => {
    return ApiService.post(`${CompetitionService.resource}`, data, { withCredentials: true });
  },
  update: (competitionId: Competition['id'], data: CompetitionFormData) => {
    return ApiService.put(`${CompetitionService.resource}/${competitionId}`, data, { withCredentials: true });
  },
  addFile: (competitionId: Competition['id'], data: FormData) => {
    return ApiService.post(`${CompetitionService.resource}/${competitionId}/attachment-templates`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  getAttachmentTemplates: (competitionId: Competition['id']) => {
    return ApiService.get<ApiResponse<Array<AttachmentTemplate>>>(`${CompetitionService.resource}/${competitionId}/attachment-templates`, {
      withCredentials: true,
    });
  },
  deleteAttachmentTemplate: (attachmentTemplateId: AttachmentTemplate['id']) => {
    return ApiService.delete(`${CompetitionService.resource}/attachment-templates/${attachmentTemplateId}`, {
      withCredentials: true,
    });
  },
  addCompetitionCoordinators: (competitionId: Competition['id'], userIds: string[]) => {
    return ApiService.post(`${CompetitionService.resource}/${competitionId}/competition-coordinators`, userIds, { withCredentials: true });
  },
  getCompetitionCoordinators: (competitionId: Competition['id']) => {
    return ApiService.get<ApiResponse<Array<User>>>(`${CompetitionService.resource}/${competitionId}/competition-coordinators`, { withCredentials: true });
  },
  deleteCompetitionCoordinator: (competitionId: Competition['id'], userId: string) => {
    return ApiService.delete(`${CompetitionService.resource}/${competitionId}/competition-coordinator/${userId}`, { withCredentials: true });
  }
};
