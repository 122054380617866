import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { List } from '../../List';
import { Box } from '../../Box';
import { ErrorService } from '../../../services/Error.service';
import { Message } from '../../Message';
import { BoxLoader } from '../../BoxLoader';
import { FoundationInNumbers } from '../../../shared/types/FoundationInNumbers';
import { ReportsService } from '../../../services/Reports.service';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const FoundationInNumbersReportScreenComponent: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState<Array<FoundationInNumbers>>([]);
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const listHeader = [
    {
      key: 'edition',
      text: t('edition')
    },
    {
      key: 'name',
      text: t('competitionName')
    },
    {
      key: 'numberOfSubmissions',
      text: t('numberOfSentSubmissions')
    },
    {
      key: 'numberOfSubmissionsWithGrants',
      text: t('numberOfSubmissionsWithGrants')
    },
    {
      key: 'numberOfClosedSubmissions',
      text: t('numberOfClosedSubmissions')
    },
    {
      key: 'sumOfCosts',
      text: t('sumOfCosts')
    }
  ];

  const mapRowsToListRows = () =>
    reportData.map((reportDataRow) => ({
      id: reportDataRow.id,
      items: [
        {
          key: 'edition',
          content: reportDataRow.edition
        },
        {
          key: 'name',
          content: reportDataRow.name
        },
        {
          key: 'numberOfSubmissions',
          content: reportDataRow.numberOfSubmissions
        },
        {
          key: 'numberOFSubmissionsWithGrants',
          content: reportDataRow.numberOfSubmissionsWithGrants
        },
        {
          key: 'numberOFClosedSubmissions',
          content: reportDataRow.numberOfClosedSubmissions
        },
        {
          key: 'sumOfCosts',
          content: reportDataRow.sumOfCosts
        }
      ]
    }));

  const fetchReportData = () => {
    (async () => {
      try {
        setServerError('');
        setLoading(true);

        const {
          data: { result },
        } = await ReportsService.getFoundationInNumbers();

        setReportData(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchReportData, []);

  return (
    <div>
      <SectionHeader title={t('reports')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Box>{loading ? <BoxLoader /> : <List headerCells={listHeader} rows={mapRowsToListRows()} />}</Box>
      )}
    </div>
  );
};

export const FoundationInNumbersReportScreen = withStyles(styles)(FoundationInNumbersReportScreenComponent);
