import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { TextBoxOutline, TrophyVariantOutline } from 'mdi-material-ui';

import { SidebarMenuItem } from '../SidebarMenuItem';
import { useTranslator } from '../../i18n/useTranslator';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AuthorSidebarContentComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <div>
      <SidebarMenuItem icon={TrophyVariantOutline} label={t('competitions')} to={'/author/competitions'} />
      <SidebarMenuItem icon={TextBoxOutline} label={t('mySubmissions')} to={'/author/submissions'} />
    </div>
  );
};

export const AuthorSidebarContent = withStyles(styles)(AuthorSidebarContentComponent);
