import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { TextBoxOutline } from 'mdi-material-ui';
import { RouteComponentProps, withRouter } from 'react-router';

import { SidebarMenuItem } from '../SidebarMenuItem';
import { useTranslator } from '../../i18n/useTranslator';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const ReviewerSidebarContentComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <div>
      <SidebarMenuItem icon={TextBoxOutline} label={t('activeSubmissions')} to={'/reviewer/submissions/active'} />
      {/* <SidebarMenuItem icon={TextBoxCheckOutline} label={t('archiveSubmissions')} to={'/reviewer/submissions/archive'} /> */}
    </div>
  );
};

export const ReviewerSidebarContent = withStyles(styles)(withRouter(ReviewerSidebarContentComponent));
