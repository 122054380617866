import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { ActiveCompetitionList } from '../../ActiveCompetitionList';
import { SectionHeader } from '../../SectionHeader';
import { useTranslator } from '../../../i18n/useTranslator';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const CompetitionCommitteeMemberCompetitionsScreenComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <div>
      <SectionHeader title={t('competitions')} />
      <ActiveCompetitionList rolePath={'competition-committee-member'} />
    </div>
  );
};

export const CompetitionCommitteeMemberCompetitionsScreen = withStyles(styles)(CompetitionCommitteeMemberCompetitionsScreenComponent);
