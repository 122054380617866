import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import classNames from 'classnames';

const styles = () =>
  createStyles({
    root: {
      fontWeight: 600,
      '&:hover': {
        opacity: 0.8,
      },
    },
    clearStylings: {
      fontWeight: 'inherit',
      '&:hover': {
        opacity: 1,
      },
    },
  });

type Props = RouterLinkProps &
  WithStyles<typeof styles> & {
    className?: string;
    clearStylings?: boolean;
  };

const LinkComponent: React.FunctionComponent<Props> = ({ classes, className, clearStylings, ...restProps }) => {
  return (
    <RouterLink
      className={classNames(
        classes.root,
        {
          [classes.clearStylings]: clearStylings,
        },
        className
      )}
      {...restProps}
    />
  );
};

export const Link = withStyles(styles)(LinkComponent);
