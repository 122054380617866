import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import { BorderColorSharp } from '@material-ui/icons';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { List } from '../../List';
import { Box } from '../../Box';
import { ErrorService } from '../../../services/Error.service';
import { CompetitionService } from '../../../services/Competition.service';
import { Competition } from '../../../shared/types/Competition';
import { Message } from '../../Message';
import { DateService } from '../../../services/Date.service';
import { BoxLoader } from '../../BoxLoader';
import { Link } from '../../Link';
import { ListAction } from '../../ListAction';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const CompetitionCoordinatorCompetitionsScreenComponent: React.FunctionComponent<Props> = ({ classes }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(true);
  const [competitions, setCompetitions] = useState<Array<Competition>>([]);

  const { t } = useTranslator();

  const listHeader = [{
    key: 'name',
    text: t('competitionName'),
  }, {
    key: 'startDate',
    text: t('startDate'),
  }, {
    key: 'endDate',
    text: t('endDate'),
  }, {
    key: 'type',
    text: t('competitionType'),
  }, {
    key: 'status',
    text: t('status'),
  }, {
    key: 'action',
    text: '',
    pullRight: true,
  }];

  const mapRowsToListRows = () =>
    competitions.map((competition) => ({
      id: competition.id,
      items: [{
        key: 'name',
        content: <Link to={`/competition-coordinator/competitions/${competition.id}/ranking-list`}>{competition.name}</Link>,
      }, {
        key: 'startDate',
        content: moment(competition.startDate).format(DateService.DATE_FORMAT),
      }, {
        key: 'endDate',
        content: moment(competition.endDate).format(DateService.DATE_FORMAT),
      }, {
        key: 'type',
        content: t(`competitionName${competition.type}`),
      }, {
        key: 'status',
        content: competition.isActive ? t('active') : t('inactive'),
      }, {
        key: 'action',
        content:
          <ListAction to={`/competition-coordinator/competitions/${competition.id}`} title={t('edit')}>
            <BorderColorSharp />
          </ListAction>
      }]
    }));

  const fetchCompetitions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result },
        } = await CompetitionService.getForCompetitionCoordinator();

        setCompetitions(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchCompetitions, []);

  return (
    <div>
      <SectionHeader title={t('competitions')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Box>
          {loading ? (
            <BoxLoader />
          ) : competitions?.length ? (
            <List headerCells={listHeader} rows={mapRowsToListRows()} />
          ) : (
            <span>{t('noCompetitionsFound')}</span>
          )}
        </Box>
      )}
    </div>
  );
};

export const CompetitionCoordinatorCompetitionsScreen = withStyles(styles)(CompetitionCoordinatorCompetitionsScreenComponent);
