import axios, { AxiosRequestConfig } from 'axios';

export const API_URL = process.env.REACT_APP_API_URL || `${window.location.origin}/api`;

const headers = {
  'Content-Type': 'application/json',
};

const httpConfig = {
  headers,
};

const getConfig = (config?: AxiosRequestConfig) => ({
  ...httpConfig,
  ...config,
});

export const ApiService = {
  get: <T>(resource: string, config?: AxiosRequestConfig) => {
    return axios.get<T>(`${API_URL}/${resource}`, getConfig(config));
  },
  post: <T>(resource: string, data?: object, config?: AxiosRequestConfig) => {
    return axios.post<T>(`${API_URL}/${resource}`, data, getConfig(config));
  },
  put: <T>(resource: string, data?: object, config?: AxiosRequestConfig) => {
    return axios.put<T>(`${API_URL}/${resource}`, data, getConfig(config));
  },
  delete: (resource: string, config?: AxiosRequestConfig) => {
    return axios.delete(`${API_URL}/${resource}`, getConfig(config));
  },
};
