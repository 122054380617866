import React, { ComponentType } from 'react';
import { createStyles, WithStyles, withStyles, SvgIconProps, Theme } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Color from 'color';
import classNames from 'classnames';

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 16,
      paddingRight: 20,
      paddingTop: 2,
      paddingBottom: 2,
      cursor: 'pointer',
      color: Color(palette.common.white).alpha(0.5).rgb().string(),
      borderLeft: `4px solid transparent`,
      '&:not(:first-child)': {
        marginTop: 20,
      },
      '&:hover': {
        color: palette.common.white,
      },
    },
    label: {
      marginLeft: 20,
    },
    active: {
      color: palette.common.white,
      borderLeft: `4px solid ${palette.common.white}`,
    },
    iconContainer: {
      minWidth: 30,
      display: 'flex',
    },
  });

type Props = WithStyles<typeof styles> & {
  icon: ComponentType<SvgIconProps>;
  label: string;
  to?: string;
  active?: boolean;
};

const SidebarMenuItemComponent: React.FunctionComponent<Props> = ({ classes, active, icon: Icon, label, to }) => {
  return to ? (
    <NavLink className={classes.root} to={to} activeClassName={classes.active} exact>
      <div className={classes.iconContainer}>
        <Icon />
      </div>
      <span className={classes.label}>{label}</span>
    </NavLink>
  ) : (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={classes.iconContainer}>
        <Icon />
      </div>
      <span className={classes.label}>{label}</span>
    </div>
  );
};

export const SidebarMenuItem = withStyles(styles)(SidebarMenuItemComponent);
