import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router';
import qs from 'query-string'

import { UnloggedLayout } from '../layouts/UnloggedLayout';
import { Box } from '../Box';
import { BoxHeader } from '../BoxHeader';
import { useTranslator } from '../../i18n/useTranslator';
import { Message } from '../Message';
import { UserService } from '../../services/User.service';
import { ErrorService } from '../../services/Error.service';
import { BoxLoader } from '../BoxLoader';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const ConfirmEmailScreenComponent: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [, setError] = useState('');

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslator();

  const confirmEmail = () => {
    (async () => {
      try {
        const query = qs.parse(location.search, { decode: false })
        const code = query.code as string
        const id = query.id as string

        await UserService.confirmEmail(code, id)
      
        history.push('/login', { confirmedEmail: true })
      } catch (error) {
        setError(ErrorService.parseError(error))
      }
  
      setLoading(false);
    })()
  }

  useEffect(confirmEmail, [])

  return (
    <UnloggedLayout>
    <Box>
      <BoxHeader title={t('emailConfirmation')} />
      {loading ? (
        <BoxLoader />
      ) : (
        <Message type={'ERROR'}>{t('errorConfirmEmail')}</Message>
      )}
    </Box>
  </UnloggedLayout>
  );
};

export const ConfirmEmailScreen = withStyles(styles)(ConfirmEmailScreenComponent);
