import React from "react";
import { createStyles, Grid, WithStyles, withStyles } from "@material-ui/core";

import { useTranslator } from "../../i18n/useTranslator";
import { FieldTextField } from "../form-fields/FieldTextField";
import { CompetitionCategoryEnum } from "../../shared/enums/CompetitionCategoryEnum";
import { FieldCheckbox } from "../form-fields/FieldCheckbox";
import { useField } from "formik";

const styles = () =>
  createStyles({
    form: {
      marginTop: 10,
    }
  });

type Props = WithStyles<typeof styles> & {
  competitionCategory: CompetitionCategoryEnum;
  isSubmissionEdit?: boolean;
};

export type SubmissionFormValues = {
  projectName: string;
  unitInfo: string;
  estimatedFinishTime?: number | string;
  estimatedFunds?: number | string;
  academicTitle: string;
  requiredConsent: string;
  acceptedRequiredConsent: boolean;
};

const SubmissionFormComponent: React.FunctionComponent<Props> = ({
  classes,
  competitionCategory,
  isSubmissionEdit
}) => {
  const { t } = useTranslator();

  const getProjectNameFieldLabel = () => {
    return competitionCategory === CompetitionCategoryEnum.Person ?
      t("prizeCandidate") : t("projectName");
  };

  const getUnitInfoFieldLabel = () => 
    competitionCategory === CompetitionCategoryEnum.Person ?
      t("candidateUnitInfo") : t("unitInfo");
      
  const [requiredConsent] = useField('requiredConsent');

  return (
    <Grid container spacing={2} className={classes.form}>
      <Grid item xs={12}>
        <FieldTextField
          name={"projectName"}
          label={getProjectNameFieldLabel() + ' (*)'}
          fullWidth
          disabled={isSubmissionEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldTextField 
          name={"unitInfo"} 
          label={getUnitInfoFieldLabel() + ' (*)'} 
          fullWidth 
          disabled={isSubmissionEdit}
      />
      </Grid>
      <Grid item xs={6}>
        <FieldTextField
          name={"academicTitle"}
          label={t("academicTitle") + ' (*)'}
          fullWidth
          disabled={isSubmissionEdit}
        />
      </Grid>
      {competitionCategory === CompetitionCategoryEnum.Project && (
        <>
          <Grid item xs={6}>
            <FieldTextField
              name={"estimatedFinishTime"}
              label={t("estimatedFinishTime") + ' (*)'}
              fullWidth
              disabled={isSubmissionEdit}
              type={"number"}
            />
          </Grid>
          <Grid item xs={6}>
            <FieldTextField
              name={"estimatedFunds"}
              label={t("estimatedFunds") + ' (*)'}
              fullWidth
              disabled={isSubmissionEdit}
              type={"number"}
            />
          </Grid>
        </>
      )}

      {competitionCategory === CompetitionCategoryEnum.Person && (
        <Grid item xs={12}>
          <FieldCheckbox 
            name={"acceptedRequiredConsent"}
            label={t('acceptRequiredConsentLabel') + ' (*)'}
            additionalText={requiredConsent.value}
            disabled={isSubmissionEdit}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const SubmissionForm = withStyles(styles)(SubmissionFormComponent);
