import React, { useRef } from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';

import { TextField } from './TextField';
import { useTranslator } from '../../i18n/useTranslator';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    smallTextField: {
      maxWidth: 200,
    },
    fileInput: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0,
      width: '100%',
      zIndex: 9,
      cursor: 'pointer',
    },
    input: {
      fontSize: 14,
      padding: '7px 0',
    },
    removeIcon: {
      position: 'absolute',
      right: 5,
      fontSize: 20,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 999,
      cursor: 'pointer',
      color: custom.gray.g_2,
      '&:hover': {
        color: custom.gray.g_4,
      },
    },
    chooseFileLabel: {
      pointerEvents: 'none',
      backgroundColor: custom.gray.g_0,
      borderRadius: custom.radius.r_0,
      padding: '0 5px',
      fontSize: 11,
      whiteSpace: 'nowrap',
      zIndex: 9,
    },
    adormentStart: {
      paddingLeft: 10,
    },
    adormentEnd: {
      paddingRight: 7,
    },
    adornmentMediumSize: {
      paddingRight: 14,
    },
    adornmentEndWithValue: {
      paddingRight: 28,
    },
    inputLabelMarginDense: {
      fontSize: 14,
      transform: 'translate(10px, 8px) scale(1) !important',
    },
  });

type Props = WithStyles<typeof styles> & {
  label: string;
  value: File | null;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  allowedMimeTypes?: string[];
  onChange?: (value: File | null) => void;
};

const FileInputComponent: React.FunctionComponent<Props> = ({
  classes,
  label,
  size = 'medium',
  error,
  helperText,
  fullWidth,
  value,
  allowedMimeTypes,
  onChange,
}) => {
  const { t } = useTranslator();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const file = files ? files[0] : null;

    onChange && onChange(file);
  };

  const handleClear = () => {
    onChange && onChange(null);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classNames({
          [classes.smallTextField]: size === 'small',
        })}
        label={size === 'small' && value ? '' : label}
        size={'small'}
        fullWidth={fullWidth}
        value={value ? value.name : ''}
        error={error}
        helperText={helperText}
        variant={'outlined'}
        InputLabelProps={{
          classes: {
            marginDense: size === 'small' ? classes.inputLabelMarginDense : '',
          },
          shrink: Boolean(value),
        }}
        InputProps={{
          classes: {
            input: size === 'small' ? classes.input : '',
            adornedStart: size === 'small' ? classes.adormentStart : '',
            adornedEnd: classNames(classes.adormentEnd, {
              [classes.adornmentEndWithValue]: value,
              [classes.adornmentMediumSize]: size !== 'small',
            }),
          },
          startAdornment: 
            <input ref={inputRef} className={classes.fileInput} type={'file'} onChange={handleFileChange}
              accept={allowedMimeTypes?.join(',')} />,
          endAdornment: !value ? (
            <div className={classes.chooseFileLabel}>{t('chooseFile')}</div>
          ) : (
            <Close className={classes.removeIcon} onClick={handleClear} />
          ),
        }}
      />
    </div>
  );
};

export const FileInput = withStyles(styles)(FileInputComponent);
