import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { UnloggedLayout } from '../layouts/UnloggedLayout';
import { Box } from '../Box';
import { BoxHeader } from '../BoxHeader';
import { Divider } from '../Divider';
import { Button } from '../Button';
import { useTranslator } from '../../i18n/useTranslator';
import { RegistrationForm } from '../forms/RegistrationForm';
import { HelpMessage } from '../HelpMessage';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const RegitrationScreenComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <UnloggedLayout>
      <Box>
        <BoxHeader title={t('registration')} />
        <RegistrationForm />
        <Divider />
        <Button to={'/login'} fullWidth variant={'outlined'}>
          {t('login')}
        </Button>
        <HelpMessage />
      </Box>
    </UnloggedLayout>
  );
};

export const RegitrationScreen = withStyles(styles)(RegitrationScreenComponent);
