import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { AdminRoute } from '../../routes/AdminRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { AdminAddCompetitionScreen } from '../Admin/AdminAddCompetitionScreen';
import { AdminCompetitionDetailsScreen } from '../Admin/CompetitionDetailsScreen';
import { AdminUserDetailsScreen } from '../Admin/AdminUserDetailsScreen';
import { AdminStageDetailsScreen } from '../Admin/AdminStageDetailsScreen';
import { AdminSubmissionDetailsScreen } from '../Admin/AdminSubmissionDetailsScreen';
import { AdminUsersScreen } from '../Admin/AdminUsersScreen';
import { AdminAddUserScreen } from '../Admin/AdminAddUserScreen';
import { AdminCompetitionsScreen } from '../Admin/AdminCompetitionsScreen';
import { AdminEmailTemplatesScreen } from '../Admin/AdminEmailTemplatesScreen';
import { AdminEmailTemplateDetailsScreen } from '../Admin/AdminEmailTemplateDetailsScreen';
import { CompetitionRankingListScreen } from '../Common/CompetitionRankingListScreen';
import { AdminUserCompetitionListScreen } from '../Admin/AdminUserCompetitionListScreen';
import { AdminAddStageScreen } from '../Admin/AdminAddStageScreen';
import { AdminReportsScreen } from '../Admin/AdminReportsScreen';
import { FoundationInNumbersReportScreen } from '../Admin/FoundationInNumbersReportScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const AdminScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <AdminRoute exact path={`${match.path}/users`} component={AdminUsersScreen} />
        <AdminRoute path={`${match.path}/users/add`} component={AdminAddUserScreen} />
        <AdminRoute path={`${match.path}/users/:id/submissions`} component={AdminUserCompetitionListScreen} />
        <AdminRoute path={`${match.path}/users/:id`} component={AdminUserDetailsScreen} />
        <AdminRoute path={`${match.path}/stages/add`} component={AdminAddStageScreen} />
        <AdminRoute path={`${match.path}/stages/:id`} component={AdminStageDetailsScreen} />
        <AdminRoute exact path={`${match.path}/competitions`} component={AdminCompetitionsScreen} />
        <AdminRoute path={`${match.path}/competitions/add`} component={AdminAddCompetitionScreen} />
        <AdminRoute path={`${match.path}/competitions/:id/ranking-list`} component={CompetitionRankingListScreen} />
        <AdminRoute path={`${match.path}/competitions/:id`} component={AdminCompetitionDetailsScreen} />
        <AdminRoute exact path={`${match.path}/submissions/:id`} component={AdminSubmissionDetailsScreen} />
        <AdminRoute exact path={`${match.path}/templates`} component={AdminEmailTemplatesScreen} />
        <AdminRoute exact path={`${match.path}/templates/:type`} component={AdminEmailTemplateDetailsScreen} />
        <AdminRoute exact path={`${match.path}/reports`} component={AdminReportsScreen}/>
        <AdminRoute exact path={`${match.path}/reports/foundation-in-numbers`} component={FoundationInNumbersReportScreen}/>
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const AdminScreens = withStyles(styles)(AdminScreensComponent);
