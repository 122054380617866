import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { pl } from './translations/pl';

i18n.use(initReactI18next).init({
  resources: {
    pl,
  },
  lng: 'pl',
  fallbackLng: 'pl',
  debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
