import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { RoleEnum } from '../shared/enums/RoleEnum';
import { Badge } from './Badge';
import { useTranslator } from '../i18n/useTranslator';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      width: 'fit-content'
    },
  });

type Props = WithStyles<typeof styles> & {
  role?: RoleEnum;
  className?: string;
};

const RoleBadgeComponent: React.FunctionComponent<Props> = ({ classes, className, role }) => {
  const { t } = useTranslator();

  const getColor = () => {
    switch (role) {
      case RoleEnum.Author:
        return '#350bb5';
      case RoleEnum.Reviewer:
        return '#0d709e';
      default:
        return '#350bb5';
    }
  };

  const getText = () => {
    switch (role) {
      case RoleEnum.Author:
        return 'authorDocument';
      case RoleEnum.Reviewer:
        return 'reviewerDocument';
      default:
        return 'authorDocument';
    }
  };

  return (
    <Badge className={classNames(classes.root, className)} color={getColor()}>
      {t(getText())}
    </Badge>
  );
};

export const RoleBadge = withStyles(styles)(RoleBadgeComponent);
