import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import sortBy from 'lodash/sortBy';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { Box } from '../../Box';
import { CompetitionForm } from '../../forms/CompetitionForm';
import { Competition } from '../../../shared/types/Competition';
import { CompetitionService } from '../../../services/Competition.service';
import { DocumentationEdit } from '../../DocumentationEdit';
import { AttachmentTemplate } from '../../../shared/types/AttachmentTemplate';
import { CompetitionCoordinatorsSelector } from '../../CompetitionCoordinatorsSelector';
import { BillingMethodEnum } from '../../../shared/enums/BillingMethodEnum';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const AdminCompetitionDetailsScreenComponent: React.FunctionComponent<Props> = ({ match }) => {
  const [competition, setCompetition] = useState<Competition>();
  const [attachmentTemplates, setAttachmentTemplates] = useState<Array<AttachmentTemplate>>([]);
  const [billingMethod, setBillingMethod] = useState(BillingMethodEnum.PerReview);
  
  const { t } = useTranslator();

  const fetchCompetition = () => {
    (async () => {
      const {
        data: { result },
      } = await CompetitionService.getOne(match.params.id);

      setCompetition(result);
    })();
  };

  const fetchAttachmentTemplates = () => {
    (async () => {
      const {
        data: { result },
      } = await CompetitionService.getAttachmentTemplates(match.params.id);

      setAttachmentTemplates(sortBy(result, 'role'));
    })();
  };

  const addFileServiceHandler = async (file: FormData) => {
    if (competition?.id) {
        await CompetitionService.addFile(competition?.id, file);
    }
  };

  const deleteFileServiceHandler = async (template: AttachmentTemplate) => {
    if (template?.id) {
        await CompetitionService.deleteAttachmentTemplate(template.id);
    }
  };

  const init = () => {
    fetchCompetition();
    fetchAttachmentTemplates();
  };

  useEffect(init, []);

  return competition ? (
    <div>
      <SectionHeader title={t('competitionDetails')} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <CompetitionForm
              initValues={competition}
              competitionId={competition.id}
              attachmentTemplates={attachmentTemplates}
              setBillingMethod={setBillingMethod}
              isCompetitionEdit
            />
            <CompetitionCoordinatorsSelector competitionId={competition.id} isUpdate />
            <DocumentationEdit
              deleteFileServiceHandler={deleteFileServiceHandler}
              addFileServiceHandler={addFileServiceHandler}
              attachmentTemplates={attachmentTemplates}
              onNewFileAdded={fetchAttachmentTemplates}
              onFileDeleted={fetchAttachmentTemplates}
              billingMethod={billingMethod}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  ) : null;
};

export const AdminCompetitionDetailsScreen = withStyles(styles)(AdminCompetitionDetailsScreenComponent);
