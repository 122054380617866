import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { CompetitionCoordinatorRoute } from '../../routes/CompetitionCoordinatorRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { AdminCompetitionDetailsScreen } from '../Admin/CompetitionDetailsScreen';
import { AdminStageDetailsScreen } from '../Admin/AdminStageDetailsScreen';
import { AdminSubmissionDetailsScreen } from '../Admin/AdminSubmissionDetailsScreen';
import { CompetitionRankingListScreen } from '../Common/CompetitionRankingListScreen';
import { AdminUserCompetitionListScreen } from '../Admin/AdminUserCompetitionListScreen';
import { AdminAddStageScreen } from '../Admin/AdminAddStageScreen';
import { CompetitionCoordinatorCompetitionsScreen } from '../CompetitionCoordinator/CompetitionCoordinatorCompetitionsScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const CompetitionCoordinatorScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <CompetitionCoordinatorRoute exact path={`${match.path}/competitions`} component={CompetitionCoordinatorCompetitionsScreen} />
        <CompetitionCoordinatorRoute path={`${match.path}/competitions/:id/ranking-list`} component={CompetitionRankingListScreen} />
        <CompetitionCoordinatorRoute path={`${match.path}/competitions/:id`} component={AdminCompetitionDetailsScreen} />
        <CompetitionCoordinatorRoute exact path={`${match.path}/submissions/:id`} component={AdminSubmissionDetailsScreen} />
        <CompetitionCoordinatorRoute path={`${match.path}/stages/add`} component={AdminAddStageScreen} />
        <CompetitionCoordinatorRoute path={`${match.path}/stages/:id`} component={AdminStageDetailsScreen} />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const CompetitionCoordinatorScreens = withStyles(styles)(CompetitionCoordinatorScreensComponent);
