import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      flex: 1,
      padding: '20px 0',
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        fontSize: 18,
        color: custom.gray.g_4,
      },
    },
    pullRight: {
      justifyContent: 'flex-end',
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
  pullRight?: boolean;
};

const ListItemComponent: React.FunctionComponent<Props> = ({ classes, className, children, pullRight }) => {
  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.pullRight]: pullRight,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export const ListItem = withStyles(styles)(ListItemComponent);
