import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Form } from '../Form';
import { FieldTextField } from '../form-fields/FieldTextField';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { password, repeatNewPassword } from '../../shared/validators';
import { ErrorService } from '../../services/Error.service';
import { UserService } from '../../services/User.service';
import { FormError } from '../FormError';
import { RouteComponentProps, withRouter } from 'react-router';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> &
RouteComponentProps<void> & {
  token: string;
  userId: string;
};

type FormValues = {
  newPassword: string;
  repeatedNewPassword: string;
};

const CreatePasswordFormComponent: React.FunctionComponent<Props> = ({ history, token, userId }) => {
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      newPassword: password(t),
      repeatedNewPassword: repeatNewPassword(t),
    });

  const initialValues = {
    newPassword: '',
    repeatedNewPassword: '',
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setServerError('');
    setSubmitting(true);

    try {

      await UserService.setNewPasswordByEmailToken({
        token: token,
        newPassword: values.newPassword,
        repeatedNewPassword: values.repeatedNewPassword,
        userId: userId
      });

      history.push('/password/reset/success');
    } catch (error) {
      setServerError(ErrorService.parseError(error));
      console.log(error);
      history.push('/password/reset/error');
    }
    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <FormContent>
          <FieldTextField name={'newPassword'} label={t('newPassword')} type={'password'} />
          <FieldTextField name={'repeatedNewPassword'} label={t('repeatNewPassword')} type={'password'} />

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <Button type={'submit'} loading={isSubmitting}>
            {t('send')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const CreatePasswordForm = withStyles(styles)(withRouter(CreatePasswordFormComponent));
