import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';

import { DiscussionTile } from './DiscussionTile';
import { SubmissionService } from '../services/Submissions.service';
import { Comment } from '../shared/types/Comment';
import { ErrorService } from '../services/Error.service';
import { AddComment } from './AddComment';
import { BoxLoader } from './BoxLoader';
import { Message } from './Message';
import { useTranslator } from '../i18n/useTranslator';
import { CommentStatusEnum } from '../shared/enums/CommentStatusEnum';

const styles = () =>
  createStyles({
    root: {},
    tile: {
      '&:not(:first-child)': {
        marginTop: 20,
      },
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const DiscussionComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [comments, setComments] = useState<Array<Comment>>([]);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const fetchComments = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const {
          data: { result },
        } = await SubmissionService.getComments(match.params.id);

        setComments(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchComments, []);

  return (
    <div className={classes.root}>
      {loading ? (
        <BoxLoader />
      ) : (
        <>
          <AddComment
            submissionId={match.params.id}
            initValues={comments.find(comment => comment.status === CommentStatusEnum.Autosaved)}
            onSaved={fetchComments}
          />
          {serverError ? (
            <Message type={'ERROR'}>{t(serverError)}</Message>
          ) : (
            comments
              .filter(comment => comment.status === CommentStatusEnum.Submitted)
              .map(comment => <DiscussionTile className={classes.tile} {...comment} />)
          )}
        </>
      )}
    </div>
  );
};

export const Discussion = withStyles(styles)(withRouter(DiscussionComponent));
