import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { ReactComponent as Logo } from '../../assets/nfp-logo-dark.svg';

import BackgroundImage from '../../assets/bg.jpg';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 40,
      paddingBottom: 40,
      height: '100vh',
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat'
    },
    boxContainer: {
      maxWidth: 650,
      margin: '40px auto',
      width: '100%',
    },
    logo: {
      maxHeight: 60,
    },
  });

type Props = WithStyles<typeof styles>;

const UnloggedLayoutComponent: React.FunctionComponent<Props> = ({ children, classes }) => {
  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <div className={classes.boxContainer}>{children}</div>
    </div>
  );
};

export const UnloggedLayout = withStyles(styles)(UnloggedLayoutComponent);
