import React, { useState, useRef, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Grid, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useTranslator, TFunction } from '../i18n/useTranslator';
import { BoxSection } from './BoxSection';
import { LabelValue } from './LabelValue';
import { SubmissionDocumentation } from './SubmissionDocumentation';
import { RootState } from '../store/rootReducer';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { Submission } from '../shared/types/Submission';
import { DateService } from '../services/Date.service';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { FormError } from './FormError';
import { Button } from './Button';
import { SubmissionService } from '../services/Submissions.service';
import { ErrorService } from '../services/Error.service';
import { Discussion } from './Discussion';
import { Notes } from './Notes';
import { Mark } from '../shared/types/Mark';
import { getSubmissionStatusLabel } from '../shared/helpers/getSubmissionStatusLabel';
import { SubmissionForm } from './forms/SubmissionForm';
import { Form } from "./Form";
import { SubmissionStage } from '../shared/types/SubmissionStage';
import { FormContent } from "./FormContent";
import * as Yup from 'yup';
import { projectName, unitInfo, academicTitle, estimatedFinishTime, estimatedFunds } from '../shared/validators';
import { SubmissionStagesDocumentation } from './SubmissionStagesDocumentation';
import { BoxHeader } from './BoxHeader';
import { ReviewSubmission } from './ReviewSubmission';
import { SubmissionStatusEnum } from '../shared/enums/SubmissionStatusEnum';
import { CompetitionCategoryEnum } from '../shared/enums/CompetitionCategoryEnum';
import { ConfirmationDialog } from './ConfirmationDialog';
import { AxiosResponse } from 'axios';
import { Autosave } from './Autosave';
import { AttachmentStatusEnum } from '../shared/enums/AttachmentStatusEnum';
import { MarkStatusEnum } from '../shared/enums/MarkStatusEnum';

const styles = () =>
  createStyles({ 
    root: {
      display: 'flex',
      flexDirection: 'column',
    },    
    actionsBox: {
      marginBottom: 20,
    },
    section: {},  
    row: {
      marginBottom: 12,
    },
    sendDocumentsButton: {
      alignSelf: 'flex-end',
      marginTop: 20,
    },
    error: {
      marginTop: 10,
      marginBottom: 0,
    },
    sectionsContainer: {
      marginBottom: 20,
    },       
    topError: {
      marginBottom: 30,
    },
    mark: {
      width: '50%',
      marginTop: 30
    }
  });

type SubmissionDetailsForm = {
  projectName: string;
  unitInfo: string;
  estimatedFinishTime?: number;
  estimatedFunds?: number;
  academicTitle: string;
  requiredConsent: string;
  acceptedRequiredConsent: boolean;
};

type AttachmentType = 'author' | 'reviewer';
  
type Props = WithStyles<typeof styles> & {
  submission: Submission;
  authorAttachments: Array<SubmissionAttachment>;
  reviewerAttachments: Array<SubmissionAttachment>;
  marks?: Array<Mark>;
  summedMarks?: number;
  onChanged?: () => void;
  activeStage?: SubmissionStage;
  canReviewSubmission? : boolean;
  onStageAttachmentsChanged?: (attachments: SubmissionAttachment[]) => void;
};

const SubmissionDetailsComponent: React.FunctionComponent<Props> = ({
  classes,
  reviewerAttachments,
  authorAttachments,
  submission,
  marks,
  summedMarks,
  onChanged,
  activeStage,
  canReviewSubmission,
  onStageAttachmentsChanged
}) => {
  const defaultMark: Mark = {
    id: '',
    reviewer: { firstName: '', lastName: '' },
    submissionId: '',
    reviewerId: '',
    mark: 0,
    status: MarkStatusEnum.Invalid
  };

  const { role } = useSelector((state: RootState) => state.user);

  const [shouldValidateFiles, setShouldValidateFiles] = useState(false);
  const [shouldValidateMark, setShouldValidateMark] = useState(false);
  const [serverError, setServerError] = useState('');
  const [savingAuthorAttachments, setSavingAuthorAttachments] = useState(false);
  const [savingReviewerAttachments, setSavingReviewerAttachments] = useState(false);
  const [markServerError, setMarkServerError] = useState('');
  const [mark, setMark] = useState(defaultMark);
  const [initialMark, setInitialMark] = useState<Mark>();
  const [markLoading, setMarkLoading] = useState(true);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [typeOfTemplatesToUpdate, setTypeOfTemplatesToUpdate] = useState<AttachmentType>();

  const [attachmentsToUpload, setAttachmentsToUpload] = useState<Map<string, File | null>>(new Map());
  const [authorAttachmentsToUpdate, setAuthorAttachmentsToUpdate] = useState<Map<string, File | null>>(new Map());
  const [reviewerAttachmentsToUpdate, setReviewerAttachmentsToUpdate] = useState<Map<string, File | null>>(new Map());
  const [autosaving, setAutosaving] = useState(false);
  const [submittingReview, setSubmittingReview] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const initialAttachments = useRef<Map<string, File | null>>(new Map()).current;

  const initialValues: SubmissionDetailsForm = {
    projectName: submission.project.title,
    unitInfo: submission.project.scientificFacility,
    estimatedFinishTime: submission.project.duration,
    estimatedFunds: submission.project.cost,
    academicTitle: submission.project.academicTitle,
    requiredConsent: submission.project.requiredConsent,
    acceptedRequiredConsent: submission.project.acceptedRequiredConsent
  };
  
  const getAttachmentsByRole = (): SubmissionAttachment[] => {
    switch (role) {
      case RoleEnum.Author:
        return authorAttachments;
      case RoleEnum.Reviewer:
        return reviewerAttachments;
      default:
        return [];
    }
  };

  const attachmentsByRole = getAttachmentsByRole();

  const handleFileChange = (templateId: string, file: File | null) => {
    setAttachmentsToUpload(map => new Map(map.set(templateId, file)));
  };

  const handleAuthorFileChange = (attachmentId: string, file: File | null) => {
    setAuthorAttachmentsToUpdate(map => new Map(map.set(attachmentId, file)));
  };

  const handleReviewerFileChange = (attachmentId: string, file: File | null) => {
    setReviewerAttachmentsToUpdate(map => new Map(map.set(attachmentId, file)));
  };

  const handleMarkChange = (value: number | number[]) => {
    setMark(mark => ({
      ...mark,
      mark: value as number || defaultMark.mark
    }));
  };
  
  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      projectName: projectName(t),
      unitInfo: unitInfo(t),
      academicTitle: academicTitle(t),
      estimatedFinishTime: estimatedFinishTime(t, submission.competition.category === CompetitionCategoryEnum.Project),
      estimatedFunds: estimatedFunds(t, submission.competition.category === CompetitionCategoryEnum.Project)
    });

  const hasAttachmentsToSubmit = (docsAssignedTo: RoleEnum) => {
    let attachments: SubmissionAttachment[] = [];
    switch (docsAssignedTo) {
      case RoleEnum.Author:
        attachments = authorAttachments;
        break;
      case RoleEnum.Reviewer:
        attachments = reviewerAttachments;
        break;
    }

    const hasAttachmentsToUpload = Array.from(attachmentsToUpload).some(([templateId, file]) => Boolean(file));
    const hasAutosavedAttachments = attachments.some(attachment => attachment.attachments.some(attach => attach.status === AttachmentStatusEnum.Autosaved));
    
    return hasAttachmentsToUpload || hasAutosavedAttachments;
  };

  const hasAttachmentsToUpdate = (attachmentsToUpdate: Map<string, File | null>) =>
    Array.from(attachmentsToUpdate).some(([templateId, file]) => Boolean(file));

  const isMarkToSubmit = () => {
    return mark.mark && (mark.mark !== initialMark?.mark || mark.status === MarkStatusEnum.Autosaved);
  };

  const hasValidDocuments = () => {
    for (const attachment of attachmentsByRole) {
      if (attachment.role === role && !attachmentsToUpload.get(attachment.templateId) &&
        !attachment.attachments.length && attachment.isRequired === true) {
        
        return false;
      }
    }

    return true;
  };

  const hasValidMark = () => {
    return mark?.mark !== 0;
  }

  const saveAttachments = async (submissionId: Submission["id"]) => {
    const promises: Promise<AxiosResponse<any>>[] = [SubmissionService.submitAttachments(submissionId)];
    attachmentsToUpload.forEach((file, templateId) =>{
      if (!file) {
        return;
      }
      const formData = new FormData();

      formData.append("file", file);
      formData.append("parentId", submissionId);
      formData.append("templateId", templateId);
      formData.append('status', AttachmentStatusEnum.Submitted.toString());

      promises.push(SubmissionService.addFile(formData));
    });

    return Promise.all(promises);
  };

  const saveMark = async () => {
    if (!mark) {
      return;
    }
    
    const formData = new FormData();
    formData.append("submissionId", submission.id);
    formData.append('mark', mark.mark?.toString() ?? '0');
    formData.append('status', MarkStatusEnum.Submitted.toString());

    await SubmissionService.mark(formData);
  };

  const updateAttachments = async (attachmentsToUpdate: Map<string, File | null>, type: 'author' | 'reviewer') => {
    try {
      const promises: Promise<AxiosResponse<any>>[] = [];

      attachmentsToUpdate.forEach((file, attachmentId) => {
        if (!file) {
          return;
        }
        const formData = new FormData();

        formData.append("file", file);
        formData.append("parentId", submission.id);

        promises.push(SubmissionService.updateFile(attachmentId, formData));
      });

      await Promise.all(promises);

      onChanged && onChanged();
    }
    catch (error) {
      setServerError(ErrorService.parseError(error));
    } finally {
      setSavingAttachments(false, type);
    }
  };

  const handleSaveReview = () => {
    (async () => {
      if (!submittingReview || autosaving) {
        return;
      }

      try {
        await saveAttachments(submission.id);
        await saveMark();

        onChanged && onChanged();
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      finally {
        setSubmittingReview(false);
      }
    })();
  };

  useEffect(handleSaveReview, [submittingReview, autosaving]);

  const handleReviewerSubmit = () => {
    setShouldValidateFiles(true);
    setShouldValidateMark(true);
    if (!hasValidDocuments() || !hasValidMark()) {
      return;
    }

    setServerError("");
    setSubmittingReview(true);
  };

  const handleSaveSubmission = () => {
    (async () => {
      if (!submitting || autosaving) {
        return;
      }

      try {
        await saveAttachments(submission.id);

        onChanged && onChanged();
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      finally {
        setSubmitting(false);
      }
    })();
  };

  useEffect(handleSaveSubmission, [submitting, autosaving]);

  const handleFormSubmit = () => {
    setShouldValidateFiles(true);
    if (!hasValidDocuments()) {
      return;
    }

    setServerError("");
    setSubmitting(true);
  };

  const setSavingAttachments = (value: boolean, type: AttachmentType): void => {
    type ===  'author' ?
      setSavingAuthorAttachments(value)
      : setSavingReviewerAttachments(value);
  };

  const handleCloseConfirmationDialog = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
      if (!typeOfTemplatesToUpdate) return;

      const attachmentsToUpdate = typeOfTemplatesToUpdate === 'author' ? 
        authorAttachmentsToUpdate 
        : reviewerAttachmentsToUpdate;
        
      if (confirmed) {
        setSavingAttachments(true, typeOfTemplatesToUpdate);
        await updateAttachments(attachmentsToUpdate, typeOfTemplatesToUpdate);
      }
      
      setTypeOfTemplatesToUpdate(undefined);
    })()
  };

  const handleUpdateAttachments = async (attachmentsToUpdate: Map<string, File | null>, type: AttachmentType) => {
    const allAttachments =  authorAttachments.concat(reviewerAttachments);
    const attachmentsForAllReviews = new Array<SubmissionAttachment>();
    attachmentsToUpdate.forEach((file, attachmentId) => {
      if (!file) {
        return;
      }

      var attachment = allAttachments.find(attachment => Boolean(attachment.attachments.find(attach => attach.id === attachmentId)));
      attachment?.isForAllReviews && attachmentsForAllReviews.push(attachment);
    });

    if (attachmentsForAllReviews.length) {
      setTypeOfTemplatesToUpdate(type);
      setIsConfirmDialogOpen(true);
    }
    else {
      setServerError("");
      setSavingAttachments(true, type);

      await updateAttachments(attachmentsToUpdate, type);
    }
  };

  const getProjectNameFieldLabel = () => 
    submission.competition.category === CompetitionCategoryEnum.Person ?
      t('prizeCandidate') : t('projectName');

  const getUnitInfoFieldLabel = () => 
    submission.competition.category === CompetitionCategoryEnum.Person ?
      t('candidateUnitInfo') : t('unitInfo');

  const handleAutosave = async (
    data?: SubmissionDetailsForm,
    attachmentsToAdd?: { key: string; value: File | null; }[],
    mark?: number
  ) => {
    setAutosaving(true);

    try {
      await autosaveAttachments(attachmentsToAdd);
      await autosaveMark(mark);
    }
    finally {
      setAutosaving(false);
    }
  };

  const autosaveAttachments = async (
    attachmentsToAdd?: { key: string; value: File | null; }[]
  ) => {
    if (!attachmentsToAdd) {
      return;
    }

    const promises: Promise<AxiosResponse<any>>[] = [];

    attachmentsToAdd.forEach(file => {
      if (!file.value) {
        return;
      }

      const formData = new FormData();

      formData.append('file', file.value);
      formData.append('parentId', submission.id);
      formData.append('templateId', file.key);
      formData.append('status', AttachmentStatusEnum.Autosaved.toString());

      promises.push(SubmissionService.addFile(formData));
    });

    await Promise.all(promises);

    if (promises.length) {
      onChanged && onChanged();
      setAttachmentsToUpload(map => {
        attachmentsToAdd.forEach(attachment => {
          map.delete(attachment.key);
        });
        return map;
      });
    }
  };

  const autosaveMark = async (mark?: number) => {
    if ((!mark && mark !== 0) || role !== RoleEnum.Reviewer) {
      return;
    }

    const formData = new FormData();
    formData.append("submissionId", submission.id);
    formData.append('mark', mark?.toString() ?? '0');
    formData.append('status', MarkStatusEnum.Autosaved.toString());

    await SubmissionService.mark(formData);

    fetchMark();
  };

  const fetchMark = () => {
    (async () => {
      if (role !== RoleEnum.Reviewer) {
        return;
      }
      
      setMarkLoading(true);
      setMarkServerError('');

      try {
        const {
          data: { result },
        } = await SubmissionService.getMark(submission.id);
        
        if (result) {
          setMark(result);
          setInitialMark(result);
        }
        else {
          setInitialMark(defaultMark);
        }
      } catch (error) {
        setMarkServerError(ErrorService.parseError(error));
      }

      setMarkLoading(false);
    })();
  };

  useEffect(fetchMark, []);

  return (   
    <div className={classes.root}>
      <div className={classes.sectionsContainer}>
          {marks && (
            <BoxSection className={classes.section} title={t("submissionMark")}>
              {marks.length ? (
                <Grid container spacing={2}>
                  {summedMarks && (
                    <Grid item xs={4}>
                      <LabelValue
                        label={t("generalMark")}
                        value={`${summedMarks} pkt.`}
                      />
                    </Grid>
                  )}
                  {marks.map((mark) => (
                    <>
                      <Grid item xs={4}>
                        <LabelValue
                          label={`${mark.reviewer.firstName} ${mark.reviewer.lastName}`}
                          value={`${mark.mark} pkt.`}
                        />
                      </Grid>
                    </>
                  ))}
                </Grid>
              ) : (
                <div>{t("noMarks")}</div>
              )}
            </BoxSection>
          )}

          <BoxSection className={classes.section} title={t("participant")}>
            <Grid className={classes.row} container spacing={2}>
              <Grid item xs={6}>
                <LabelValue
                  label={t("firstName")}
                  value={submission.author.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <LabelValue
                  label={t("lastName")}
                  value={submission.author.lastName}
                />
              </Grid>
            </Grid>
            <Grid className={classes.row} container spacing={2}>
              <Grid item xs={6}>
                <LabelValue
                  label={t("email")}
                  value={submission.author.email}
                />
              </Grid>
              <Grid item xs={6}>
                <LabelValue
                  label={t("phone")}
                  value={submission.author.phoneNumber}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LabelValue
                  label={t("scienceUnit")}
                  value={submission.author.scientificFacility}
                />
              </Grid>
            </Grid>
          </BoxSection>
          <BoxSection className={classes.section} title={t("submission")}>
            {(role !== RoleEnum.Author || (role === RoleEnum.Author && submission.competition.isActive === false)) && (
              <>
                <Grid container spacing={2} className={classes.row}>
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("competitionName")}
                      value={submission.competition.name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("createDate")}
                      value={moment(submission.createdAt).format(
                        DateService.DATE_FORMAT
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("status")}
                      value={t(getSubmissionStatusLabel(submission.status))}
                    />
                  </Grid>

                  {submission.competition.category === CompetitionCategoryEnum.Project && ( 
                    <Grid item xs={4}>
                      <LabelValue
                        label={t("submissionNumber")}
                        value={`${submission.submissionId}/${submission.competition.edition}`}
                      />
                    </Grid>
                  )}

                  <Grid item xs={4}>
                    <LabelValue
                      label={getProjectNameFieldLabel()}
                      value={submission.project.title || "-"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LabelValue
                      label={getUnitInfoFieldLabel()}
                      value={submission.project.scientificFacility || "-"}
                    />
                  </Grid>

                  {submission.competition.category === CompetitionCategoryEnum.Project && (
                    <>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("estimatedFinishTime")}
                          value={submission.project.duration ?? 0}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("estimatedFunds")}
                          value={submission.project.cost ?? 0}
                        />
                      </Grid>
                    </>
                  )}

                  {submission.competition.category === CompetitionCategoryEnum.Person && (
                    <Grid item xs={12}>
                      <LabelValue
                        label={t("requiredConsentLabel")}
                        value={submission.project.requiredConsent}
                        multiline
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider />
              </>
            )}
            <Form
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema(t)}
            >
              {() => (
                <FormContent>
                  {role === RoleEnum.Author && submission.competition.isActive === true && (
                    <React.Fragment>
                      <SubmissionForm
                        competitionCategory={submission.competition.category}
                        isSubmissionEdit
                      />
                      <Divider />
                    </React.Fragment>
                  )}
                  {Boolean(authorAttachments.length) && (
                    <SubmissionDocumentation
                      shouldValidate={shouldValidateFiles}
                      attachments={attachmentsToUpload}
                      submissionAttachments={authorAttachments}
                      submissionStatus={submission.status}
                      submissionId={submission.id}
                      competetionIsActive={submission.competition.isActive}
                      docsAssignedTo={RoleEnum.Author}
                      attachmentsToUpdate={authorAttachmentsToUpdate}
                      onFileChange={handleFileChange}
                      onAttachmentRemoved={onChanged}
                      onAdminFileChange={handleAuthorFileChange}
                    />
                  )}

                  {role === RoleEnum.Author && submission.competition.isActive === true && (
                    <>
                      <FormError
                        className={classes.error}
                        show={Boolean(serverError)}
                        error={serverError}
                      />
                      {authorAttachments.some(attachment => !attachment.attachments?.length || attachment.attachments[0].status === AttachmentStatusEnum.Autosaved) && (
                        <Button
                          className={classes.sendDocumentsButton}
                          loading={submitting}
                          disabled={submitting || !hasAttachmentsToSubmit(RoleEnum.Author)}
                          onClick={() => setShouldValidateFiles(true)}
                          type="submit"
                        >
                          {t("send")}
                        </Button>
                      )}
                      <div className={classes.sendDocumentsButton}>
                        {t("requiredInfo")}
                      </div>

                      <Autosave
                        initialAttachments={initialAttachments}
                        currentAttachments={attachmentsToUpload}
                        submitting={submitting}
                        formReadonly
                        onSave={handleAutosave}
                      />
                    </>
                  )}

                  {(role === RoleEnum.Admin ||
                  role === RoleEnum.CompetitionCoordinator) && 
                    <Button
                      className={classes.sendDocumentsButton}
                      loading={savingAuthorAttachments}
                      disabled={savingAuthorAttachments || !hasAttachmentsToUpdate(authorAttachmentsToUpdate)}
                      onClick={() => handleUpdateAttachments(authorAttachmentsToUpdate, 'author')}
                    >
                      {t("save")}
                    </Button>}
                </FormContent>
              )}
            </Form>
          </BoxSection>

          {(reviewerAttachments.length || (role === RoleEnum.Reviewer && canReviewSubmission)) && (
            <BoxSection
              className={classes.section}
              title={t("reviewerDocumentation")}
            >
              {Boolean(reviewerAttachments.length) && (
                <SubmissionDocumentation
                  shouldValidate={shouldValidateFiles}
                  attachments={attachmentsToUpload}
                  submissionAttachments={reviewerAttachments}
                  submissionStatus={submission.status}
                  submissionId={submission.id}
                  competetionIsActive={submission.competition.isActive}
                  docsAssignedTo={RoleEnum.Reviewer}
                  attachmentsToUpdate={reviewerAttachmentsToUpdate}
                  onFileChange={handleFileChange}
                  onAttachmentRemoved={onChanged}
                  onAdminFileChange={handleReviewerFileChange}
                />
              )}

              {role === RoleEnum.Reviewer && canReviewSubmission && submission.status !== SubmissionStatusEnum.Won && (
                <div className={classes.mark}>
                  <BoxHeader title={t("reviewSubmission")} />
                  <ReviewSubmission
                    competitionType={submission.competition.type}
                    shouldValidate={shouldValidateMark}
                    serverError={markServerError}
                    loading={markLoading}
                    mark={mark}
                    onChange={handleMarkChange}
                  />
                  <Button
                    className={classes.sendDocumentsButton}
                    loading={submittingReview}
                    disabled={submittingReview || (!hasAttachmentsToSubmit(RoleEnum.Reviewer) && !isMarkToSubmit())}
                    onClick={handleReviewerSubmit}
                  >
                    {t("sendDocuments")}
                  </Button>       
                  <div className={classes.sendDocumentsButton}>
                    {t("requiredInfo")}
                  </div> 
                </div>
              )}

              {role === RoleEnum.Reviewer && (
                <Autosave
                  initialAttachments={initialAttachments}
                  currentAttachments={attachmentsToUpload}
                  initialMark={initialMark?.mark}
                  currentMark={mark.mark}
                  submitting={submittingReview}
                  onSave={handleAutosave}
                />
              )}

              {(role === RoleEnum.Admin ||
              role === RoleEnum.CompetitionCoordinator) && (
                <Button
                  className={classes.sendDocumentsButton}
                  loading={savingReviewerAttachments}
                  disabled={savingReviewerAttachments || !hasAttachmentsToUpdate(reviewerAttachmentsToUpdate)}
                  onClick={() => handleUpdateAttachments(reviewerAttachmentsToUpdate, 'reviewer')}
                >
                  {t("save")}
                </Button>
              )}
            </BoxSection>
          )}
      </div>
      <SubmissionStagesDocumentation
        submissionId={submission.id}
        selectedStage={activeStage}
        onChanged={onChanged}
        onStageAttachmentsChanged={onStageAttachmentsChanged}
      />

      {(role === RoleEnum.Admin ||
      role === RoleEnum.CompetitionCoordinator ||
      role === RoleEnum.ScientificCouncilMember ||
      role === RoleEnum.CompetitionCommitteeMember ||
      role === RoleEnum.CompetitionJuryMember) && (
        <BoxSection title={t("discussion")}>
          <Discussion />
        </BoxSection>
      )}

      {(role === RoleEnum.Admin ||
      role === RoleEnum.CompetitionCoordinator) && (
        <BoxSection title={t("notes")}>
          <Notes />
        </BoxSection>
      )}

      <ConfirmationDialog open={isConfirmDialogOpen} onClose={handleCloseConfirmationDialog} dialogTitle={t('confirmChangeDocumentForAllReviews')} />
    </div>
  );
};

export const SubmissionDetails = withStyles(styles)(SubmissionDetailsComponent);