import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Tabs, Tab, Theme, Badge } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import { useTranslator } from '../i18n/useTranslator';
import { CompetitionRankingList } from './CompetitionRankingList';
import { GrantImplementationRankingList } from './GrantImplementationRankingList';
import { Message } from './Message';
import { Box } from './Box';
import { BoxLoader } from './BoxLoader';
import { SubmissionService } from '../services/Submissions.service';
import { ErrorService } from '../services/Error.service';
import { SubmissionsCount } from '../shared/types/SubmissionsCount';

const styles = ({ custom }: Theme) =>
  createStyles({
    tabs: {
      marginBottom: 20,
      overflow: 'visible',
      '& > div.MuiTabs-scroller': {
        overflow: 'visible !important'
      }
    },
    tab: {
      borderRadius: `${custom.radius.r_0}px ${custom.radius.r_0}px 0 0`,
      overflow: 'visible'
    },
    badgeText: {
      padding: 5
    }
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const CompetitionRankingListWithTabsComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [submissions, setSubmissions] = useState<Array<any>>([]);
  const [submissionsCount, setSubmissionsCount] = useState<SubmissionsCount>();

  const { t } = useTranslator();

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    setActiveTab(value);
  };

  const fetchSubmissions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        if (activeTab === 0) {
          const {
            data: { result },
          } = await SubmissionService.getWon(match.params.id);       
          
          setSubmissions(result);
        } else if (activeTab === 1) {
          const {
            data: { result },
          } = await SubmissionService.getInReview(match.params.id);          

          setSubmissions(result);
        } else if (activeTab === 2) {
          const {
            data: { result },
          } = await SubmissionService.getRejected(match.params.id);          

          setSubmissions(result);
        } else if (activeTab === 3) {
          const {
            data: { result },
          } = await SubmissionService.getDrafts(match.params.id);          

          setSubmissions(result);
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  const fetchSubmissionsCount = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result }
        } = await SubmissionService.getSubmissionsCount(match.params.id);

        setSubmissionsCount(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchSubmissions, [activeTab]);
  useEffect(fetchSubmissionsCount, []);

  return (
    <div>
      <Tabs className={classes.tabs} indicatorColor='primary' value={activeTab} onChange={handleTabChange}>
        <Tab 
          className={classes.tab}
          disabled={loading}
          label={
            <Badge badgeContent={submissionsCount?.won} color={"primary"}>
              <span className={classes.badgeText}>
                {t('grantInplementation')}
              </span>
            </Badge>
          }
        />
        <Tab
          className={classes.tab}
          disabled={loading}
          label={
            <Badge badgeContent={submissionsCount?.inReview} color={"primary"}>
              <span className={classes.badgeText}>
                {t('markInProgressSubmissions')}
              </span>
            </Badge>
          }
        />
        <Tab
          className={classes.tab}
          disabled={loading}
          label={
            <Badge badgeContent={submissionsCount?.rejected} color={"primary"}>
              <span className={classes.badgeText}>
                {t('rejectedSubmissions')}
              </span>
            </Badge>
          }
        />
        <Tab
          className={classes.tab}
          disabled={loading}
          label={
            <Badge badgeContent={submissionsCount?.draft} color={"primary"}>
              <span className={classes.badgeText}>
                {t('toAcceptSubmissions')}
              </span>
            </Badge>
          }
        />
      </Tabs>
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <div>
        <Box>
          {loading ? <BoxLoader /> : submissions.length ? 
            <div>
              {activeTab === 0 ? <GrantImplementationRankingList rows={submissions} /> : null}
              {activeTab > 0 ? <CompetitionRankingList rows={submissions} /> : null}
            </div> 
            : <span>{t('none')}</span> }
         </Box>
        </div>
      )}
    </div>
  );
};

export const CompetitionRankingListWithTabs = withStyles(styles)(withRouter(CompetitionRankingListWithTabsComponent));
