import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import partition from 'lodash/partition';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { ErrorService } from '../../../services/Error.service';
import { Message } from '../../Message';
import { BoxLoader } from '../../BoxLoader';
import { SubmissionDetails } from '../../SubmissionDetails';
import { SubmissionService } from '../../../services/Submissions.service';
import { SubmissionFull } from '../../../shared/types/SubmissionFull';
import { SubmissionAttachment } from '../../../shared/types/SubmissionAttachment';
import { ScreenToolbar } from '../../ScreenToolbar';
import { Box } from '../../Box';
import { BoxHeader } from '../../BoxHeader';
import { ReviewerAutocomplete } from '../../ReviewerAutocomplete';
import { AcceptSubmission } from '../../AcceptSubmission';
import { SubmissionStatusEnum } from '../../../shared/enums/SubmissionStatusEnum';
import { RoleEnum } from '../../../shared/enums/RoleEnum';
import { ReviewSubmissionAdminActions } from '../../ReviewSubmissionAdminActions';
import { SubmissionStages } from '../../SubmissionStages';
import { User } from '../../../shared/types/User';
import { SubmissionStage } from '../../../shared/types/SubmissionStage';
import { SuggestedReviewers } from '../../SuggestedReviewers';
import { RestoreSubmission } from '../../RestoreSubmission';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { CompetitionCategoryEnum } from '../../../shared/enums/CompetitionCategoryEnum';
import { AdditionalDocumentation } from '../../AdditionalDocumentation';

const styles = () =>
  createStyles({
    box: {
      marginBottom: 20,
    },
    suggestedReviewers: {
      fontSize: '1rem',
      padding: 0
    },
    suggestedReviewersHeader: {
      height: '10px'
    },
  });

type Props = WithStyles<typeof styles> & RouteComponentProps<{ id: string }>;

const AdminSubmissionDetailsScreenComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState<SubmissionFull | null>(null);
  const [authorAttachments, setAuthorAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [reviewerAttachments, setReviewerAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [selectedStage, setSelectedStage] = useState<SubmissionStage>();
  const [forceRefreshReviewers, setForceRefreshReviewers] = useState<number>(0);
  const [reviewerStageAttachments, setReviewerStageAttachments] = useState<Array<SubmissionAttachment>>([]);

  const { t } = useTranslator();

  const { role } = useSelector((state: RootState) => state.user);
  const path = role === RoleEnum.Admin ? 'admin' 
    : role === RoleEnum.CompetitionCoordinator ? 'competition-coordinator' 
    : '';

  const fetchSubmission = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const {
          data: { result },
        } = await SubmissionService.getOneFull(match.params.id);
        setSubmission(result);

        try {
          const {
            data: { result: submissionAttachments },
          } = await SubmissionService.getAllAttachments(result.submission.id);

          const [reviewer, author] = partition(submissionAttachments, { role: RoleEnum.Reviewer });

          setReviewerAttachments(reviewer);
          setAuthorAttachments(author);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      setLoading(false);
    })();
  };

  const handleSave = async (users: Array<User>) => {
    if  (submission?.submission.id) {
      for (const reviewer of users) {
        try {
          await SubmissionService.addReviewer(submission?.submission.id, reviewer.userId);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      }
      
      fetchSubmission();
    }
  };

  const acceptSuggestedReviewerEventHandler = async (userId: string) => {
    if  (submission?.submission.id) {
      setForceRefreshReviewers(forceRefreshReviewers + 1);
    }
  };

  const onUpdate = async (selectedStage: SubmissionStage) => {
    setSelectedStage(selectedStage);
  };

  const init = () => {
    fetchSubmission();
  };
  useEffect(init, []);

  return (
    <div>
      <SectionHeader title={t("submissionDetails")} />
      {submission ? (
        <ScreenToolbar
          backLink={{
            to: `/${path}/competitions/${submission.submission.competition.id}/ranking-list`,
            title: t("backToRankingList"),
          }}
        />
      ) : null}
      {serverError ? (
        <Message type={"ERROR"}>{t(serverError)}</Message>
      ) : loading ? (
        <BoxLoader />
      ) : submission && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={
              submission.submission.status !== SubmissionStatusEnum.Closed
                ? 8
                : 12
            }
          >
            <Box>
              <SubmissionDetails
                submission={submission.submission}
                authorAttachments={authorAttachments}
                reviewerAttachments={reviewerAttachments}
                marks={submission.assessments}
                summedMarks={submission.summedMarks}
                onChanged={fetchSubmission}
                activeStage={selectedStage}
                onStageAttachmentsChanged={setReviewerStageAttachments}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            {(submission.submission.status === SubmissionStatusEnum.Draft ||
            submission.submission.status === SubmissionStatusEnum.Review) && (
              <Box className={classes.box}>
                <BoxHeader title={t("adminActions")} />
                {submission.submission.status ===
                SubmissionStatusEnum.Draft ? (
                  <AcceptSubmission
                    submissionId={submission.submission.id}
                    onUpdated={fetchSubmission}
                  />
                ) : null}
                {submission.submission.status ===
                SubmissionStatusEnum.Review ? (
                  <ReviewSubmissionAdminActions
                    submissionId={submission.submission.id}
                    onUpdated={fetchSubmission}
                  />
                ) : null}
              </Box>
            )}

            {(submission.submission.status === SubmissionStatusEnum.New ||
            submission.submission.status === SubmissionStatusEnum.Review) && (
              <>
                <Box className={classes.box}>
                  <BoxHeader title={t("reviewers")} />
                  <ReviewerAutocomplete
                    forceRefreshReviewersList={forceRefreshReviewers}
                    submissionId={submission.submission.id}
                    reviewerAttachments={reviewerAttachments}
                    onSaveHandler={handleSave}
                  />
                </Box>
                <Box className={classes.box}>
                  <SuggestedReviewers
                    addSuggestedReviewerHandler={
                      SubmissionService.addSuggestedReviewer
                    }
                    acceptSuggestedReviewerHandler={
                      SubmissionService.acceptSuggestedReviewer
                    }
                    removeSuggestedReviewerHandler={
                      SubmissionService.removeSuggestedReviewer
                    }
                    fetchAssignedSuggestedReviewersHandler={
                      SubmissionService.getSuggestedReviewers
                    }
                    fetchAvailableSuggestedReviewersHandler={
                      SubmissionService.getAvailableSuggestedReviewers
                    }
                    reviewerAcceptedEvent={
                      acceptSuggestedReviewerEventHandler
                    }
                    entityId={submission.submission.id}
                    blockHeaderText={t('suggestedReviewers')}
                    listHeaderText={t('suggestedReviewers')}
                    suggestedReviewersVisibilityOpt={{
                      showSuggestedReviewersList: true,
                      allowAcceptSuggestedReviewers: true,
                      allowSelectSuggestedReviewers: false,
                    }}
                  />
                </Box>
              </>
            )}

            {submission.submission.status === SubmissionStatusEnum.Won && (
              <div>
                <Box className={classes.box}>
                  <SubmissionStages
                    visibilityOptions={{
                      stagesVisibility: {
                        showStagesList: true,
                        allowStagesEdition: true,
                        allowStagesSwitch: true,
                      },
                      reviewersVisibility: {
                        showReviewersList: true,
                        allowReviewersEdition: true,
                      },
                      suggestedReviewersVisibility: {
                        showSuggestedReviewersList: true,
                        allowAcceptSuggestedReviewers: true,
                        allowSelectSuggestedReviewers: false,
                      },
                    }}
                    submissionId={submission.submission.id}
                    reviewerStageAttachments={reviewerStageAttachments}
                    onSelectedValueChanged={onUpdate}
                  />
                </Box>
              </div>
            )}

            {submission.submission.status === SubmissionStatusEnum.Rejected && (
              <Box className={classes.box}>
                <BoxHeader title={t("adminActions")} />
                <RestoreSubmission
                  submissionId={submission.submission.id}
                  onUpdated={fetchSubmission}
                />
              </Box>
            )}

            {submission.submission.competition.category === CompetitionCategoryEnum.Person && (
              <AdditionalDocumentation
                submission={submission.submission}
                onChanged={fetchSubmission}
              />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export const AdminSubmissionDetailsScreen = withStyles(styles)(AdminSubmissionDetailsScreenComponent);
