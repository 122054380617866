import { AttachmentTemplate } from "../types/AttachmentTemplate";
import { DuplicateAttachment } from "../types/DuplicateAttachment";
import { SubmissionAttachment } from "../types/SubmissionAttachment";

export const getDuplicateSubmissionAttachments = (
  submissionAttachments: SubmissionAttachment[],
  attachmentsToUpload: Map<string, File | null>
): DuplicateAttachment[] => {
  if (!submissionAttachments) {
    return [];
  }

  const attachments = Array.from(attachmentsToUpload).map(([templateId, file]) => ({
    templateId: templateId,
    fileName: file?.name
  }))
  .filter(attachment => Boolean(attachment.fileName));

  const allAttachments = submissionAttachments.map(attachment => ({
    templateId: attachment.templateId,
    templateName: attachment.templateName,
    fileName: attachment.attachments[0]?.fileName
  }))
  .filter(attachment => Boolean(attachment.fileName));

  allAttachments.push(...attachments
    .map(attachment => {
      const templateName = submissionAttachments.find(attach => attach.templateId === attachment.templateId)?.templateName;
      
      return {
        templateId: attachment.templateId,
        templateName: templateName ?? '',
        fileName: attachment.fileName!
      };
    })
    .filter(attachment => Boolean(attachment.templateName))
  );

  var map = new Map();
  allAttachments.forEach(attachment => 
    map.set(attachment.fileName, (map.get(attachment.fileName) || 0) + 1)
  );
  const duplicates = allAttachments
    .filter(attachment => map.get(attachment.fileName) > 1)
    .reduce((group: DuplicateAttachment[], attachment) => {
      const attach = group.find(a => a.fileName === attachment.fileName);
      if (attach) {
        attach.templateNames.push(attachment.templateName);
      }
      else {
        group.push({
          fileName: attachment.fileName,
          templateNames: [attachment.templateName]
        });
      }
      return group;
    }, Object.create([]));
  duplicates.forEach(duplicate => {
    duplicate.templateNames.sort((a, b) => {
      const indexOfA = submissionAttachments.findIndex(attach => attach.templateName === a);
      const indexOfB = submissionAttachments.findIndex(attach => attach.templateName === b);
      return indexOfA - indexOfB;
    });
  });

  return duplicates;
};

export const getDuplicateAttachments = (
  attachmentTemplates: Array<AttachmentTemplate>,
  attachmentsToUpload: Map<string, File | null>
): DuplicateAttachment[] => {
  if (!attachmentTemplates) {
    return [];
  }

  const attachments = Array.from(attachmentsToUpload).map(([templateId, file]) => ({
    templateId: templateId,
    fileName: file?.name
  }))
  .filter(attachment => Boolean(attachment.fileName));

  const allAttachments = attachments
    .map(attachment => {
      const templateName = attachmentTemplates.find(template => template.id === attachment.templateId)?.name;
      
      return {
        templateId: attachment.templateId,
        templateName: templateName ?? '',
        fileName: attachment.fileName!
      };
    })
    .filter(attachment => Boolean(attachment.templateName));

  var map = new Map();
  allAttachments.forEach(attachment => 
    map.set(attachment.fileName, (map.get(attachment.fileName) || 0) + 1)
  );
  const duplicates = allAttachments
    .filter(attachment => map.get(attachment.fileName) > 1)
    .reduce((group: DuplicateAttachment[], attachment) => {
      const attach = group.find(a => a.fileName === attachment.fileName);
      if (attach) {
        attach.templateNames.push(attachment.templateName);
      }
      else {
        group.push({
          fileName: attachment.fileName,
          templateNames: [attachment.templateName]
        });
      }
      return group;
    }, Object.create([]));
  duplicates.forEach(duplicate => {
    duplicate.templateNames.sort((a, b) => {
      const indexOfA = attachmentTemplates.findIndex(template => template.name === a);
      const indexOfB = attachmentTemplates.findIndex(template => template.name === b);
      return indexOfA - indexOfB;
    });
  });

  return duplicates;
};
