import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { AuthorRoute } from '../../routes/AuthorRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { AuthorSubmissionsScreen } from '../Author/AuthorSubmissionsScreen';
import { AuthorSubmissionDetailsScreen } from '../Author/AuthorSubmissionDetailsScreen';
import { AuthorCompetitionsScreen } from '../Author/AuthorCompetitionsScreen';
import { AuthorSubmissionScreen } from '../Author/AuthorSubmissionScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const AuthorScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <AuthorRoute exact path={`${match.path}/submissions`} component={AuthorSubmissionsScreen} />
        <AuthorRoute exact path={`${match.path}/submissions/:id`} component={AuthorSubmissionDetailsScreen} />
        <AuthorRoute exact path={`${match.path}/competitions`} component={AuthorCompetitionsScreen} />
        <AuthorRoute exact path={`${match.path}/competitions/:id/submit`} component={AuthorSubmissionScreen} />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const AuthorScreens = withStyles(styles)(AuthorScreensComponent);
