import React from 'react';
import { createStyles, WithStyles, withStyles, MenuItem } from '@material-ui/core';
import MaterialSelect, { SelectProps as MaterialSelectProps } from '@material-ui/core/Select';

import { FormControl } from '../FormControl';
import { InputLabel } from '../InputLabel';

const styles = () => createStyles({});

type Props = MaterialSelectProps &
  WithStyles<typeof styles> & {
    name: string;
    label: string;
    items: Array<{
      label: string;
      value: string;
    }>;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
  };

const SelectComponent: React.FunctionComponent<Props> = ({ label, fullWidth, size, name, items, ...restProps }) => {
  return (
    <FormControl size={size} fullWidth={fullWidth} variant={'outlined'}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <MaterialSelect labelId={`${name}-label`} label={label} {...restProps}>
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <em>{item.label}</em>
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};

export const Select = withStyles(styles)(SelectComponent);
