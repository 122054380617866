import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      flex: 1,
      padding: '10px 0',
      color: custom.gray.g_2,
    },
  });

type Props = WithStyles<typeof styles>;

const ListHeaderItemComponent: React.FunctionComponent<Props> = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>;
};

export const ListHeaderItem = withStyles(styles)(ListHeaderItemComponent);
