import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import moment from 'moment';

import { RoleEnum } from '../shared/enums/RoleEnum';
import { useTranslator } from '../i18n/useTranslator';
import { DateService } from '../services/Date.service';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {},
    comment: {
      display: 'inline-flex',
      lineHeight: '24px',
      padding: 10,
      borderRadius: custom.radius.r_0,
      backgroundColor: custom.gray.g_0,
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 22,
        width: 0,
        height: 0,
        border: '7px solid transparent',
        borderTopColor: custom.gray.g_0,
        borderBottom: 0,
        borderLeft: 0,
        marginLeft: -11.5,
        marginBottom: -7,
      },
    },
    author: {
      fontWeight: 600,
      textTransform: 'uppercase',
      marginRight: 5,
    },
    date: {
      color: custom.gray.g_4,
      marginLeft: 5,
    },
    footer: {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      marginLeft: 10,
    },
  });

type Props = WithStyles<typeof styles> & {
  createdAt: string;
  senderRole: RoleEnum;
  senderFullName: string;
  value: string;
  className?: string;
};

const DiscussionTileComponent: React.FunctionComponent<Props> = ({ classes, className, createdAt, senderRole, senderFullName, value }) => {
  const { t } = useTranslator();

  return (
    <div className={className}>
      <div className={classes.comment}>{value}</div>
      <div className={classes.footer}>
        <div className={classes.author}>{senderFullName || ''} ({t(senderRole)})</div> ·
        <div className={classes.date}>{moment(createdAt).format(DateService.DATE_FORMAT)}</div>
      </div>
    </div>
  );
};

export const DiscussionTile = withStyles(styles)(DiscussionTileComponent);
