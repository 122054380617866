export enum SubmissionStatusEnum {
  Invalid = 0,
  Draft = 1,
  New = 2,
  Review = 3,
  Rejected = 4,
  Won = 5,
  Closed = 6,
  Autosaved = 7
}
