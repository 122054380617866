import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Box } from '../../Box';
import { RouteComponentProps, withRouter } from 'react-router';
import { List } from '../../List';
import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { ErrorService } from '../../../services/Error.service';
import { Message } from '@material-ui/icons';
import { Submission } from '../../../shared/types/Submission';
import { UserService } from '../../../services/User.service';
import { BoxLoader } from '../../BoxLoader';
import { Link } from '../../Link';
import { ScreenToolbar } from '../../ScreenToolbar';

const styles = () =>createStyles({});

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const AdminUserCompetitionListScreenComponent: React.FunctionComponent<Props> = ({ match }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(true);
  const [submissions, setSubmissions] = useState<Array<Submission>>([]);
  const [title, setTitle] = useState<string>('');

  const { t } = useTranslator();

  const fetchUser = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const { data: { result } } = await UserService.getOne(match.params.id);
        
        switch (result?.role) {
          case 'Author':
            setTitle(`${t('authorsCompetitions')} ${result.firstName} ${result.lastName}`)
            break;
          case 'Reviewer':
            setTitle(`${t('reviewersCompetitions')} ${result.firstName} ${result.lastName}`)
            break;
          default:
            setTitle(`${t('usersCompetitions')} ${result?.firstName} ${result?.lastName}`)
            break;
        }

      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  const fetchSubmissions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const { data: { result } } = await UserService.getSubmissionsByUser(match.params.id);
        setSubmissions(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchSubmissions, []);
  useEffect(fetchUser, []);

  const mapRowsToListRows = () => 

      submissions.map((submission) => ({
        id: submission.id,
        items: [
          {
            key: 'competitionName',
            content: <Link to={`/admin/competitions/${submission.competition.id}`}>{submission.competition.name}</Link>,
          },
          {
            key: 'type',
            content: t(`competitionName${submission.competition.type}`),
          },
          {
            key: 'projectName',
            content: <Link to={`/admin/submissions/${submission.id}`}>{t(submission.project.title)}</Link>,
          },
          {
            key: 'unitInfo',
            content: t(submission.project.scientificFacility),
          },
          {
            key: 'estimatedFinishTime',
            content: submission.project.duration,
          },
          {
            key: 'estimatedFunds',
            content: submission.project.cost,
          },
          {
            key: 'reviewers',
            content: submission.reviewers,
          }]
      })
    );
  
    
  const listHeader = [
    {
      key: 'competitionName',
      text: t('competitionName'),
    },
    {
      key: 'competitionType',
      text: t('competitionType'),
    },
    {
      key: 'projectName',
      text: t('projectName'),
    },
    {
      key: 'unitInfo',
      text: t('unitInfo'),
    },
    {
      key: 'estimatedFinishTime',
      text: t('estimatedFinishTime'),
    },
    {
      key: 'estimatedFunds',
      text: t('estimatedFunds'),
    },
    {
      key: 'reviewers',
      text: t('reviewers'),
    }
  ];

  return (
    <div>
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        loading ? <BoxLoader /> :
            <div>
              <SectionHeader title={title} /> 
              <ScreenToolbar backLink={{ to: `/admin/users/`, title: t('backToUserList') }}/>
              <Box>
                {submissions.length === 0 ? (t('noCompetitionsFound')) : 
                  ( <List headerCells={listHeader} rows={mapRowsToListRows()} />)}
              </Box>
            </div>
      )}
    </div>
  );
};

export const AdminUserCompetitionListScreen = withStyles(styles)(withRouter(AdminUserCompetitionListScreenComponent));
