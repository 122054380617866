import React, { useState } from 'react';
import { createStyles, WithStyles, Dialog, DialogTitle, DialogContent, FormControlLabel, DialogActions, Checkbox, withStyles } from '@material-ui/core';

import { UserService } from '../services/User.service';
import { ErrorService } from '../services/Error.service';
import { useTranslator } from '../i18n/useTranslator';
import { RodoMessage } from './RodoMessage';
import { Button } from './Button';
import { Message } from './Message';
import BackgroundImage from '../assets/bg.jpg';

const styles = () => createStyles({
  dialogBackground: {
    backgroundSize: '100% 100%',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat'
  }
});

type Props = WithStyles<typeof styles> & {
  acceptedRODO: boolean;
};

const RODOAcceptanceDialogComponent: React.FunctionComponent<Props> = ({classes, acceptedRODO}) => {
  const [serverError, setServerError] = useState('');
  const [RODOChecked, setRODOChecked] = useState(false);

  const { t } = useTranslator();

  const RODOSubmit = () => {
    (async () => {
      try {
        await UserService.setAcceptedRODO(RODOChecked);

        window.location.reload();
      }
      catch (error) {
        setServerError(ErrorService.parseError(error));
      }
    })();
  };

  return (
  <Dialog open={!acceptedRODO} className={classes.dialogBackground}> 
    <DialogTitle>{t('iAcceptTermsTitle')}</DialogTitle> 
    <DialogContent>
      <RodoMessage />
      <FormControlLabel control={<Checkbox name={'agreement'}  
                        onChange={(e) => {setRODOChecked(e.target.checked)}}/>}
                        label={t('iAcceptTerms')} />
      {serverError ? (<Message type={'ERROR'}>{t(serverError)}</Message>) : null }
    </DialogContent>
    <DialogActions>
      <Button type={'submit'} disabled={!RODOChecked} onClick={RODOSubmit}>{t('save')}</Button>
    </DialogActions>
  </Dialog>);
}

export const RODOAcceptanceDialog = withStyles(styles)(RODOAcceptanceDialogComponent);