import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { UserForm } from '../../forms/UserForm';
import { Box } from '../../Box';
import { UserService } from '../../../services/User.service';
import { User } from '../../../shared/types/User';
import { BoxHeader } from '../../BoxHeader';
import { Button } from '../../Button';
import { ErrorService } from '../../../services/Error.service';
import { FormError } from '../../FormError';
import { FormSuccess } from '../../FormSuccess';

const styles = () =>
  createStyles({
    resetPasswordError: {
      marginTop: 10,
      marginBottom: 0,
    },
    resetPasswordSuccess: {
      marginTop: 10,
      marginBottom: 0,
    },
    button: {
      alignSelf: 'flex-end',
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const AdminUserDetailsScreenComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [user, setUser] = useState<User>();
  const [resetPasswordError, setResetPasswordError] = useState('');
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState('');

  const { t } = useTranslator();

  const fetchUser = () => {
    (async () => {
      const {
        data: { result },
      } = await UserService.getOne(match.params.id);

      setUser(result);
    })();
  };

  const resetUserPassword = async () => {
    setResetPasswordError('');
    setResetPasswordSuccess('');
    setResetPasswordLoading(true);

    try {
      if (!user || user.userId === undefined) {
        throw new Error('User ID not passed');
      }

      await UserService.resetPassword(user.userId);

      setResetPasswordSuccess('passwordResetSuccess');
    } catch (error) {
      setResetPasswordError(ErrorService.parseError(error));
    }

    setResetPasswordLoading(false);
  };

  useEffect(fetchUser, []);

  return (
    <div>
      <SectionHeader title={t('userDetails')} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box>
            <BoxHeader title={t('data')} />
            {user ? <UserForm initValues={user} userId={user.userId} /> : null}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <BoxHeader title={t('adminActions')} />
            <Button className={classes.button} loading={resetPasswordLoading} onClick={resetUserPassword}>
              {t('resetUserPassword')}
            </Button>
            <FormError className={classes.resetPasswordError} show={Boolean(resetPasswordError)} error={t(resetPasswordError)} />
            <FormSuccess className={classes.resetPasswordSuccess} show={Boolean(resetPasswordSuccess)} message={t(resetPasswordSuccess)} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export const AdminUserDetailsScreen = withStyles(styles)(withRouter(AdminUserDetailsScreenComponent));
