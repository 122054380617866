import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import Color from 'color';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      fontSize: 10,
      textTransform: 'uppercase',
      letterSpacing: 0.4,
      padding: '2px 5px',
      borderRadius: custom.radius.r_0,
    },
  });

type Props = WithStyles<typeof styles> & {
  color?: string;
  className?: string;
};

const BadgeComponent: React.FunctionComponent<Props> = ({ children, classes, className, color }) => {
  return (
    <div className={classNames(classes.root, className)} style={{ backgroundColor: Color(color).alpha(0.2).rgb().string(), color }}>
      {children}
    </div>
  );
};

export const Badge = withStyles(styles)(BadgeComponent);
