import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import { Form } from '../Form';
import { FieldTextField } from '../form-fields/FieldTextField';
import { FieldCheckbox } from '../form-fields/FieldCheckbox';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { email, reviewerSpecialization, name, surname, scienceUnit, phone, agreement, password } from '../../shared/validators';
import { UserService } from '../../services/User.service';
import { RegistrationData } from '../../shared/types/RegistrationData';
import { FormError } from '../FormError';
import { ErrorService } from '../../services/Error.service';
import { RodoMessage } from '../RodoMessage';

const styles = () => createStyles({
  checkboxField: {
    marginTop: 5
  }
});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

type FormValues = RegistrationData;

const RegistrationFormComponent: React.FunctionComponent<Props> = ({ classes, history }) => {
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      firstName: name(t),
      lastName: surname(t),
      scientificFacility: scienceUnit(t),
      phoneNumber: phone(t),
      email: email(t),
      password: password(t),
      specialty: reviewerSpecialization(t),
      acceptedRODO: agreement(t),
    });

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    scientificFacility: '',
    email: '',
    password: '',
    agreement: false,
    specialty: '',
    acceptedRODO: false
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setServerError('');
    setSubmitting(true);

    try {
      await UserService.register(values);

      history.push('/registration/success');
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }

    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <FormContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FieldTextField name={'firstName'} label={t('name')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FieldTextField name={'lastName'} label={t('surname')} fullWidth />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FieldTextField name={'email'} label={t('email')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FieldTextField name={'phoneNumber'} label={t('phone')} fullWidth />
            </Grid>
          </Grid>

          <FieldTextField name={'scientificFacility'} label={t('scienceUnit')} />
          <FieldTextField name={'academicTitle'} label={t('academicTitle')} />

          <FieldTextField name={'password'} type={'password'} label={t('password')} />
          <RodoMessage />
          <FieldCheckbox className={classes.checkboxField} name={'acceptedRODO'} label={t('iAcceptTerms')} />          

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <Button type={'submit'} loading={isSubmitting}>
            {t('register')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const RegistrationForm = withStyles(styles)(withRouter(RegistrationFormComponent));
