import { ApiService } from './Api.service';
import { RegistrationData } from '../shared/types/RegistrationData';
import { LoginData } from '../shared/types/LoginData';
import { ApiResponse } from '../shared/types/ApiResponse';
import { User } from '../shared/types/User';
import { UserFormData } from '../shared/types/UserFormData';
import { ChangePasswordFormData } from '../shared/types/ChangePasswordFormData';
import { Submission } from '../shared/types/Submission';
import { ResetPasswordFormData } from '../shared/types/ResetPasswordFormData';
import { SetNewPasswordFormData } from '../shared/types/SetNewPasswordFormData';

export const UserService = {
  resource: 'User',
  register: (data: RegistrationData) => {
    return ApiService.post(`${UserService.resource}/register`, data);
  },
  confirmEmail: (emailConfirmationCode: string, userId: string) => {
    return ApiService.post(`${UserService.resource}/email/confirm`, {
      emailConfirmationCode,
      userId
    });
  },
  login: (data: LoginData) => {
    return ApiService.post(`${UserService.resource}/sign-in`, data);
  },
  add: (data: UserFormData) => {
    return ApiService.post(`${UserService.resource}/generate`, data, { withCredentials: true });
  },
  getMe: () => {
    return ApiService.get<ApiResponse<User>>(`${UserService.resource}/me`, { withCredentials: true });
  },
  getSubmissionsByUser: (userId: User['userId']) => {
    return ApiService.get<ApiResponse<Array<Submission>>>(`${UserService.resource}/${userId}/submissions`, { withCredentials: true });
  },
  getAll: () => {
    return ApiService.get<ApiResponse<Array<User>>>(UserService.resource, { withCredentials: true });
  },
  getUsersByFilter: (role: string, competition: string, searchPhrase: string) => {
    return ApiService.get<ApiResponse<Array<User>>>(`${UserService.resource}`,
    { withCredentials: true, params: normalizeParameters({ role, competition, searchPhrase }) });
  },
  getOne: (userId: User['userId']) => {
    return ApiService.get<ApiResponse<User>>(`${UserService.resource}/${userId}`, { withCredentials: true });
  },
  update: (userId: User['userId'], data: UserFormData) => {
    return ApiService.put(`${UserService.resource}/${userId}`, data, { withCredentials: true });
  },
  updateUserIsActiveField: (userId: User['userId'], value: boolean) => {
    return ApiService.put(`${UserService.resource}/${userId}/is-active?value=${value}`, {}, { withCredentials: true });
  },
  updateSpecialty: (specialty: User['specialty']) => {
    return ApiService.put(`${UserService.resource}/specialty?specialty=${specialty}`, {}, { withCredentials: true });
  },
  changePassword: (userId: User['userId'], data: ChangePasswordFormData) => {
    return ApiService.put(`${UserService.resource}/${userId}/password`, data, { withCredentials: true });
  },
  resetPassword: (userId: User['userId']) => {
    return ApiService.post(`${UserService.resource}/${userId}/password/reset`, {}, { withCredentials: true });
  },
  resetPasswordByEmailToken: (data: ResetPasswordFormData) => {
    return ApiService.post(`${UserService.resource}/password/reset/email`, data);
  },
  setNewPasswordByEmailToken: (data: SetNewPasswordFormData) => {
    return ApiService.post(`${UserService.resource}/password/set`, data);
  },
  logout: () => {
    return ApiService.post(`${UserService.resource}/sign-out`, {}, { withCredentials: true });
  },
  getCompetitionCoordinators: () => {
    return ApiService.get<ApiResponse<Array<User>>>(`${UserService.resource}/competition-coordinators`, { withCredentials: true });
  },
  getReviewers: () => {
    return ApiService.get<ApiResponse<Array<User>>>(`${UserService.resource}/reviewers`, { withCredentials: true });
  },
  getReviewersGrid: () => {
    return ApiService.get<ApiResponse<Array<User>>>(`${UserService.resource}/reviewers-grid`, { withCredentials: true });
  },
  updateAcademicTitle: (title: string) => {
    return ApiService.put(`${UserService.resource}/academic-title?academicTitle=${title}`, {}, {
      withCredentials: true,
    });
  },
  setAcceptedRODO: (acceptedRODO: boolean) => {
    return ApiService.put(`${UserService.resource}/RODO?acceptedRODO=${acceptedRODO}`, {}, { withCredentials: true });
  }
};

const normalizeParameters = (params: any) => {
  for (const key of Object.keys(params)) {
    if (params[key] === "") {
      delete params[key];
    }
  }
  return params;
}
