import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import partition from 'lodash/partition';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { ErrorService } from '../../../services/Error.service';
import { Message } from '../../Message';
import { Box } from '../../Box';
import { BoxLoader } from '../../BoxLoader';
import { SubmissionDetails } from '../../SubmissionDetails';
import { SubmissionService } from '../../../services/Submissions.service';
import { SubmissionAttachment } from '../../../shared/types/SubmissionAttachment';
import { ScreenToolbar } from '../../ScreenToolbar';
import { RoleEnum } from '../../../shared/enums/RoleEnum';
import { SubmissionFull } from '../../../shared/types/SubmissionFull';
import { SubmissionInstructions } from '../../SubmissionInstructions';
import { SubmissionStages } from '../../SubmissionStages';
import { SubmissionStatusEnum } from '../../../shared/enums/SubmissionStatusEnum';
import { SubmissionStage } from '../../../shared/types/SubmissionStage';
import { SuggestedReviewers } from '../../SuggestedReviewers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { CompetitionCategoryEnum } from '../../../shared/enums/CompetitionCategoryEnum';
import { AdditionalDocumentation } from '../../AdditionalDocumentation';

const styles = () =>
  createStyles({
    box: {
      marginBottom: 20,
      marginTop: 10
    },
    suggestedReviewers: {
      fontSize: '1rem',
      padding: 0
    },
    suggestedReviewersHeader: {
      height: '10px'
    },
    heading: {
      textTransform: 'uppercase',
      fontWeight: 600,
    }
  });

type Props = WithStyles<typeof styles> & RouteComponentProps<{ id: string }>;

const SubmissionDetailsScreenComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const [submission, setSubmission] = useState<SubmissionFull | null>(null);
  const [authorAttachments, setAuthorAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [reviewerAttachments, setReviewerAttachments] = useState<Array<SubmissionAttachment>>([]);

  const [selectedStage, setSelectedStage] = useState<SubmissionStage>();

  const { t } = useTranslator();

  const { role } = useSelector((state: RootState) => state.user);
  const getRolePath = () => {
    switch (role) {
      case RoleEnum.ScientificCouncilMember:
        return 'scientific-council-member';
      case RoleEnum.CompetitionCommitteeMember:
        return 'competition-committee-member';
      case RoleEnum.CompetitionJuryMember:
        return 'competition-jury-member';
    }

    return '';
  };

  const fetchSubmission = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const {
          data: { result },
        } = await SubmissionService.getOneFull(match.params.id);

        setSubmission(result);

        try {
          const {
            data: { result: submissionAttachments },
          } = await SubmissionService.getAllAttachments(result.submission.id);

          const [reviewer, author] = partition(submissionAttachments, { role: RoleEnum.Reviewer });

          setReviewerAttachments(reviewer);
          setAuthorAttachments(author);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  const onUpdate = async (selectedStage: SubmissionStage) => {
    setSelectedStage(selectedStage);
  };

  useEffect(fetchSubmission, []);

  return (
    <div>
      <SectionHeader title={t("submissionDetails")} />
      {submission ? (
        <ScreenToolbar
          backLink={{
            to: `/${getRolePath()}/competitions/${submission.submission.competition.id}/ranking-list`,
            title: t("backToRankingList"),
          }}
        />
      ) : null}
      {serverError ? (
        <Message type={"ERROR"}>{t(serverError)}</Message>
      ) : loading ? (
        <BoxLoader />
      ) : submission ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box>
              <SubmissionDetails
                submission={submission.submission}
                authorAttachments={authorAttachments}
                reviewerAttachments={reviewerAttachments}
                marks={submission.assessments}
                summedMarks={submission.summedMarks}
                onChanged={fetchSubmission}
                activeStage={selectedStage}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              {submission.submission.status === SubmissionStatusEnum.Won ? (
                <SubmissionStages
                  visibilityOptions={{
                    stagesVisibility: {
                      showStagesList: true,
                      allowStagesEdition: false,
                      allowStagesSwitch: true,
                    },
                    reviewersVisibility: {
                      showReviewersList: true,
                      allowReviewersEdition: false,
                    },
                    suggestedReviewersVisibility: {
                      showSuggestedReviewersList: true,
                      allowAcceptSuggestedReviewers: false,
                      allowSelectSuggestedReviewers: true,
                    },
                  }}
                  submissionId={submission.submission.id}
                  reviewerStageAttachments={reviewerAttachments}
                  onSelectedValueChanged={onUpdate}
                />
              ) : (
                <SubmissionInstructions />
              )}
            </Box>

            {submission.submission.status === SubmissionStatusEnum.New ||
            submission.submission.status === SubmissionStatusEnum.Review ? (
              <Box className={classes.box}>
                <SuggestedReviewers
                  addSuggestedReviewerHandler={
                    SubmissionService.addSuggestedReviewer
                  }
                  acceptSuggestedReviewerHandler={
                    SubmissionService.acceptSuggestedReviewer
                  }
                  removeSuggestedReviewerHandler={
                    SubmissionService.removeSuggestedReviewer
                  }
                  fetchAssignedSuggestedReviewersHandler={
                    SubmissionService.getSuggestedReviewers
                  }
                  fetchAvailableSuggestedReviewersHandler={
                    SubmissionService.getAvailableSuggestedReviewers
                  }
                  entityId={submission.submission.id}
                  suggestedReviewersVisibilityOpt={{
                    showSuggestedReviewersList: true,
                    allowAcceptSuggestedReviewers: false,
                    allowSelectSuggestedReviewers: true,
                  }}
                  blockHeaderText={t('suggestReviewers')}
                  listHeaderText={t('suggestedReviewers')}
                />
              </Box>
            ) : null}
            
            {submission.submission.competition.category === CompetitionCategoryEnum.Person ? (
              <AdditionalDocumentation
                submission={submission.submission}
                onChanged={fetchSubmission}
              />
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export const SubmissionDetailsScreen = withStyles(styles)(SubmissionDetailsScreenComponent);