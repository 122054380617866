import React from 'react';
import { createStyles, WithStyles, withStyles, Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { useTranslator } from '../i18n/useTranslator';

const styles = () =>
  createStyles({});

type Props = WithStyles<typeof styles> & { 
    open: boolean,
    onClose: (confirmed: boolean) => void,
    dialogTitle: string
};

const ConfirmationDialogComponent: React.FunctionComponent<Props> = ({ classes, open, dialogTitle, onClose }) => {
    const { t } = useTranslator();
        
    return (
        <Dialog open={open} onClose={() => onClose(false)}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogActions>
                <Button onClick={() => onClose(false)} color='default'>
                    {t('no')}
                </Button>
                <Button onClick={() => onClose(true)} color='primary' autoFocus>
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const ConfirmationDialog = withStyles(styles)(ConfirmationDialogComponent);
