import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { Box } from '../../Box';
import { BoxHeader } from '../../BoxHeader';
import { SubmissionStage } from '../../../shared/types/SubmissionStage';
import { StageService } from '../../../services/Stage.service';
import { StageForm } from '../../forms/StageForm';
import sortBy from 'lodash/sortBy';
import { DocumentationEdit } from '../../DocumentationEdit';
import { AttachmentTemplate } from '../../../shared/types/AttachmentTemplate';
import { ScreenToolbar } from '../../ScreenToolbar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { RoleEnum } from '../../../shared/enums/RoleEnum';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const AdminStageDetailsScreenComponent: React.FunctionComponent<Props> = ({ match }) => {
  const [stage, setStage] = useState<SubmissionStage>();
  const [activeStage, setActiveStage] = useState<SubmissionStage>();
  const [attachmentTemplates, setAttachmentTemplates] = useState<Array<AttachmentTemplate>>([]);

  const { t } = useTranslator();

  const { role } = useSelector((state: RootState) => state.user);
  const path = role === RoleEnum.Admin ? 'admin' 
    : role === RoleEnum.CompetitionCoordinator ? 'competition-coordinator' 
    : '';

  const fetchStage = () => {
    (async () => {
      const {
        data: { result },
      } = await StageService.getStage(match.params.id);

      setStage(result);
    })();
  };

  const fetchActiveStage = () => {
    (async () => {
      if(stage) {
        const {
          data: { result },
        } = await StageService.getActiveStage(stage?.submissionId!);
  
        setActiveStage(result);
      }
    })();
  };

  const addFileServiceHandler = async (file: FormData) => {
    if (stage?.id) {
      await StageService.addTemplateFile(stage.id, file);
      await fetchAttachmentTemplates();
    }
  };

  const fetchAttachmentTemplates = () => {
    (async () => {
      if (stage?.id) {
        const { data: { result } } = await StageService.getAttachmentTemplates(stage?.id);
        setAttachmentTemplates(sortBy(result, 'role'));
      }
    })();
  };

  const deleteFileServiceHandler = async (template: AttachmentTemplate) => {
    if (stage?.id) {
        await StageService.deleteAttachmentTemplate(template.id);
        await fetchAttachmentTemplates();
    }
  };
  
  useEffect(fetchStage, []);
  useEffect(fetchActiveStage, [stage]);
  useEffect(fetchAttachmentTemplates, [stage]);

  return stage ? (
    <div>
    <SectionHeader title={t('stageDetails')} />
    <ScreenToolbar
          backLink={{ to: `/${path}/submissions/${stage?.submissionId!}`, title: t('backToSubmission') }}
        />
    <Box>
          <BoxHeader title={t('data')} />
          <StageForm submissionId={stage.submissionId}
                     activeStageName={activeStage?.name}
                     initValues={stage}
                     hideDocumentation={true}
                     stageId={stage.id} />
          <DocumentationEdit
            deleteFileServiceHandler={deleteFileServiceHandler}
            addFileServiceHandler={addFileServiceHandler}
            attachmentTemplates={attachmentTemplates}
            onNewFileAdded={fetchAttachmentTemplates}
            onFileDeleted={fetchAttachmentTemplates}
          />
     </Box>
  </div>
  ): null;
};

export const AdminStageDetailsScreen = withStyles(styles)(withRouter(AdminStageDetailsScreenComponent));
