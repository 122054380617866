import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { DashboardScreenWrapper } from '../DashboardScreenWrapper';
import { SectionHeader } from '../SectionHeader';
import { useTranslator } from '../../i18n/useTranslator';
import { PasswordChangeForm } from '../forms/PasswordChangeForm';
import { Box } from '../Box';
import { BoxHeader } from '../BoxHeader';
import { RootState } from '../../store/rootReducer';
import { RoleEnum } from '../../shared/enums/RoleEnum';
import { UpdateSpecialtyForm } from '../forms/UpdateSpecialtyForm';

const styles = () =>
  createStyles({
    box: {
      marginTop: 20,
    },
  });

type Props = WithStyles<typeof styles>;

const ProfileScreenComponent: React.FunctionComponent<Props> = ({ classes }) => {
  const { role } = useSelector((state: RootState) => state.user);
  const { t } = useTranslator();

  return (
    <DashboardScreenWrapper>
      <SectionHeader title={t('profile')} />
      <Box>
        <BoxHeader title={t('changePassword')} />
        <PasswordChangeForm />
      </Box>
      {role === RoleEnum.Reviewer ? (
        <Box className={classes.box}>
          <BoxHeader title={t('updateSpecialty')} />
          <UpdateSpecialtyForm />
        </Box>
      ) : null}
    </DashboardScreenWrapper>
  );
};

export const ProfileScreen = withStyles(styles)(ProfileScreenComponent);
