import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { RoleEnum } from '../shared/enums/RoleEnum';
import { Badge } from './Badge';
import { useTranslator } from '../i18n/useTranslator';

const styles = () =>
  createStyles({
    root: {
        display: 'flex',
        width: 'fit-content',
        fontWeight: 600
    },
});

type Props = WithStyles<typeof styles> & {
    role?: RoleEnum;
    className?: string;
};

const DocumentForAllReviewsBadgeComponent: React.FunctionComponent<Props> = ({ classes, className, role }) => {
    const { t } = useTranslator();

    return (
        <Badge className={classNames(classes.root, className)} color={'#006E00'}>
            {t('documentForAllReviews')}
        </Badge>
    );
};

export const DocumentForAllReviewsBadge = withStyles(styles)(DocumentForAllReviewsBadgeComponent);
