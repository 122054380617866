import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';

import { BoxHeader } from './BoxHeader';
import { useTranslator } from '../i18n/useTranslator';
import { Button } from './Button';
import { AttachmentTemplate } from '../shared/types/AttachmentTemplate';
import { AddNewAttachmentTemplate } from './AddNewAttachmentTemplate';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { API_URL } from '../services/Api.service';
import { RoleBadge } from './RoleBadge';
import { RoleVisibilityBadge } from './RoleVisibilityBadge';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { BillingMethodEnum } from '../shared/enums/BillingMethodEnum';
import { DocumentForAllReviewsBadge } from './DocumentForAllReviewsBadge';
import { ConfirmationDialog } from './ConfirmationDialog';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      marginTop: 20,
    },
    template: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 20,
      paddingBottom: 20,
      '&:not(:first-child)': {
        borderTop: `1px solid ${custom.gray.g_0}`,
      },
    },
    documentName: {
      fontSize: 16,
      fontWeight: 600,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
    },
    downloadTemplateButton: {
      marginBottom: 5,
    },
    attachmentTemplates: {},
    badge: {
      marginTop: 5,
    },
    asterisk: {
      'font-size': '0.66em',
      margin: '0 0 0 5px',
      position: 'absolute'
    },
    alert: {
      marginBottom: 20
    }
  });

type Props = WithStyles<typeof styles> & {
  attachmentTemplates: Array<AttachmentTemplate>;
  billingMethod?: BillingMethodEnum;
  addFileServiceHandler: (file: FormData) => Promise<void>;
  deleteFileServiceHandler: (template: AttachmentTemplate) => Promise<void>;
  onNewFileAdded: () => void;
  onFileDeleted: () => void;
};

const DocumentationEditComponent: React.FunctionComponent<Props> = ({
  classes,
  attachmentTemplates,
  billingMethod,
  onNewFileAdded,
  addFileServiceHandler,
  onFileDeleted,
  deleteFileServiceHandler
}) => {
  const [deletingTemplateId, setDeletingTemplateId] = useState('');
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<AttachmentTemplate>();
  const { t } = useTranslator();

  const { role } = useSelector((state: RootState) => state.user);

  const handleDownloadTemplate = (templateId: string) => () => {
    window.open(`${API_URL}/AttachmentSpecimen/${templateId}`);
  };

  const handleDeleteTemplateClick = (template: AttachmentTemplate) => {
    setIsConfirmDialogOpen(true);
    setTemplateToDelete(template);
  };

  const deleteAttachmentTemplate = async () => {
    if (!templateToDelete) {
      return;
    }
    setDeletingTemplateId(templateToDelete.id);
    await deleteFileServiceHandler(templateToDelete);
    onFileDeleted();
  };
  
  const handleCloseConfirmationDialog = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && await deleteAttachmentTemplate();
      setTemplateToDelete(undefined);
    })()
  };

  return (
    <div className={classes.root}>
      <BoxHeader title={t('documentation')} />
      <AddNewAttachmentTemplate
        onNewFileAdded={onNewFileAdded}
        addFileServiceHandler={addFileServiceHandler}
        billingMethod={billingMethod}
      />

      <div className={classes.attachmentTemplates}>
        {attachmentTemplates.map((template) => (
          <div className={classes.template}>
            <div className={classes.documentName}>
              <div>{template.name} <span className={classes.asterisk}>{template.isRequired ? t('documentRequiredInfo') : ''}</span></div>
              {role === RoleEnum.Author || role === RoleEnum.Reviewer ? null : (
                <RoleBadge className={classes.badge} role={template.role as RoleEnum} />
              )}
              {template.isForAllReviews &&
                <DocumentForAllReviewsBadge className={classes.badge} />}
              <RoleVisibilityBadge className={classes.badge} roles={template.visibilityRoles} />
            </div>
            <div className={classes.buttons}>
              {template.specimenFileName ? (
                <Button className={classes.downloadTemplateButton} size={'small'} onClick={handleDownloadTemplate(template.id)}>
                  {t('downloadTemplate')}
                </Button>
              ) : null}
              <Button
                loading={deletingTemplateId === template.id}
                disabled={deletingTemplateId === template.id}
                size={'small'}
                variant={'outlined'}
                onClick={() => handleDeleteTemplateClick(template)}
              >
                {t('deleteDocument')}
              </Button>
            </div>
          </div>
        ))}
      </div>

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={(confirmed) => handleCloseConfirmationDialog(confirmed)}
        dialogTitle={t('confirmAttachmentTemplateDeletion')}
      />
    </div>
  );
};

export const DocumentationEdit = withStyles(styles)(DocumentationEditComponent);
