import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ custom }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 1,
      backgroundColor: custom.gray.g_0,
      marginTop: 10,
      marginBottom: 20,
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
};

const DividerComponent: React.FunctionComponent<Props> = ({ classes, className }) => {
  return <div className={classNames(classes.root, className)} />;
};

export const Divider = withStyles(styles)(DividerComponent);
