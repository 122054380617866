import React from 'react';
import { createStyles, WithStyles, withStyles, InputLabelProps } from '@material-ui/core';
import { Field } from 'formik';

import { FormikTextField } from '../formik-controls/FormikTextField';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & {
  name: string;
  label?: string;
  type?: string;
  fullWidth?: boolean;
  InputLabelProps?: Partial<InputLabelProps>;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  size?: 'small' | 'medium';
  className?: string;
  placeholder?: string;
};

const FieldTextFieldComponent: React.FunctionComponent<Props> = ({ ...restProps }) => {
  return <Field className={'form-control'} component={FormikTextField} {...restProps} />;
};

export const FieldTextField = withStyles(styles)(FieldTextFieldComponent);
