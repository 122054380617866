import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';

import { useTranslator } from '../i18n/useTranslator';
import { Message } from './Message';
import { Box } from './Box';
import { BoxLoader } from './BoxLoader';
import { ErrorService } from '../services/Error.service';
import { CompetitionService } from '../services/Competition.service';
import { List } from './List';
import { DateService } from '../services/Date.service';
import { Competition } from '../shared/types/Competition';
import { Link } from './Link';

const styles = ({ custom }: Theme) =>
  createStyles({
    tabs: {
      marginBottom: 20,
    },
    tab: {
      borderRadius: `${custom.radius.r_0}px ${custom.radius.r_0}px 0 0`,
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }> & {
    rolePath: string;
  };

const ActiveCompetitionListComponent: React.FunctionComponent<Props> = ({ rolePath }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [competitions, setCompetitions] = useState<Array<Competition>>([]);

  const { t } = useTranslator();

  const fetchCompetitions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result },
        } = await CompetitionService.getActiveForView();

        setCompetitions(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  const listHeader = [{
    key: 'name',
    text: t('competitionName'),
  }, {
    key: 'startDate',
    text: t('startDate'),
  }, {
    key: 'endDate',
    text: t('endDate'),
  }, {
    key: 'type',
    text: t('competitionType'),
  }];

  const mapRowsToListRows = () =>
    competitions.map((competition) => ({
      id: competition.id,
      items: [{
        key: 'name',
        content: <Link to={`/${rolePath}/competitions/${competition.id}/ranking-list`}>{competition.name}</Link>,
      }, {
        key: 'startDate',
        content: moment(competition.startDate).format(DateService.DATE_FORMAT),
      }, {
        key: 'endDate',
        content: moment(competition.endDate).format(DateService.DATE_FORMAT),
      }, {
        key: 'type',
        content: t(`competitionName${competition.type}`),
      }]
    }));

  useEffect(fetchCompetitions, []);

  return (
    <div>
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Box>
          {loading ? (
            <BoxLoader />
          ) : competitions?.length ? (
            <List headerCells={listHeader} rows={mapRowsToListRows()} />
          ) : (
            <span>{t('none')}</span>
          )}
        </Box>
      )}
    </div>
  );
};

export const ActiveCompetitionList = withStyles(styles)(withRouter(ActiveCompetitionListComponent));
