import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';

const styles = () => createStyles({});

export type FormikTextFieldProps = TextFieldProps & {
  className?: string;
};

type Props = FormikTextFieldProps & WithStyles<typeof styles>;

const FormikTextFieldComponent: React.FunctionComponent<Props> = ({ className, ...restProps }) => {
  return <TextField className={className} variant={'outlined'} {...restProps} />;
};

export const FormikTextField = withStyles(styles)(FormikTextFieldComponent);
