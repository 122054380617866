import React from 'react';
import { createStyles, WithStyles, withStyles, SelectProps as MaterialSelectProps } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';

import { FormikSelect } from '../formik-controls/FormikSelect';

const styles = () => createStyles({});

type Props = MaterialSelectProps &
  WithStyles<typeof styles> & {
    name: string;
    items: Array<{
      label: string;
      value: string | number | boolean;
    }>;
  };

const FieldSelectComponent: React.FunctionComponent<Props> = ({ label, name, ...restProps }) => {
  const form = useFormikContext<any>();

  return (
    <Field
      className={'form-control'}
      component={FormikSelect}
      {...restProps}
      name={name}
      fieldName={name}
      label={label}
      inputProps={{
        id: name,
      }}
      error={form.touched[name] ? form.errors[name] : ''}
    />
  );
};

export const FieldSelect = withStyles(styles)(FieldSelectComponent);
