import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import partition from 'lodash/partition';

import { useTranslator } from '../../../i18n/useTranslator';
import { SubmissionService } from '../../../services/Submissions.service';
import { SubmissionAttachment } from '../../../shared/types/SubmissionAttachment';
import { ErrorService } from '../../../services/Error.service';
import { Submission } from '../../../shared/types/Submission';
import { SectionHeader } from '../../SectionHeader';
import { Message } from '../../Message';
import { BoxLoader } from '../../BoxLoader';
import { Box } from '../../Box';
import { SubmissionDetails } from '../../SubmissionDetails';
import { RoleEnum } from '../../../shared/enums/RoleEnum';
import { ReviewerSubmissionInstructions } from '../../ReviewerSubmissionInstructions';
import { SubmissionStage } from '../../../shared/types/SubmissionStage';
import { RootState } from '../../../store/rootReducer';
import { useSelector } from 'react-redux';
import { StageService } from '../../../services/Stage.service';
import { BillingMethodEnum } from '../../../shared/enums/BillingMethodEnum';
import { Alert } from '@material-ui/lab';
import { CompetitionCategoryEnum } from '../../../shared/enums/CompetitionCategoryEnum';
import { AdditionalDocumentation } from '../../AdditionalDocumentation';

const styles = () =>
  createStyles({   
    actionsBox: {
      marginBottom: 20,
    },
    box: {
      marginBottom: 20,
    },
    alert: {
      marginTop: -10
    }
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const ReviewerSubmissionScreenComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submission, setSubmission] = useState<Submission | null>(null);
  const [authorAttachments, setAuthorAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [reviewerAttachments, setReviewerAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [canUserReviewSubmission, setCanUserReviewSubmission] = useState<boolean>();
  const [activeStage, setActiveStage] = useState<SubmissionStage>();

  const { t } = useTranslator();
  const { userId } = useSelector((state: RootState) => state.user);

  const checkIfUserCanReviewSubmission = () => {
    (async () => {
      try {

        const {
          data: { result },
        } = await SubmissionService.canReviewSubmission(match.params.id, userId);
        
        setCanUserReviewSubmission(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
    })();
  };

  const fetchSubmission = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const {
          data: { result },
        } = await SubmissionService.getOne(match.params.id);

        setSubmission(result);

        try {
          const {
            data: { result: submissionAttachments },
          } = await SubmissionService.getReviewerAttachments(result.id);

          const [reviewer, author] = partition(submissionAttachments, { role: RoleEnum.Reviewer });

          setReviewerAttachments(reviewer);
          setAuthorAttachments(author);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  const fetchStages = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const { data: { result } } = await StageService.getActiveStage(match.params.id);
        setActiveStage(result);

        } catch (error) {
        }
      setLoading(false);
    })();
  };
  useEffect(fetchStages, [])
  useEffect(fetchSubmission, []);
  useEffect(checkIfUserCanReviewSubmission, []);

  return (
    <div>
      <SectionHeader title={t("submissionDetails")} />
      {serverError ? (
        <Message type={"ERROR"}>{t(serverError)}</Message>
      ) : loading ? (
        <BoxLoader />
      ) : submission ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box>
              <SubmissionDetails
                canReviewSubmission={canUserReviewSubmission}
                submission={submission}
                authorAttachments={authorAttachments}
                reviewerAttachments={reviewerAttachments}
                onChanged={fetchSubmission}
                activeStage={activeStage}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <ReviewerSubmissionInstructions />
            {submission.competition.billingMethod === BillingMethodEnum.PerCompetition && 
              <Alert className={classes.alert} severity={"info"}>{t('billingPerCompetitionInfo')}</Alert>}
            {submission.competition.category === CompetitionCategoryEnum.Person ? (
              <AdditionalDocumentation
                submission={submission}
                onChanged={fetchSubmission}
              />
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export const ReviewerSubmissionScreen = withStyles(styles)(ReviewerSubmissionScreenComponent);
