import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useTranslator } from '../i18n/useTranslator';
import { RootState } from '../store/rootReducer';
import { SubmissionStage } from '../shared/types/SubmissionStage';
import { StageService } from '../services/Stage.service';
import { BoxSection } from './BoxSection';
import { SubmissionStageDocumentationRow } from './SubmissionStageDocumentationRow';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';

const styles = () => createStyles({
  root: {
    marginBottom: 20
  }
});

type Props = WithStyles<typeof styles> & {
  submissionId: string;
  onChanged?: () => void;
  selectedStage?: SubmissionStage;
  onStageAttachmentsChanged?: (attachments: SubmissionAttachment[]) => void;
};

const SubmissionStagesDocumentationComponent: React.FunctionComponent<Props> = ({
  selectedStage,
  classes,
  onChanged,
  onStageAttachmentsChanged
}) => {
  const { role } = useSelector((state: RootState) => state.user);
  const { userId } = useSelector((state: RootState) => state.user);

  const [canUserReviewStage, setCanUserReviewStage] = useState<boolean>(false);

  const checkIfUserCanReviewStage = () => {
    (async () => {
      try {
        if (selectedStage && role === RoleEnum.Reviewer) {
          const {
            data: { result },
          } = await StageService.canReviewStage(selectedStage?.id, userId);
          setCanUserReviewStage(result);
        }
      } catch (error) {}
    })();
  };

  useEffect(checkIfUserCanReviewStage, [selectedStage])

  const { t } = useTranslator();
  return (
    <div className={classes.root}>
      {(role === RoleEnum.Admin ||
      role === RoleEnum.CompetitionCoordinator ||
      role === RoleEnum.BoardMember ||
      role === RoleEnum.FinanceCoordinator ||
      role === RoleEnum.ScientificCouncilMember ||
      role === RoleEnum.CompetitionCommitteeMember ||
      role === RoleEnum.CompetitionJuryMember) && (
        selectedStage && (
          <div>
            <BoxSection
              title={`${t("authorDocumentation")} - ${
                selectedStage?.name
              }`}
            >
              {selectedStage && (
                <SubmissionStageDocumentationRow
                  submissionStage={selectedStage}
                  showSpecimen={false}
                  docsAssignedTo={RoleEnum.Author}
                  readonly={true}
                  onChanged={onChanged}
                />
              )}
            </BoxSection>
            <BoxSection
              title={`${t("reviewerDocumentation")} - ${
                selectedStage?.name
              }`}
            >
              {selectedStage && (
                <SubmissionStageDocumentationRow
                  submissionStage={selectedStage}
                  showSpecimen={false}
                  docsAssignedTo={RoleEnum.Reviewer}
                  readonly={true}
                  onChanged={onChanged}
                  onStageAttachmentsChanged={onStageAttachmentsChanged}
                />
              )}
            </BoxSection>
          </div>
        )
      )}

      {role === RoleEnum.Author && (
        selectedStage && (
          <BoxSection
            title={`${t("authorDocumentation")} - ${
              selectedStage?.name
            }`}
          >
            <SubmissionStageDocumentationRow
              submissionStage={selectedStage}
              docsAssignedTo={RoleEnum.Author}
              readonly={!selectedStage.isActive}
              onChanged={onChanged}
            />
          </BoxSection>
        )
      )}

      {role === RoleEnum.Reviewer && (
        selectedStage && canUserReviewStage && (
          <div>
            <BoxSection
              title={`${t("authorDocumentation")} - ${
                selectedStage?.name
              }`}
            >
              <SubmissionStageDocumentationRow
                submissionStage={selectedStage}
                docsAssignedTo={RoleEnum.Author}
                readonly={true}
              />
            </BoxSection>
            {selectedStage.isActive ? (
              <BoxSection
                title={`${t("reviewerDocumentation")} - ${
                  selectedStage?.name
                }`}
              >
                <SubmissionStageDocumentationRow
                  submissionStage={selectedStage}
                  docsAssignedTo={RoleEnum.Reviewer}
                  readonly={!canUserReviewStage}
                  onChanged={onChanged}
                />
              </BoxSection>
            ) : (
              <BoxSection
                title={`${t("reviewerDocumentation")} - ${
                  selectedStage?.name
                }`}
              >
                <SubmissionStageDocumentationRow
                  submissionStage={selectedStage}
                  docsAssignedTo={RoleEnum.Reviewer}
                  readonly={true}
                />
              </BoxSection>
            )}
          </div>
        )
      )}
    </div>
  );
};

export const SubmissionStagesDocumentation = withStyles(styles)(SubmissionStagesDocumentationComponent);
