import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import { Link } from './Link';

const styles = () =>
  createStyles({
    root: {
      marginBottom: 20,
    },
    backLink: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    backIcon: {
      fontSize: 18,
      marginRight: 5,
    },
  });

type Props = WithStyles<typeof styles> & {
  backLink: {
    to: string;
    title: string;
  };
};

const ScreenToolbarComponent: React.FunctionComponent<Props> = ({ classes, backLink }) => {
  return (
    <div className={classes.root}>
      <Link className={classes.backLink} to={backLink.to}>
        <ChevronLeft className={classes.backIcon} />
        {backLink.title}
      </Link>
    </div>
  );
};

export const ScreenToolbar = withStyles(styles)(ScreenToolbarComponent);
