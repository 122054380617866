import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { TrophyVariantOutline } from 'mdi-material-ui';
import { PeopleAltOutlined } from '@material-ui/icons'

import { SidebarMenuItem } from '../SidebarMenuItem';
import { useTranslator } from '../../i18n/useTranslator';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const ScientificCouncilMemberSidebarContentComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <div>
      <SidebarMenuItem icon={TrophyVariantOutline} label={t('competitions')} to={'/scientific-council-member/competitions'} />
      <SidebarMenuItem icon={PeopleAltOutlined} label={t('reviewers')} to={'/scientific-council-member/reviewers'} />
    </div>
  );
};

export const ScientificCouncilMemberSidebarContent = withStyles(styles)(ScientificCouncilMemberSidebarContentComponent);
