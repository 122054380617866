import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Theme, Grid } from '@material-ui/core';
import { CloudDownloadOutlined, DeleteOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useTranslator } from '../i18n/useTranslator';
import { FileInput } from './inputs/FileInput';
import { AttachmentTemplate } from '../shared/types/AttachmentTemplate';
import { API_URL } from '../services/Api.service';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { RootState } from '../store/rootReducer';
import { Submission } from '../shared/types/Submission';
import { SubmissionService } from '../services/Submissions.service';
import { SubmissionStatusEnum } from '../shared/enums/SubmissionStatusEnum';
import { red, green } from '@material-ui/core/colors';
import { DocumentForAllReviewsBadge } from './DocumentForAllReviewsBadge';
import { ConfirmationDialog } from './ConfirmationDialog';
import { Alert } from '@material-ui/lab';
import { DuplicateAttachment } from '../shared/types/DuplicateAttachment';
import { getDuplicateSubmissionAttachments, getDuplicateAttachments } from '../shared/helpers/getDuplicateAttachments';
import { Attachment } from '../shared/types/Attachment';
import { AttachmentStatusEnum } from '../shared/enums/AttachmentStatusEnum';

const styles = ({ palette }: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 20,
      paddingTop: 20,
      '&:not(:first-child)': {
        borderTop: '1px solid rgba(0,0,0,0.05)',
      },
      '& > :first-child > *': {
        flex: 1,
      },
    },
    documentName: {
      fontSize: 14,
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    templateLinkContainer: {
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center'
    },
    templateLink: {
      display: 'flex',
      color: palette.primary.main,
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    iconDownload: {
      fontSize: 20,
      color: green[500],
      marginLeft:5
    },    
    iconDelete: {
      fontSize: 20,
      color: red[500],
      marginLeft:5
    },      
    rightContainer: {
      textAlign: 'right',
    },
    badge: {
      marginTop: 10,
      "& ~ $badge": {
        marginTop: 5
      }
    },
    downloadUploadedFileButton: {
      marginTop: 3,
    },
    documentAuthor: {
      fontSize: 12,
      marginRight: 5
    },
    changeDocumentFileInput: {
      marginLeft: 5
    },
    alert: {
      marginTop: 15,
      marginBottom: 5
    },
    alertMessage: {
      '&:not(:last-child)': {
        marginBottom: 10
      }
    }
  });

type Props = WithStyles<typeof styles> & {
  shouldValidate: boolean;
  attachmentTemplates?: Array<AttachmentTemplate>;
  submissionAttachments?: Array<SubmissionAttachment>;
  submissionId?: string;
  competetionIsActive: boolean;
  attachments: Map<string, File | null>;
  attachmentsToUpdate?: Map<string, File | null>;
  submissionStatus: Submission['status'];
  docsAssignedTo: RoleEnum;
  onFileChange: (templateId: string, file: File | null, attachmentId?: string) => void;
  onAdminFileChange?: (attachmentId: string, file: File | null) => void;
  onAttachmentRemoved?: () => void;
};

const SubmissionDocumentationComponent: React.FunctionComponent<Props> = ({
  classes,
  attachments,
  attachmentsToUpdate = null,
  attachmentTemplates,
  shouldValidate,
  submissionAttachments,
  submissionStatus,
  submissionId,
  competetionIsActive,
  docsAssignedTo,
  onFileChange,
  onAdminFileChange,
  onAttachmentRemoved,
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState('');
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
  const [duplicateAttachments, setDuplicateFileNames] = useState<Array<DuplicateAttachment>>([]);

  const { t } = useTranslator();

  const handleClose = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && attachmentToDelete && await deleteFile(attachmentToDelete);
      setAttachmentToDelete('');
    })()
  };

  const { role } = useSelector((state: RootState) => state.user);

  const handleDownloadTemplate = (templateId: string) => () => {
    if (role === RoleEnum.Admin ||
      role === RoleEnum.CompetitionCoordinator ||
      submissionId === undefined) {
        window.open(`${API_URL}/AttachmentSpecimen/${templateId}`);
    } else {
      window.open(`${API_URL}/AttachmentSpecimen/${templateId}/${submissionId}`);
    }    
  };

  useEffect(() => {
    setDuplicateFileNames([]);
    
    if (attachmentTemplates) {
      const duplicates = getDuplicateAttachments(attachmentTemplates, attachments);
      setDuplicateFileNames(duplicates);
    }
    else if (submissionAttachments) {
      const duplicates = getDuplicateSubmissionAttachments(submissionAttachments, attachments);
      setDuplicateFileNames(duplicates);
    }
  }, [attachments, submissionAttachments]);

  const handleFileChange = (templateId: string, value: File | null, attachmentId?: string) => {
    onFileChange(templateId, value, attachmentId);
  };

  const handleDownloadAttachment = (id: string) => () => {
    window.open(`${API_URL}/Attachment/${id}`);
  };

  const deleteFile = async (id: string) => {
    await SubmissionService.removeFile(id);

    onAttachmentRemoved && onAttachmentRemoved();
  };

  const handleAttachmentDelete = (id: string, isForAllReviews: boolean) => async () => {
    const dialogTitle = isForAllReviews ? t('confirmDeleteDocumentFromAllReviews') : t('confirmAttachmentTemplateDeletion');
    setConfirmationDialogTitle(dialogTitle);
    setAttachmentToDelete(id);
    setIsConfirmDialogOpen(true);
  };

  const renderDownloadButton = (
    attachment: Attachment,
    templateName: string,
    displaySenderName?: boolean
  ): JSX.Element => {
    const senderFullName = displaySenderName ? ` (${attachment.senderFirstName} ${attachment.senderLastName})` : '';

    return (
      <div
        className={classNames(classes.templateLink, classes.downloadUploadedFileButton)}
        onClick={handleDownloadAttachment(attachment.id)}
      >
        {t('downloadDocument')} {templateName}{senderFullName}<CloudDownloadOutlined className={classes.iconDownload}/>
      </div>
    );
  };

  const renderDeleteButton = (attachment: SubmissionAttachment): JSX.Element => {
    return (
      <div
        className={classNames(classes.templateLink, classes.downloadUploadedFileButton)}
        onClick={handleAttachmentDelete(attachment.attachments[0].id, attachment.isForAllReviews)}
      >
        {t('delete')}<DeleteOutlined className={classes.iconDelete}/>
      </div>
    );
  };

  const shouldRenderDownloadTemplate = (attachment: SubmissionAttachment): boolean => {
    return attachment.hasSpecimenFile && 
      ((attachment.role === RoleEnum.Author && role === RoleEnum.Author && competetionIsActive === true) || 
       (attachment.role === RoleEnum.Reviewer && role === RoleEnum.Reviewer));
  };

  return (
    <>
      {Boolean(duplicateAttachments.length) && 
      (role === RoleEnum.Author && docsAssignedTo === RoleEnum.Author || 
      role === RoleEnum.Reviewer && docsAssignedTo == RoleEnum.Reviewer) && (
        <Alert className={docsAssignedTo === RoleEnum.Author ? classes.alert : ''} severity={"warning"}>
          {duplicateAttachments.map(attachment => (
            <div className={classes.alertMessage}>
              {t('duplicatedFileInfo')
                .replace('{0}', attachment.fileName)
                .replace('{1}', attachment.templateNames.length.toString())
                .replace('{2}', attachment.templateNames.map(a => `"${a}"`).join(', '))}
            </div>
          ))}
        </Alert>
      )}
      <div>
        {attachmentTemplates && Boolean(attachmentTemplates.length) ? 
          attachmentTemplates.map((template) => (
            <div className={classes.row}>
              <div>
                <div className={classes.documentName}>{template.name}</div>
              </div>

              {template.specimenFileName && (
                <div className={classes.templateLinkContainer}>
                  <span className={classes.templateLink} onClick={handleDownloadTemplate(template.id)}>
                    {t('downloadTemplate')} {template.name}<CloudDownloadOutlined className={classes.iconDownload}/>
                  </span>
                </div>
              )}

              <div className={classes.rightContainer}>
                <FileInput
                  error={shouldValidate && template.isRequired && !attachments.get(template.id)}
                  helperText={shouldValidate && template.isRequired && !attachments.get(template.id) ? t('fillDocument') : ''}
                  size={'small'}
                  label={t('document') + (template.isRequired ? ' (*)': '')}
                  value={attachments.get(template.id) ?? null}
                  onChange={(value: File | null) => handleFileChange(template.id, value)}
                />
              </div>
            </div>
          )) : submissionAttachments && 
            submissionAttachments.map((attachment) => {
              const shouldRenderMiddleColumn = shouldRenderDownloadTemplate(attachment);
              
              return (
                <Grid container className={classes.row}>
                  {(role === RoleEnum.Admin || role === RoleEnum.CompetitionCoordinator) ? (
                    <Grid container alignItems={"center"} justify={"space-between"}>
                      <Grid item xs={4}>
                        <div className={classes.documentName}>{attachment.templateName}</div>
                      </Grid>
                      {attachment.attachments.length ? (
                        <Grid container item xs={8} alignItems={"center"} spacing={1}>
                          {attachment.attachments.map(attach => (
                            <>
                              <Grid item xs={6}>
                                {renderDownloadButton(attach, attachment.templateName, true)}
                              </Grid>
                              <Grid item xs={6} className={classes.rightContainer}>
                                {' '}
                                <FileInput
                                  classes={{root: classes.changeDocumentFileInput}}
                                  size={'small'}
                                  label={t('changeDocument')}
                                  value={attachmentsToUpdate?.get(attach.id) ?? null}
                                  onChange={(value: File | null) => onAdminFileChange && onAdminFileChange(attach.id, value)}
                                />
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      ) : (
                        <Grid item className={classes.rightContainer}>
                          <span>{t('none')}</span>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Grid container alignItems={"center"} justify={"space-between"}>
                      <Grid item xs={shouldRenderMiddleColumn ? 4 : 6}>
                        <div className={classes.documentName}>
                          {attachment.templateName}
                        </div>
                      </Grid>

                      {shouldRenderMiddleColumn && (
                        <Grid item xs={4}>
                          <div className={classes.templateLinkContainer}>
                            <span className={classes.templateLink} onClick={handleDownloadTemplate(attachment.templateId)}>
                              {t('downloadTemplate')} {attachment.templateName}<CloudDownloadOutlined className={classes.iconDownload} />
                            </span>
                          </div>
                        </Grid>
                      )}

                      <Grid item xs={shouldRenderMiddleColumn ? 4 : 6}>
                        <div className={classes.rightContainer}>
                          {attachment.role === RoleEnum.Reviewer && role === RoleEnum.Reviewer && (
                            <div>
                              {attachment.attachments.length ? (
                                <>
                                  {renderDownloadButton(attachment.attachments[0], attachment.templateName)}
                                  {' '}
                                  {submissionStatus === SubmissionStatusEnum.Review && (
                                    <>
                                      {' '}
                                      {renderDeleteButton(attachment)}
                                    </>
                                  )}
                                </>
                              ) : (
                                submissionStatus === SubmissionStatusEnum.Review ? (
                                  <FileInput
                                    error={shouldValidate && attachment.isRequired && !attachments.get(attachment.templateId)}
                                    helperText={shouldValidate && attachment.isRequired && !attachments.get(attachment.templateId) ? t('fillDocument') : ''}
                                    size={'small'}
                                    label={t('document') + (attachment.isRequired ? ' (*)': '')}
                                    value={attachments.get(attachment.templateId) ?? null}
                                    onChange={(value: File | null) => handleFileChange(attachment.templateId, value)}
                                  />
                                ) : (
                                  <span>{t('none')}</span>
                                )
                              )}
                            </div>
                          )}
                  
                          {attachment.role === RoleEnum.Author && role === RoleEnum.Reviewer && (
                            attachment.attachments.length ?
                              renderDownloadButton(attachment.attachments[0], attachment.templateName) 
                              : (
                                <span>{t('none')}</span>
                              )
                          )}

                          {role === RoleEnum.Author && (
                            attachment.attachments.length ? (
                              <>
                                {renderDownloadButton(attachment.attachments[0], attachment.templateName)}
                                {(submissionStatus == SubmissionStatusEnum.Autosaved 
                                || attachment.attachments[0].status === AttachmentStatusEnum.Autosaved) && (
                                  <>  
                                    {' '}
                                    {' '}
                                    {renderDeleteButton(attachment)}
                                  </>
                                )}
                              </>
                            ) : (
                              competetionIsActive ? (
                                <FileInput
                                  error={shouldValidate && attachment.isRequired && !attachments.get(attachment.templateId)}
                                  helperText={shouldValidate && attachment.isRequired && !attachments.get(attachment.templateId) ? t('fillDocument') : ''}
                                  size={'small'}
                                  label={t('document') + (attachment.isRequired ? ' (*)': '')}
                                  value={attachments.get(attachment.templateId) ?? null}
                                  onChange={(value: File | null) => handleFileChange(attachment.templateId, value)}
                                />
                              ) : (
                                <span>{t('none')}</span>
                              )
                            )
                          )}

                          {(role === RoleEnum.BoardMember ||
                          role === RoleEnum.FinanceCoordinator ||
                          role === RoleEnum.ScientificCouncilMember ||
                          role === RoleEnum.CompetitionCommitteeMember ||
                          role === RoleEnum.CompetitionJuryMember) && (
                            attachment.attachments.length ? 
                              attachment.attachments.map(attach =>
                                renderDownloadButton(attach, attachment.templateName, true)
                              ) : (
                                <span>{t('none')}</span>
                              )
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  {attachment.isForAllReviews && (
                    <Grid item xs={12} className={classes.badge}>
                      <DocumentForAllReviewsBadge />
                    </Grid>
                  )}
                </Grid>
              );
            })}
            <ConfirmationDialog open={isConfirmDialogOpen} onClose={(confirmed) => handleClose(confirmed)} dialogTitle={confirmationDialogTitle} />
      </div>
    </>
  );
};

export const SubmissionDocumentation = withStyles(styles)(SubmissionDocumentationComponent);
