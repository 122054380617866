import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { Form } from '../Form';
import { FieldTextField } from '../form-fields/FieldTextField';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { specialty as specialtyValidator } from '../../shared/validators';
import { ErrorService } from '../../services/Error.service';
import { FormError } from '../FormError';
import { UserService } from '../../services/User.service';
import { RootState } from '../../store/rootReducer';
import { FormSuccess } from '../FormSuccess';

const styles = () =>
  createStyles({
    button: {
      alignSelf: 'flex-end',
    },
  });

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

type FormValues = {
  specialty: string;
};

const UpdateSpecialtyFormComponent: React.FunctionComponent<Props> = ({ classes }) => {
  const { specialty } = useSelector((state: RootState) => state.user);
  const [serverError, setServerError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      specialty: specialtyValidator(t),
    });

  const initialValues = {
    specialty,
  };

  const handleSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    setServerError('');
    setSuccessMessage('');
    setSubmitting(true);
    try {
      await UserService.updateSpecialty(values.specialty);

      resetForm();
      setSuccessMessage('updateSpecialtySuccess');
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }
    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <FormContent>
          <FieldTextField name={'specialty'} label={t('reviewerSpecialization')} />

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <FormSuccess show={Boolean(successMessage)} message={t(successMessage)} />

          <Button className={classes.button} type={'submit'} loading={isSubmitting}>
            {t('save')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const UpdateSpecialtyForm = withStyles(styles)(withRouter(UpdateSpecialtyFormComponent));
