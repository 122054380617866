import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { BoardMemberRoute } from '../../routes/BoardMemberRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { BoardMemberCompetitionsScreen } from '../BoardMember/BoardMemberCompetitionsScreen';
import { CompetitionRankingListScreen } from '../Common/CompetitionRankingListScreen';
import { BoardMemberSubmissionDetailsScreen } from '../BoardMember/BoardMemberSubmissionDetailsScreen';
import { ReviewersScreen } from '../Common/ReviewersScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const BoardMemberScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <BoardMemberRoute exact path={`${match.path}/competitions`} component={BoardMemberCompetitionsScreen} />
        <BoardMemberRoute exact path={`${match.path}/competitions/:id/ranking-list`} component={CompetitionRankingListScreen} />
        <BoardMemberRoute exact path={`${match.path}/submissions/:id`} component={BoardMemberSubmissionDetailsScreen} />
        <BoardMemberRoute exact path={`${match.path}/reviewers`} component={ReviewersScreen} />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const BoardMemberScreens = withStyles(styles)(BoardMemberScreensComponent);
