import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ custom, palette }: Theme) =>
  createStyles({
    root: {
      backgroundColor: palette.error.light,
      color: palette.error.contrastText,
      padding: '10px 20px',
      borderRadius: custom.radius.r_0,
      marginBottom: 20,
    },
  });

type Props = WithStyles<typeof styles> & {
  error: string;
  show: boolean;
  className?: string;
};

const FormErrorComponent: React.FunctionComponent<Props> = ({ classes, className, error, show }) => {
  return show ? <div className={classNames(classes.root, className)}>{error}</div> : null;
};

export const FormError = withStyles(styles)(FormErrorComponent);
