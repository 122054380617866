import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import { useTranslator} from '../i18n/useTranslator';
import classNames from 'classnames';

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      padding: '20px 20px 0 20px',
      color: 'gray'
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
};

const HelpMessageComponent: React.FunctionComponent<Props> = ({ classes, className }) => {
  const { t } = useTranslator();

  return(
    <div className={classNames(classes.root, className)}>
        {t('iInCaseOfHelp')} <a href="mailto:kontakt.fundacja@polpharma.com">kontakt.fundacja@polpharma.com</a>
        <br />
        <a href="https://polpharma.pl/fundacja" target="_blank">Naukowa Fundacja Polpharmy</a>
    </div>
  )
};

export const HelpMessage = withStyles(styles)(HelpMessageComponent);
