import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { List } from '../../List';
import { Box } from '../../Box';
import { NotificationTemplateService } from '../../../services/NotificationTemplate.service';
import { NotificationTemplate } from '../../../shared/types/NotificationTemplate';
import { ErrorService } from '../../../services/Error.service';
import { Message } from '../../Message';
import { BoxLoader } from '../../BoxLoader';
import { Link } from '../../Link';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AdminEmailTemplatesScreenComponent: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<Array<NotificationTemplate>>([]);
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const listHeader = [
    {
      key: 'title',
      text: t('templateTitle'),
    },
  ];
  const mapRowsToListRows = () =>
    templates.map((template) => ({
      id: template.id,
      items: [
        {
          key: 'title',
          content: <Link to={`/admin/templates/${template.type}`}>{template.title}</Link>,
        },
      ],
    }));

  const fetchTemplates = () => {
    (async () => {
      try {
        setServerError('');
        setLoading(true);

        const {
          data: { result },
        } = await NotificationTemplateService.getAll();

        setTemplates(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchTemplates, []);

  return (
    <div>
      <SectionHeader title={t('emailTemplates')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Box>{loading ? <BoxLoader /> : <List headerCells={listHeader} rows={mapRowsToListRows()} />}</Box>
      )}
    </div>
  );
};

export const AdminEmailTemplatesScreen = withStyles(styles)(AdminEmailTemplatesScreenComponent);
