import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { Button } from './Button';
import { useTranslator } from '../i18n/useTranslator';
import { DocumentFormRow } from './DocumentFormRow';
import { DocumentRow } from '../shared/types/DocumentRow';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { ConfirmationDialog } from './ConfirmationDialog';

const styles = () =>
  createStyles({
    root: {},
    documentsList: {
      marginTop: 20,
    },
  });

type Props = WithStyles<typeof styles> & {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  className?: string;
};

const DocumentationConfiguratorComponent: React.FunctionComponent<Props> = ({ classes, className, setFieldValue }) => {
  const [documents, setDocuments] = useState<Array<DocumentRow>>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [documentToRemoveIndex, setDocumentToRemoveIndex] = useState(-1);

  const { t } = useTranslator();

  const handleDocumentRemove = (index: number) => {
    setDocuments(
      documents.filter((document, i) => {
        return index !== i;
      })
    );
  };

  const handleDocumentNameChange = (index: number) => (value: string) => {
    setDocuments(
      documents.map((document, i) => {
        if (index === i) {
          return {
            ...document,
            name: value
          };
        }

        return document;
      })
    );
  };

  const handleDocumentFileChange = (index: number) => (value: File | null) => {
    setDocuments(
      documents.map((document, i) => {
        if (index === i) {
          return {
            ...document,
            specimen: value
          };
        }

        return document;
      })
    );
  };

  const handleRoleChange = (index: number) => (value: string) => {
    setDocuments(
      documents.map((document, i) => {
        if (index === i) {
          return {
            ...document,
            role: value
          };
        }

        return document;
      })
    );
  };

  const handleIsRequiredChange = (index: number) => (value: boolean) => {
    setDocuments(
      documents.map((document, i) => {
        if (index === i) {
          return {
            ...document,
            isRequired: value
          };
        }

        return document;
      })
    );
  };

  const handleIsForAllReviewsChange = (index: number) => (value: boolean) => {
    setDocuments(
      documents.map((document, i) => {
        if (index === i) {
          return {
            ...document,
            isForAllReviews: value
          };
        }

        return document;
      })
    );
  };
  
  const handleVisibleForRolesChange = (index: number) => (value: RoleEnum[]) => {
    setDocuments(
      documents.map((document, i) => {
        if (index === i) {
          return {
            ...document,
            visibleForRoles: value
          };
        }

        return document;
      })
    );
  };  

  useEffect(() => {
    setFieldValue('attachmentTemplates', documents);
  }, [documents, setFieldValue]);

  const handleAddDocumentClick = () => {
    setDocuments([
      ...documents,
      {
        name: '',
        role: RoleEnum.Author,
        specimen: null,
        isRequired: true,
        isForAllReviews: false,
        visibleForRoles: [
          RoleEnum.ScientificCouncilMember,
          RoleEnum.BoardMember,
          RoleEnum.CompetitionCommitteeMember,
          RoleEnum.CompetitionJuryMember
        ]
      },
    ])
  };

  const handleRemove = (index: number) => {
    setIsConfirmDialogOpen(true);
    setDocumentToRemoveIndex(index);
  };
  
  const handleCloseConfirmationDialog = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && documentToRemoveIndex > -1 && handleDocumentRemove(documentToRemoveIndex);
      setDocumentToRemoveIndex(-1);
    })()
  };

  return (
    <>
      <div className={classNames(classes.root, className)}>
        <Button
          size={'small'}
          onClick={handleAddDocumentClick}
        >
          {t('addDocument')}
        </Button>
        <div className={classes.documentsList}>
          {documents.length ? (
            documents.map((document, index: number) => (
              <DocumentFormRow
                key={index}
                documentName={document.name}
                specimen={document.specimen}
                role={document.role}
                isRequired={document.isRequired}
                isForAllReviews={document.isForAllReviews}
                visibleForRoles={document.visibleForRoles}
                onRemove={() => handleRemove(index)}
                onDocumentNameChange={handleDocumentNameChange(index)}
                onDocumentFileChange={handleDocumentFileChange(index)}
                onRoleChange={handleRoleChange(index)}
                onIsRequiredChange={handleIsRequiredChange(index)}
                onIsForAllReviewsChange={handleIsForAllReviewsChange(index)}
                onVisibleForRolesChange={handleVisibleForRolesChange(index)}
              />
            ))
          ) : (
            <span>{t('noDocumentsAdded')}</span>
          )}
        </div>
      </div>

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={(confirmed) => handleCloseConfirmationDialog(confirmed)}
        dialogTitle={t('confirmAttachmentTemplateDeletion')}
      />
    </>
  );
};

export const DocumentationConfigurator = withStyles(styles)(DocumentationConfiguratorComponent);
