import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { createStyles } from '@material-ui/core';

type GrayUnion = 'g_0' | 'g_1' | 'g_2' | 'g_3' | 'g_4';
type RadiusUnion = 'r_0';

type GrayType = { [key in GrayUnion]: CSSProperties['color'] };
type RadiusType = { [key in RadiusUnion]: number };

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    custom: {
      radius: RadiusType;
      gray: GrayType;
    };
  }

  interface ThemeOptions {
    custom: {
      radius: RadiusType;
      gray: GrayType;
    };
  }
}

const palette = {
  primary: {
    main: '#1b79de',
  },
  secondary: {
    main: '#550987',
  },
  success: {
    main: '#12e048',
    contrastText: '#13ba3f',
    light: '#91ffae',
  },
};

const custom = {
  radius: {
    r_0: 4,
  },
  gray: {
    g_0: '#f2f2f2',
    g_1: '#d6d6d6',
    g_2: '#b3b3b3',
    g_3: '#a1a1a1',
    g_4: '#858585',
  },
};

const MuiButton = createStyles({
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
});

const theme: ThemeOptions = {
  palette,
  custom,
  overrides: {
    MuiButton,
  },
};

export default theme;
