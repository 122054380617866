import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { ReviewerRoute } from '../../routes/ReviewerRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { ReviewerActiveSubmissionsScreen } from '../../screens/Reviewer/ReviewerActiveSubmissionsScreen';
import { ReviewerArchivedSubmissionsScreen } from '../../screens/Reviewer/ReviewerArchivedSubmissionsScreen';
import { ReviewerSubmissionScreen } from '../../screens/Reviewer/ReviewerSubmissionScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const ReviewerScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <ReviewerRoute exact path={`${match.path}/submissions/active`} component={ReviewerActiveSubmissionsScreen} />
        <ReviewerRoute exact path={`${match.path}/submissions/archive`} component={ReviewerArchivedSubmissionsScreen} />
        <ReviewerRoute exact path={`${match.path}/submissions/:id`} component={ReviewerSubmissionScreen} />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const ReviewerScreens = withStyles(styles)(ReviewerScreensComponent);
