import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import { Form } from '../Form';
import { FieldTextField } from '../form-fields/FieldTextField';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { email, password } from '../../shared/validators';
import { ErrorService } from '../../services/Error.service';
import { UserService } from '../../services/User.service';
import { FormError } from '../FormError';
import { RoleEnum } from '../../shared/enums/RoleEnum';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

type FormValues = {
  email: string;
  password: string;
};

const LoginFormComponent: React.FunctionComponent<Props> = ({ history }) => {
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      email: email(t),
      password: password(t),
    });

  const initialValues = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setServerError('');
    setSubmitting(true);

    try {
      await UserService.login(values);

      try {
        const {
          data: {
            result: { role },
          },
        } = await UserService.getMe();

        switch (role) {
          case RoleEnum.Admin:
            history.push('/admin/competitions');
            break;
          case RoleEnum.Author:
            history.push('/author/competitions');
            break;
          case RoleEnum.Reviewer:
            history.push('/reviewer/submissions/active');
            break;
          case RoleEnum.ScientificCouncilMember:
            history.push('/scientific-council-member/competitions');
            break;
          case RoleEnum.CompetitionCommitteeMember:
            history.push('/competition-committee-member/competitions');
            break;
          case RoleEnum.CompetitionJuryMember:
            history.push('/competition-jury-member/competitions');
            break;
          case RoleEnum.FinanceCoordinator:
            history.push('/finance-coordinator/competitions');
            break;
          case RoleEnum.BoardMember:
            history.push('/board-member/competitions');
            break;
          case RoleEnum.CompetitionCoordinator:
            history.push('/competition-coordinator/competitions');
            break;
          default:
            history.push('/');
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
        console.log(error);
      }
    } catch (error) {
      setServerError(ErrorService.parseError(error));
      console.log(error);
    }

    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <FormContent>
          <FieldTextField name={'email'} label={t('email')} type={'email'} />
          <FieldTextField name={'password'} label={t('password')} type={'password'} />

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <Button type={'submit'} loading={isSubmitting}>
            {t('signIn')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const LoginForm = withStyles(styles)(withRouter(LoginFormComponent));
