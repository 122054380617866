import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { CompetitionCommitteeMemberRoute } from '../../routes/CompetitionCommitteeMemberRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { CompetitionCommitteeMemberCompetitionsScreen } from '../CompetitionCommitteeMember/CompetitionCommitteeMemberCompetitionsScreen';
import { CompetitionRankingListScreen } from '../Common/CompetitionRankingListScreen';
import { SubmissionDetailsScreen } from '../Common/ScientificCouncilMemberSubmissionDetailsScreen';
import { ReviewersScreen } from '../Common/ReviewersScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const CompetitionCommitteeMemberScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <CompetitionCommitteeMemberRoute exact path={`${match.path}/competitions`} component={CompetitionCommitteeMemberCompetitionsScreen} />
        <CompetitionCommitteeMemberRoute
          exact
          path={`${match.path}/competitions/:id/ranking-list`}
          component={CompetitionRankingListScreen}
        />
        <CompetitionCommitteeMemberRoute
          exact
          path={`${match.path}/submissions/:id`}
          component={SubmissionDetailsScreen}
        />
        <CompetitionCommitteeMemberRoute
          exact
          path={`${match.path}/reviewers`}
          component={ReviewersScreen}
        />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const CompetitionCommitteeMemberScreens = withStyles(styles)(CompetitionCommitteeMemberScreensComponent);
