import React from 'react';
import { createStyles, WithStyles, withStyles, Slider, Tooltip, Theme } from '@material-ui/core';
import { ErrorOutlineRounded } from '@material-ui/icons';

import { useTranslator } from '../i18n/useTranslator';
import { FormError } from './FormError';
import { CompetitionTypeEnum } from '../shared/enums/CompetitionTypeEnum';
import { BoxLoader } from './BoxLoader';
import { Mark } from '../shared/types/Mark';
import { MarkStatusEnum } from '../shared/enums/MarkStatusEnum';

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    markButton: {
      alignSelf: 'flex-end',
      marginTop: 10,
    },
    error: {
      marginTop: 10,
      marginBottom: 0,
      color: palette.error.main
    },
    errorIcon: {
      fontSize: '1.25rem',
      top: '5px',
      position: 'relative'
    },
    success: {
      marginTop: 10,
      marginBottom: 0,
    },
    slider: {
      marginTop: 10,
    },
  });

type Props = WithStyles<typeof styles> & {
  competitionType: CompetitionTypeEnum;
  shouldValidate: boolean;
  serverError: string;
  loading: boolean;
  mark: Mark;
  onChange: (value: number | Array<number>) => void;
};

const ReviewSubmissionComponent: React.FunctionComponent<Props> = ({
  classes,
  competitionType,
  shouldValidate,
  serverError,
  loading,
  mark,
  onChange
}) => {
  const initValue: Mark = {
    id: '',
    reviewer: { firstName: '', lastName: '' },
    submissionId: '',
    reviewerId: '',
    mark: 0,
    status: MarkStatusEnum.Invalid
  };
  const min = 0;
  const max = competitionType === CompetitionTypeEnum.ScholarshipProgram ? 100 : 10;
  const step = 1;

  const { t } = useTranslator();

  return loading ? (
    serverError ? (
      <FormError className={classes.error} show={Boolean(serverError)} error={t(serverError)} />
    ) : (
      <BoxLoader />
    )
  ) : (
    <div className={classes.root}>
      <Slider
        className={classes.slider}
        ValueLabelComponent={(props) => (
          <Tooltip open={props.open} enterTouchDelay={0} placement='top' title={props.value}>
            {props.children}
          </Tooltip>
        )}
        value={mark.mark}
        defaultValue={initValue.mark}
        step={step}
        min={min}
        max={max}
        valueLabelDisplay='on'
        onChange={(e, value) => onChange(value)}
      />

      {shouldValidate && mark.mark === 0 && (
        <div className={classes.error}>
          <ErrorOutlineRounded className={classes.errorIcon}></ErrorOutlineRounded> {t('markRequired')}
        </div>
      )}
    </div>
  );
};

export const ReviewSubmission = withStyles(styles)(ReviewSubmissionComponent);
