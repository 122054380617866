import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import MaterialTextField, { TextFieldProps as MaterialTextFieldProps } from '@material-ui/core/TextField';

const styles = () => createStyles({});

type Props = MaterialTextFieldProps & WithStyles<typeof styles>;

const TextFieldComponent: React.FunctionComponent<Props> = ({ ...restProps }) => {
  return <MaterialTextField variant={'outlined'} {...restProps} />;
};

export const TextField = withStyles(styles)(TextFieldComponent);
