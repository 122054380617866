export const ErrorService = {
  parseError: (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.responseException &&
      error.response.data.responseException.exceptionMessage
    ) {
      return error.response.data.responseException.exceptionMessage;
    }

    return 'serverError';
  },

  parseStatusCode: (error: any) => {
    if (
      error &&
      error.response &&
      error.response.status
    ) {
      return error.response.status;
    }

    return null;
  },
};
