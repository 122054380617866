import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ custom, palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: palette.common.white,
      padding: 20,
      borderRadius: custom.radius.r_0,
      boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.1)',
      '& ol': {
        '& li': {
          margin: '10px 0',
        },
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
};

const BoxComponent: React.FunctionComponent<Props> = ({ children, classes, className }) => {
  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export const Box = withStyles(styles)(BoxComponent);
