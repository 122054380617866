import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import MaterialCircularProgress, { CircularProgressProps as MaterialCircularProgressProps } from '@material-ui/core/CircularProgress';

const styles = () => createStyles({});

type Props = MaterialCircularProgressProps & WithStyles<typeof styles>;

const CircularProgressComponent: React.FunctionComponent<Props> = ({ ...restProps }) => {
  return <MaterialCircularProgress {...restProps} />;
};

export const CircularProgress = withStyles(styles)(CircularProgressComponent);
