import { ApiService } from './Api.service';
import { ApiResponse } from '../shared/types/ApiResponse';
import { Submission } from '../shared/types/Submission';
import { User } from '../shared/types/User';
import { SubmissionStage } from '../shared/types/SubmissionStage';
import { SubmissionStageFormData } from '../shared/types/SubmissionStageFormData';
import { SubmissionStageReviewer } from '../shared/types/SubmissionStageReviewer';
import { AttachmentTemplate } from '../shared/types/AttachmentTemplate';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { SuggestedReviewer } from '../shared/types/SuggestedReviewer';

export const StageService = {
  resource: 'Stage',

  getStages: (submissionId: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<SubmissionStage>>>(`${StageService.resource}?submissionId=${submissionId}`, { withCredentials: true });
  },

  getStageReviewers: (stageId: string) => {
    return ApiService.get<ApiResponse<Array<User>>>(`${StageService.resource}/${stageId}/reviewers`, { withCredentials: true });
  },

  getAvailableStageReviewers: (stageId: string) => {
    return ApiService.get<ApiResponse<Array<User>>>(`${StageService.resource}/${stageId}/reviewers/available`, { withCredentials: true });
  },

  getStage: (stageId: string) => {
    return ApiService.get<ApiResponse<SubmissionStage>>(`${StageService.resource}/${stageId}`, { withCredentials: true });
  },

  getActiveStage: (submissionId: Submission['id']) => {
    return ApiService.get<ApiResponse<SubmissionStage>>(`${StageService.resource}/active?submissionId=${submissionId}`, { withCredentials: true });
  },

  addStage: (data: SubmissionStageFormData) => {
    return ApiService.post(`${StageService.resource}`, data, { withCredentials: true } );
  },

  addStageReviewer: (stageId: string, data: SubmissionStageReviewer) => {
    return ApiService.post(`${StageService.resource}/${stageId}/reviewers`, data, { withCredentials: true } );
  },

  deleteStageReviewer: (stageId: SubmissionStage['id'], userId: User['userId']) => {
    return ApiService.delete(`${StageService.resource}/${stageId}/reviewers?userId=${userId}`, { withCredentials: true } );
  },

  updateStage: (submissionId: Submission['id'], stageId: SubmissionStage['id'], data: SubmissionStageFormData) => {
    return ApiService.put(`${StageService.resource}/${stageId}?submissionId=${submissionId}`, data, { withCredentials: true });
  },

  addTemplateFile: (stageId: SubmissionStage['id'], data: FormData) => {
    return ApiService.post(`${StageService.resource}/${stageId}/attachment-templates`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  addFile: (stageId: SubmissionStage['id'], data: FormData) => {
    return ApiService.post(`${StageService.resource}/${stageId}/attachments`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  removeFile: (id: string) => {
    return ApiService.delete(`${StageService.resource}/attachments/${id}`, {
      withCredentials: true,
    });
  },

  updateFile: (id: string, data: FormData) => {
    return ApiService.put(`${StageService.resource}/attachments/${id}`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  submitAttachments: (stageId: string) => {
    return ApiService.put(`${StageService.resource}/attachments/${stageId}/submit`, {}, {
      withCredentials: true
    });
  },

  getAttachmentTemplates: (stageId: SubmissionStage['id']) => {
    return ApiService.get<ApiResponse<Array<AttachmentTemplate>>>(`${StageService.resource}/${stageId}/attachment-templates`, {
      withCredentials: true,
    });
  },

  getStageAttachmentsForRole: (id: SubmissionStage['id'], role: RoleEnum) => {
    return ApiService.get<ApiResponse<Array<SubmissionAttachment>>>(`${StageService.resource}/attachments/${id}?role=${role}`, {
      withCredentials: true,
    });
  },

  deleteAttachmentTemplate: (attachmentTemplateId: AttachmentTemplate['id']) => {
    return ApiService.delete(`${StageService.resource}/attachment-templates/${attachmentTemplateId}`, {
      withCredentials: true,
    });
  },

  addSuggestedReviewer: (stageId: SubmissionStage['id'], userId: User['userId']) => {
    return ApiService.post(
      `${StageService.resource}/${stageId}/reviewers/suggested?userId=${userId}`,
      {},
      { withCredentials: true }
    );
  },
  acceptSuggestedReviewer: (stageId: SubmissionStage['id'], userId: User['userId']) => {
    return ApiService.post(
      `${StageService.resource}/${stageId}/reviewers/accept?userId=${userId}`,
      {},
      { withCredentials: true }
    );
  },
  getAvailableSuggestedReviewers: (stageId: SubmissionStage['id']) => {
    return ApiService.get<ApiResponse<Array<User>>>(`${StageService.resource}/${stageId}/reviewers/suggested/available/`, { withCredentials: true });
  },
  removeSuggestedReviewer: (stageId: string, userId: string) => {
    return ApiService.delete(`${StageService.resource}/${stageId}/reviewers/suggested?userId=${userId}`, 
    { withCredentials: true });
  },
  getSuggestedReviewers: (stageId: SubmissionStage['id']) => {
    return ApiService.get<ApiResponse<Array<SuggestedReviewer>>>(`${StageService.resource}/${stageId}/reviewers/suggested`, 
    { withCredentials: true });
  },
  canReviewStage: (id: SubmissionStage['id'], userId: User['userId']) => {
    return ApiService.get<ApiResponse<boolean>>(`${StageService.resource}/${id}/canReview?userId=${userId}`, {
      withCredentials: true
    });
  },
};