import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles, Dialog, DialogTitle, DialogActions } from '@material-ui/core';

import { useTranslator } from '../i18n/useTranslator';
import { Button } from './Button';
import { ErrorService } from '../services/Error.service';
import { SubmissionStatusEnum } from '../shared/enums/SubmissionStatusEnum';
import { SubmissionService } from '../services/Submissions.service';
import { Submission } from '../shared/types/Submission';
import { Message } from './Message';
import { ConfirmationDialog } from './ConfirmationDialog';

const styles = () =>
  createStyles({
    restoreSubmissionButtonContainer: {
      textAlign: 'center',
    },
  });

type Props = WithStyles<typeof styles> & {
  submissionId: Submission['id'];
  onUpdated: () => void;
};

const RestoreSubmissionComponent: React.FunctionComponent<Props> = ({ classes, submissionId, onUpdated }) => {
  const [serverError, setServerError] = useState('');
  const [saving, setSaving] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { t } = useTranslator();

  const handleClose = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && await handleRestoreSubmission();
    })()
  };

  const handleRestoreSubmission = async () => {
    setSaving(true);

    try {
      await SubmissionService.updateStatus(submissionId, SubmissionStatusEnum.Draft);

      onUpdated();
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }

    setSaving(false);
  };

  return (
    <div>
        <div className={classes.restoreSubmissionButtonContainer}>
            <Button
                loading={saving}
                disabled={saving}
                onClick={() => setIsConfirmDialogOpen(true)}
                >
                    {t('restoreSubmission')}
            </Button>
        </div>
        {serverError && (
            <Message type={'ERROR'}>{t(serverError)}</Message>
        )}

        <ConfirmationDialog open={isConfirmDialogOpen} onClose={(confirmed) => handleClose(confirmed)} dialogTitle={t('confirmSubmissionRestore')} />
    </div>
  );
};

export const RestoreSubmission = withStyles(styles)(RestoreSubmissionComponent);
