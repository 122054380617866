import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import { Form } from '../Form';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { FieldTextField } from '../form-fields/FieldTextField';
import { emailTemplateTitle, emailTemplateBody } from '../../shared/validators';
import { FormError } from '../FormError';
import { ErrorService } from '../../services/Error.service';
import { NotificationTemplateService } from '../../services/NotificationTemplate.service';
import { NotificationTemplateFormData } from '../../shared/types/NotificationTemplateFormData';
import { NotificationTemplate } from '../../shared/types/NotificationTemplate';

const styles = () =>
  createStyles({
    saveButton: {
      alignSelf: 'flex-end',
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<void> & {
    templateId: NotificationTemplate['id'];
    initValues: NotificationTemplateFormData;
  };

type FormValues = NotificationTemplateFormData;

const EmailTemplateFormComponent: React.FunctionComponent<Props> = ({ classes, history, initValues, templateId }) => {
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      title: emailTemplateTitle(t),
      body: emailTemplateBody(t),
    });

  const initialValues: FormValues = {
    ...initValues,
    type: 0,
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true);

    try {
      await NotificationTemplateService.update(templateId, values);

      history.push('/admin/templates');
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }

    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting }) => (
        <FormContent>
          <FieldTextField name={'title'} label={t('templateTitle')} fullWidth />

          <FieldTextField name={'body'} label={t('templateBody')} multiline rows={14} fullWidth />

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <Button className={classes.saveButton} type={'submit'} loading={isSubmitting} disabled={!dirty || isSubmitting}>
            {t('save')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const EmailTemplateForm = withStyles(styles)(withRouter(EmailTemplateFormComponent));
