export enum RoleEnum {
  Author = 'Author',
  Reviewer = 'Reviewer',
  Admin = 'Administrator',
  ScientificCouncilMember = 'Scientific Council Member',
  FinanceCoordinator = 'Finance Coordinator',
  BoardMember = 'Board Member',
  CompetitionCoordinator = 'Competition Coordinator',
  CompetitionCommitteeMember = "Competition Committee Member",
  CompetitionJuryMember = "Competition Jury Member"
}
