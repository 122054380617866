import { UserState } from './reducer';
import { createAction, ActionsUnion } from '../helpers';
import { ActionType } from '../actionTypes';

type UserSetActionPayload = UserState;

export const UserActions = {
  setUser: (payload: UserSetActionPayload) => createAction(ActionType.SET_USER, payload),
};

export type UserActions = ActionsUnion<typeof UserActions>;
