import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import { Form } from '../Form';
import { v4 as uuidv4 } from 'uuid';
import { FieldTextField } from '../form-fields/FieldTextField';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { FieldSelect } from '../form-fields/FieldSelect';
import { StageService } from '../../services/Stage.service';
import { FormError } from '../FormError';
import { ErrorService } from '../../services/Error.service';
import { SubmissionStageFormData } from '../../shared/types/SubmissionStageFormData';
import { BoxHeader } from '../BoxHeader';
import { DocumentationConfigurator } from '../DocumentationConfigurator';
import { stageName } from '../../shared/validators';
import { SubmissionStage } from '../../shared/types/SubmissionStage';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { RoleEnum } from '../../shared/enums/RoleEnum';

const styles = () =>
  createStyles({
    saveButton: {
      alignSelf: 'flex-end',
    },
    stageActivationError: {
      marginTop: 10,
      marginBottom: 10
    },
    documentationConfigurator: {
      marginBottom: 20
    }
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<void> & {
    hideDocumentation? : boolean;
    submissionId: string;
    stageId?: string;
    initValues?: SubmissionStageFormData;
    activeStageName?: string;
  };

const StageFormComponent: React.FunctionComponent<Props> = ({ classes, history, stageId, activeStageName, submissionId, initValues, hideDocumentation}) => {
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [stageActivationError, setStageActivationError] = useState(false);

  const { t } = useTranslator();

  const { role } = useSelector((state: RootState) => state.user);
  const path = role === RoleEnum.Admin ? 'admin' 
    : role === RoleEnum.CompetitionCoordinator ? 'competition-coordinator' 
    : '';

  const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: stageName(t),
  });

  const initialValues = initValues || {
    name: '',
    isActive: false,
    submissionId: submissionId,
    attachmentTemplates: []
  };

  const saveAttachments = async (stageId: SubmissionStage['id'], attachmentTemplates: Array<any>) => {
    const promises = [];

    for (const file of attachmentTemplates.filter((a) => a.name)) {
      const formData = new FormData();

      formData.append('name', file.name);
      formData.append('role', file.role);
      formData.append('isRequired', file.isRequired);

      file.visibleForRoles.forEach((r: RoleEnum) => formData.append('visibilityRoles', r));

      if (file.specimen) {
        formData.append('specimenFile', file.specimen);
      }

      promises.push(StageService.addTemplateFile(stageId, formData));
    }

    return Promise.all(promises);
  };

  const handleSubmit = async (values: SubmissionStageFormData, { setSubmitting }: FormikHelpers<SubmissionStageFormData>) => {
    setServerErrorMessage('');
    setStageActivationError(false);
    setSubmitting(true);

    try {
      if (stageId) {
        await StageService.updateStage(submissionId, stageId, values);
        if (values.attachmentTemplates) {
          await saveAttachments(stageId, values.attachmentTemplates);
        }

      } else {
        const newStageId = uuidv4();
        const { attachmentTemplates, ...data } = values;

        await StageService.addStage({ id: newStageId, ...data });
        if (values.attachmentTemplates) {
          await saveAttachments(newStageId, values.attachmentTemplates);
        }
      }

      history.push(`/${path}/submissions/${submissionId}`);
    } catch (error) {
      if (ErrorService.parseStatusCode(error) === 409) {
        setStageActivationError(true);
      } else {
        setServerErrorMessage(ErrorService.parseError(error));
      }
    }
    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting, setFieldValue}) => (
        <FormContent>
          <FieldTextField name={'name'} label={t('stageName')} />
          <FieldSelect
            name={'isActive'}
            label={t('status')}
            items={[
              { value: true, label: t('active') },
              { value: false, label: t('inactive') },
            ]}
          />

          {!hideDocumentation ? (
            <>
              <BoxHeader title={t('documentation')} />
              <DocumentationConfigurator className={classes.documentationConfigurator} setFieldValue={setFieldValue} />
            </>
          ) : null}

          <FormError
            className={classes.stageActivationError}
            show={Boolean(serverErrorMessage)}
            error={serverErrorMessage} />
            {stageId ?  
            <FormError
              className={classes.stageActivationError}
              show={Boolean(stageActivationError)}
              error={`${t('alreadyHasActiveStage')} - ${activeStageName}. ${t('disalbleActiveStage')} - ${initialValues.name}.`} /> :
            <FormError
              className={classes.stageActivationError}
              show={Boolean(stageActivationError)}
              error={`${t('alreadyHasActiveStage')} - ${activeStageName}.`} />}
          <Button
            className={classes.saveButton}
            type={'submit'}
            loading={isSubmitting} 
            disabled={!dirty || isSubmitting}>{stageId ? t('save') : t('addStage')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const StageForm = withStyles(styles)(withRouter(StageFormComponent));
