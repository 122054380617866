import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useLocation } from 'react-router';

import { LoginForm } from '../forms/LoginForm';
import { Box } from '../Box';
import { BoxHeader } from '../BoxHeader';
import { useTranslator } from '../../i18n/useTranslator';
import { Divider } from '../Divider';
import { Button } from '../Button';
import { UnloggedLayout } from '../layouts/UnloggedLayout';
import { Message } from '../Message';
import { HelpMessage } from '../HelpMessage';
import { Link } from '../Link';

const styles = () => createStyles({
  message: {  
    marginBottom: 20
  },
  resetPasswordLink: {
    marginTop: 10,
    alignSelf: 'end'
  }
});

type Props = WithStyles<typeof styles>;

const LoginScreenComponent: React.FunctionComponent<Props> = ({ classes }) => {
  const [infoMessage, setInfoMessage] = useState('');

  const { t } = useTranslator();
  const location = useLocation<{ confirmedEmail: boolean }>();

  const initMessages = () => {
    if (!location.state) {
      return;
    }

    if (location.state.confirmedEmail) {
      setInfoMessage('emailIsConfirmed')
    }
  }

  useEffect(initMessages, [])

  return (
    <UnloggedLayout>
      <Box>
        <BoxHeader title={t('login')} />
        {infoMessage ? (
          <Message className={classes.message} type={'INFO'}>{t(infoMessage)}</Message>
        ) : null}
        <LoginForm />

        <Link clearStylings to={'/password/reset'} className={classes.resetPasswordLink}>
          {t("forgotPassword")}
        </Link>
        
        <Divider />
        <Button to={'/registration'} fullWidth variant={'outlined'}>
          {t('register')}
        </Button>
        <HelpMessage />
      </Box>
    </UnloggedLayout>
  );
};

export const LoginScreen = withStyles(styles)(LoginScreenComponent);
