import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { CircularProgress } from './CircularProgress';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
  });

type Props = WithStyles<typeof styles> & {};

const BoxLoaderComponent: React.FunctionComponent<Props> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

export const BoxLoader = withStyles(styles)(BoxLoaderComponent);
