import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { AxiosError } from 'axios';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { SubmitSubmission } from '../../SubmitSubmission';
import { CompetitionService } from '../../../services/Competition.service';
import { Competition } from '../../../shared/types/Competition';
import { Box } from '../../Box';
import { BoxLoader } from '../../BoxLoader';
import { ErrorService } from '../../../services/Error.service';
import { Message } from '../../Message';
import { AuthorSubmissionInstructions } from '../../AuthorSubmissionInstructions';
import { SubmissionService } from '../../../services/Submissions.service';
import { Submission } from '../../../shared/types/Submission';
import { SubmissionAttachment } from '../../../shared/types/SubmissionAttachment';
import { RootState } from '../../../store/rootReducer';
import { useSelector } from 'react-redux';
import { AttachmentTemplate } from '../../../shared/types/AttachmentTemplate';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const AuthorSubmissionScreenComponent: React.FunctionComponent<Props> = ({ match }) => {
  const [competition, setCompetition] = useState<Competition>();
  const [submission, setSubmission] = useState<Submission>();
  const [submissionNotFound, setSubmissionNotFound] = useState<boolean>();
  const [attachmentTemplates, setAttachmentTemplates] = useState<Array<AttachmentTemplate>>();
  const [authorAttachments, setAuthorAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslator();
  const { role } = useSelector((state: RootState) => state.user);

  const fetchCompetition = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result: competition },
        } = await CompetitionService.getOne(match.params.id);

        setCompetition(competition);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      finally {
        setLoading(false);
      }
    })();
  };

  const fetchAttachments = async (submission: Submission) => {
    try {
      const { data: { result: authorAttachments } } = await SubmissionService.getAuthorAttachments(submission.id);

      setAuthorAttachments(authorAttachments);
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }
  };

  const fetchAutosavedSubmission = () => {
    (async () => {
      try {
        if (!competition) {
          return;
        }
        setAttachmentTemplates(undefined);

        const { data: { result: submission } } = await SubmissionService.getAutosaved(competition.id);
        
        setSubmission(submission);
        setSubmissionNotFound(false);

        await fetchAttachments(submission);
      } catch (error) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 404) {
            setSubmissionNotFound(true);
            return;
          }

          setServerError(ErrorService.parseError(error));
      }
    })();
  };

  const fetchAttachmentTemplates = () => {
    (async () => {
      try {
        if (!competition || !submissionNotFound) {
          return;
        }

        const { data: { result: attachmentTemplates } } = await CompetitionService.getAttachmentTemplates(competition.id);

        setAttachmentTemplates(attachmentTemplates.filter((template) => template.role === role));
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
    })();
  };

  useEffect(fetchCompetition, []);
  useEffect(fetchAutosavedSubmission, [competition]);
  useEffect(fetchAttachmentTemplates, [submissionNotFound]);

  return (
    <div>
      <SectionHeader title={t('submit')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box>
              {loading ? (
                <BoxLoader />
              ) : competition && submissionNotFound !== undefined && (
                  <SubmitSubmission
                    competition={competition}
                    submission={submission}
                    attachments={authorAttachments}
                    attachmentTemplates={attachmentTemplates}
                    onChanged={fetchAutosavedSubmission}
                  />
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <AuthorSubmissionInstructions />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export const AuthorSubmissionScreen = withStyles(styles)(AuthorSubmissionScreenComponent);
