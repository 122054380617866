import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { useTranslator } from '../i18n/useTranslator';
import { Submission } from '../shared/types/Submission';
import { SubmissionStage } from '../shared/types/SubmissionStage';
import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import { List } from './List';
import { ListAction } from './ListAction';
import { Box } from './Box';
import { BorderColorSharp, DeleteOutlined } from '@material-ui/icons';
import { CustomReviewerAutocomplete } from './CustomReviewerAutocomplete';
import { SuggestedReviewers } from './SuggestedReviewers';
import { BoxHeader } from './BoxHeader';
import { User } from '../shared/types/User';
import { StageVisibilityOptions } from '../shared/types/StageVisibilityOptions';
import { SubmissionStageReviewer } from '../shared/types/SubmissionStageReviewer';
import { StageService } from '../services/Stage.service';
import { ErrorService } from '../services/Error.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { BoxSection } from './BoxSection';
import red from '@material-ui/core/colors/red';
import { Message } from './Message';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { ConfirmationDialog } from './ConfirmationDialog';

const styles = () =>
  createStyles({
    error: {
      marginBottom: 10,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    saveButton: {
      alignSelf: 'flex-end',
    },
    listItem: {
      padding: 5
    },
    listHeader: {
      height: 0
    },
    box: {
      marginTop: 20,
    },
    suggestedReviewers: {
      fontSize: '1rem',
      padding: 10,
      maxWidth: 250
    },
    suggestedReviewersHeader: {
      height: 10
    },
    iconDelete: {
        fontSize: 20,
        color: red[500],
        marginLeft: 5
    }
  });

type Props = WithStyles<typeof styles> & {
  submissionId: Submission['id'];
  visibilityOptions: StageVisibilityOptions;
  reviewerStageAttachments: SubmissionAttachment[];
  onSelectedValueChanged?: (value: any) => void;
  reviewerAcceptedEvent?: (reviewerId: string) => void;
};

const SubmissionStagesComponent: React.FunctionComponent<Props> = ({
  classes,
  submissionId,
  visibilityOptions,
  reviewerStageAttachments,
  reviewerAcceptedEvent,
  onSelectedValueChanged}) => {
  const history = useHistory();

  const { t } = useTranslator();
  const { role } = useSelector((state: RootState) => state.user);
  const [blockHeader, setBlockHeader] = useState('');

  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);

  const [stages, setStages] = useState<Array<SubmissionStage>>([]);
  const [selectedStage, setSelectedStage] = useState<SubmissionStage>();
  const [selectedStageReviewers, setSelectedStageReviewers] = useState<Array<User>>([]);
  const [availableReviewers, setAvailableReviewers] = useState<Array<User>>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');

  const path = role === RoleEnum.Admin ? 'admin' 
    : role === RoleEnum.CompetitionCoordinator ? 'competition-coordinator' 
    : '';

  const setBlockHeaderValue = async () => {
    if (
      role === RoleEnum.ScientificCouncilMember ||
      role === RoleEnum.CompetitionCommitteeMember ||
      role === RoleEnum.CompetitionJuryMember
    ) {
      setBlockHeader(`${t("suggestReviewers")} - ${selectedStage?.name}`);
    }
    else if (role === RoleEnum.Admin || role === RoleEnum.CompetitionCoordinator) {
      setBlockHeader(`${t("suggestedReviewers")} - ${selectedStage?.name}`);
    }
};

  const stagesListHeader = [
    {
      key: 'selectItem',
      text: '',
    },
    {
      key: 'action',
      text: '',
      pullRight: true,
    },
  ];

  const reviewersListHeader = [{
    key: 'fullName',
    text: ''
  }, {
    key: 'action',
    text: ''
  }];

  const getStageReviewers = async () => {
    setServerError('');
    try { 
      if (visibilityOptions.reviewersVisibility?.showReviewersList) {
        const { data: { result } } = await StageService.getStageReviewers(selectedStage!.id);
        setSelectedStageReviewers(result);
      }
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }
};

  const getAvailableStageReviewers = () => {
    (async () => {
      setServerError('');

      try {
        if (visibilityOptions?.reviewersVisibility?.allowReviewersEdition) {
          const { data: { result } } = await StageService.getAvailableStageReviewers(selectedStage!.id);
          setAvailableReviewers(result);
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
    })();
  };

  const handleStageReviewerSave = async (users: Array<User>) => {
    if (selectedStage?.id) {
      for (const reviewer of users) {
        try {
          const reviewerDto: SubmissionStageReviewer = {
            reviewerId: reviewer.userId,
            submissionId: submissionId,
            stageId: selectedStage?.id,
          };
          await StageService.addStageReviewer(selectedStage?.id, reviewerDto);
          getAvailableStageReviewers();
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      }
      getStageReviewers();
    }
  };

  const deleteReviewer = async () => {
    setServerError('');

    try {
      if (selectedStage?.id && userIdToDelete){
        await StageService.deleteStageReviewer(selectedStage.id, userIdToDelete);

        getStageReviewers();
        getAvailableStageReviewers();
      }
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }
  };

  const mapRowsToReviewerListRows = () =>
    selectedStageReviewers.map((stageReviewer) => ({
      id: stageReviewer.userId,
      items: [{
        key: 'fullName',
        content: (`${stageReviewer.firstName} ${stageReviewer.lastName}`)
      }, {
        key: 'action',
        content: !reviewerStageAttachments.find(attachment => attachment.attachments.some(attach => attach.senderId === stageReviewer.userId)) &&
          <ListAction
            title={t('delete')} 
            to='#'
            onClick={() => handleDeleteReviewerClick(stageReviewer.userId)}
          >
            <DeleteOutlined className={classes.iconDelete} />
          </ListAction>
      }],
    }));

  const mapRowsToStageListRows = () => 
    stages.map((stage) => ({
      id: stage.id,
      items: [
        {
          key: 'selectItem',
          content: (<div><FormControlLabel value={stage.id} 
            control={<Radio color='primary' disabled={!!(!visibilityOptions?.stagesVisibility?.allowStagesSwitch)} />} 
          label={stage.name} /></div>)
        },
        {
          key: 'action',
          content: (visibilityOptions?.stagesVisibility?.allowStagesEdition ?
            <ListAction to={`/${path}/stages/${stage.id}`} title={t('edit')}>
                <BorderColorSharp />
            </ListAction> 
            : null
          ),
        },
      ],
    }));

  const suggestedReviewerAcceptedEventHandler = (userId: string) => {
    (async () => {
      getStageReviewers();
      getAvailableStageReviewers();
      reviewerAcceptedEvent && reviewerAcceptedEvent(userId);
    })();
  };

  const fetchStages = () => {
    (async () => {
      setLoading(true);
      setServerError('');

      try {
        const { data: { result } } = await StageService.getStages(submissionId);
        setStages(result);

        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      setLoading(false);
    })();
  };

  const onUpdate = async (selectedKey: string) => {
      const stage = stages.find(x => x.id === selectedKey);
      setSelectedStage(stage);
      onSelectedValueChanged && onSelectedValueChanged(stage);
  };

  useEffect(() => {
    getStageReviewers();
    getAvailableStageReviewers();
    setBlockHeaderValue();
  }, [selectedStage]);
    
  useEffect(() => {
    const activeStage = stages.find(x => x.isActive);
    if(activeStage) {
      onUpdate(activeStage.id);
      setSelectedStage(activeStage);
    }
  }, [stages]);

  const init = () => {
    fetchStages();
  };
  useEffect(init, []);

  const handleDeleteReviewerClick = (userId: string) => {
    setIsConfirmDialogOpen(true);
    setUserIdToDelete(userId);
  };

  const handleCloseConfirmationDialog = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && await deleteReviewer();
      setUserIdToDelete('');
    })()
  };

  return (
    <>
      <BoxSection title={t("stages")}>
        <form className={classes.form}>
          <RadioGroup
            value={selectedStage ? selectedStage.id : ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if(visibilityOptions.stagesVisibility?.allowStagesSwitch) {
                onUpdate(e.target.value);
              }
            }}
          >
            {stages.length ? (
              <List
                headerCells={stagesListHeader}
                rows={mapRowsToStageListRows()}
                className={classes.listItem}
                headerClassName={classes.listHeader}
              />
            ) : (
              t("noneStages")
            )}
          </RadioGroup>

          {visibilityOptions?.stagesVisibility?.allowStagesEdition && (
            <Button
              className={classes.saveButton}
              onClick={() => {
                history.push(`/${path}/stages/add`, { submissionId });
              }}
            >
              {t("addStage")}
            </Button>
          )}
        </form>

        {visibilityOptions.reviewersVisibility &&
        (role === RoleEnum.ScientificCouncilMember ||
        role === RoleEnum.CompetitionCommitteeMember ||
        role === RoleEnum.CompetitionJuryMember ||
        role === RoleEnum.Admin ||
        role === RoleEnum.CompetitionCoordinator ||
        role === RoleEnum.BoardMember) && (
          <Box className={classes.box}>
            {selectedStage?.name ? (
              <BoxHeader title={`${t("reviewers")} - ${selectedStage?.name}`} />
            ) : (
              <BoxHeader title={t("reviewers")} />
            )}

            {visibilityOptions.reviewersVisibility.allowReviewersEdition && selectedStage?.name && (
              <CustomReviewerAutocomplete
                options={availableReviewers}
                onSaveHandler={handleStageReviewerSave}
              />
            )}

            {visibilityOptions.reviewersVisibility.showReviewersList && selectedStageReviewers.length ? (
              <List
                headerCells={reviewersListHeader}
                headerClassName={classes.suggestedReviewersHeader}
                className={classes.suggestedReviewers}
                rows={mapRowsToReviewerListRows()}
              />
            ) : (
              t("none")
            )}
          </Box>
        )}

        {visibilityOptions.suggestedReviewersVisibility && (
          <Box className={classes.box}>
            {visibilityOptions.suggestedReviewersVisibility && selectedStage?.id ? (
              <SuggestedReviewers
                entityId={selectedStage.id}
                suggestedReviewersVisibilityOpt={visibilityOptions.suggestedReviewersVisibility}
                acceptSuggestedReviewerHandler={
                  StageService.acceptSuggestedReviewer
                }
                addSuggestedReviewerHandler={StageService.addSuggestedReviewer}
                reviewerAcceptedEvent={suggestedReviewerAcceptedEventHandler}
                removeSuggestedReviewerHandler={
                  StageService.removeSuggestedReviewer
                }
                fetchAssignedSuggestedReviewersHandler={
                  StageService.getSuggestedReviewers
                }
                fetchAvailableSuggestedReviewersHandler={
                  StageService.getAvailableSuggestedReviewers
                }
                blockHeaderText={blockHeader}
                listHeaderText={t('suggestedReviewers')}
              />
            ) : (
              t("none")
            )}
          </Box>
        )}

        {serverError && (
          <Message type={'ERROR'}>{t(serverError)}</Message>
        )}
      </BoxSection>

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={(confirmed) => handleCloseConfirmationDialog(confirmed)}
        dialogTitle={t('confirmReviewerDeletionFromStage')}
      />
    </>
  );
};

export const SubmissionStages = withStyles(styles)(SubmissionStagesComponent);
