import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { CompetitionRankingListWithTabs } from '../../CompetitionRankingListWithTabs';
import { RouteComponentProps } from 'react-router';
import { CompetitionService } from '../../../services/Competition.service';
import { Competition } from '../../../shared/types/Competition';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    id: string;
  }>;

const CompetitionRankingListScreenComponent: React.FunctionComponent<Props> = ({ match }) => {
  const [competition, setCompetition] = useState<Competition>();
  const { t } = useTranslator();

  const fetchCompetition = () => {
    (async () => {
      const {
        data: { result },
      } = await CompetitionService.getOne(match.params.id);

      setCompetition(result);
    })();
  };
  
  useEffect(fetchCompetition, []);

  const competitionName = competition?.name ? ` (${competition.name})` : '';

  return (
    <div>
      <SectionHeader title={`${t('rankingList')}${competitionName}`} />
      <CompetitionRankingListWithTabs />
    </div>
  );
};

export const CompetitionRankingListScreen = withStyles(styles)(CompetitionRankingListScreenComponent);
