import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },
  });

type Props = WithStyles<typeof styles> & {
  headerClassName?: string;
};

const ListHeaderComponent: React.FunctionComponent<Props> = ({ headerClassName, classes, children }) => {
  return <div className={classNames(
    classes.root,
    headerClassName
  )}>{children}</div>;
};

export const ListHeader = withStyles(styles)(ListHeaderComponent);
