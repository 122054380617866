import { ActionCreatorsMapObject } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from './rootReducer';
import { UserActions } from './user/actions';

export type Actions = UserActions;

export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export type ActionsUnion<T extends ActionCreatorsMapObject> = ReturnType<T[keyof T]>;
export type ActionByType<TActionUnion, TActionType> = TActionUnion extends {
  type: TActionType;
}
  ? TActionUnion
  : never;

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Actions>;
