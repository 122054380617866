import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { ListRow } from '../components/ListRow';
import { ListItem } from '../components/ListItem';
import { ListHeader } from '../components/ListHeader';
import { ListHeaderItem } from '../components/ListHeaderItem';
import { ListRow as ListRowType } from '../shared/types/ListRow';

const styles = () => createStyles({});

type ListHeaderItem = {
  key: string;
  text: string;
  pullRight?: boolean;
};

type Props = WithStyles<typeof styles> & {
  rows: Array<ListRowType>;
  headerCells?: Array<ListHeaderItem>;
  className?: string;
  headerClassName?: string;
};

const ListComponent: React.FunctionComponent<Props> = ({ rows, headerCells, className, headerClassName }) => {
  return (
    <div>
      <ListHeader headerClassName={headerClassName}>
        {headerCells?.map((cell) => (
          <ListHeaderItem key={cell.key}>{cell.text}</ListHeaderItem>
        ))}
      </ListHeader>
      <div>
        {rows.map((row) => (
          <ListRow key={row.id}>
            {row.items.map((item) => {
              const headerCellConfig = headerCells?.find((cell) => cell.key === item.key);

              return (
                <ListItem key={item.key} className={className} pullRight={headerCellConfig?.pullRight}>
                  {item.content}
                </ListItem>
              );
            })}
          </ListRow>
        ))}
      </div>
    </div>
  );
};

export const List = withStyles(styles)(ListComponent);
