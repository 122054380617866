import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { Box } from './Box';
import { BoxHeader } from './BoxHeader';
import { useTranslator } from '../i18n/useTranslator';

const styles = () =>
  createStyles({
    root: {
      marginBottom: 20,
    },
  });

type Props = WithStyles<typeof styles> & {};

const ReviewerSubmissionInstructionsComponent: React.FunctionComponent<Props> = ({ classes }) => {
  const { t } = useTranslator();

  return (
    <Box className={classes.root}>
      <BoxHeader title={t('instructions')} />
      <ol>
        <li>Zapoznaj się z dokumentacją uczestnika</li>        
        <li>Oceń wniosek</li>
        <li>Pobierz szablony dokumentów dla recenzenta i zapisz je u siebie na dysku</li>
        <li>Uzupełnij szablony</li>
        <li>Załącz do zgłoszenia skany wypełnionych dokumentów</li>
      </ol>
      <div>
        {t('iInCaseOfHelp')} <a href="mailto:kontakt.fundacja@polpharma.com">kontakt.fundacja@polpharma.com</a>
      </div>
    </Box>
  );
};

export const ReviewerSubmissionInstructions = withStyles(styles)(ReviewerSubmissionInstructionsComponent);
