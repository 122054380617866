import { Translation } from '../Translation.type';

export const plTranslation = {
  login: 'Logowanie',
  signIn: 'Zaloguj się',
  registration: 'Rejestracja',
  name: 'Imię',
  surname: 'Nazwisko',
  email: 'Email',
  resetPasswordMessage1: 'Wysłaliśmy wiadomość na adres',
  resetPasswordMessage2: 'Kliknij w link aby zmienić hasło. Nie otrzymałeś wiadomości? Sprawdź folder SPAM.',
  linkSent: 'Wiadomość została wysłana.',  
  password: 'Hasło',
  forgotPassword: 'Zapomniałeś hasło?',
  resetPassword: 'Zresetuj hasło',
  register: 'Zarejestruj się',
  scienceUnit: 'Nazwa jednostki naukowej',
  phone: 'Numer telefonu',
  iAcceptTermsTitle: 'Zaakceptuj klauzule o danych osobowych',
  iAcceptTerms: 'Akceptuję klauzulę o danych osobowych',
  iTerms: 'Klauzula o danych osobowych',
  iInCaseOfHelp: 'W razie pytań prosimy o kontakt:',
  fieldIsRequired: 'Wypełnij to pole',
  wrongEmailAddress: 'Nieprawidłowy adres email',
  incorrectNumber: 'Wprowadź poprawną liczbę bez spacji i znaków specjalnych',
  participant: 'Uczestnik',
  reviewer: 'Recenzent',
  reviewerSpecialization: 'Specjalizacja recenzenta',
  specialty: 'Specjalizacja',
  maximumFieldLength: 'Podana wartość jest zbyt długa',
  youMustAgreeToTerms: 'Musisz zaakceptować klauzulę o danych osobowych',
  passwordTooShort: 'Hasło powinno zawierać conajmniej 6 znaków',
  passwordTooLong: 'Hasło powinno zawierać maksymalnie 12 znaków',
  fullName: 'Imię i nazwisko',
  role: 'Rola',
  users: 'Użytkownicy',
  addUser: 'Dodaj użytkownika',
  userDetails: 'Szczegóły użytkownika',
  scientificCouncil: 'Członek rady naukowej',
  management: 'Członek zarządu',
  admin: 'Administrator',
  competitionCoordinator: 'Koordynator konkursu',
  competitionCommitteeMember: 'Członek komisji konkursowej',
  competitionJuryMember: 'Członek kapituły konkursu',
  logout: 'Wyloguj się',
  competitions: 'Konkursy',
  startDate: 'Data rozpoczęcia',
  endDate: 'Data zakończenia',
  status: 'Status',
  competitionType: 'Rodzaj konkursu',
  competitionDetails: 'Szczegóły konkursu',
  addCompetition: 'Dodaj konkurs',
  invalidPassword: 'Hasło powinno zawierać conajmniej 6 znaków, jedną wielką literę, cyfrę oraz znak specjalny',
  serverError: 'Wystąpił nieoczekiwany błąd, spróbuj ponownie lub skontaktuj się z administratorem',
  registrationSuccess: 'Konto założone pomyślnie',
  activateYourAccount: 'Na podany adres email wysłaliśmy link aktywacyjny. Kliknij w link, aby aktywować konto.',
  startStatus: 'Status początkowy',
  active: 'Aktywny',
  inactive: 'Nieaktywny',
  researchProjectCompetition: 'Konkurs na Projekt Badawczy',
  scholarshipProgram: 'Program Stypendialny',
  mobileTechnics: 'Konkurs na Techniki Mobilne',
  alreadyHasActiveStage: 'To zgłoszenie ma aktywny etap',
  disalbleActiveStage: 'Dezaktywuj aktywny etap przed aktywacją etapu',
  competitionName0: '???',
  competitionName1: 'Konkurs na Projekt Badawczy',
  competitionName2: 'Program Stypendialny',
  competitionName3: 'Konkurs na Techniki Mobilne',
  endDateShouldBeAfterStartDate: 'Data zakończenia powinna być późniejsza niż data rozpoczęcia',
  submissions: 'Zgłoszenia',
  submissionName: 'Nazwa konkursu',
  submissionDate: 'Data zgłoszenia',
  submissionDetails: 'Szczegóły zgłoszenia',
  submission: 'Zgłoszenie',
  adminActions: 'Akcje administratora',
  timeline: 'Szczegóły procesu',
  createDate: 'Data utworzenia',
  firstName: 'Imię',
  lastName: 'Nazwisko',
  documentation: 'Dokumentacja',
  noReviewers: 'Brak recenzentów do wyboru',
  author: 'Uczestnik',
  Author: 'Uczestnik',
  Reviewer: 'Recenzent',
  Administrator: 'Administrator',
  'Finance Coordinator': 'Koordynator finansowy',
  'Scientific Council Member': 'Członek Rady Naukowej',
  'Board Member': 'Członek Zarządu',
  'Competition Coordinator': 'Koordynator Konkursu',
  'Competition Committee Member': 'Członek Komisji Konkursowej',
  'Competition Jury Member': 'Członek Kapituły Konkursu',
  downloadDocument: '',
  competitionName: 'Nazwa konkursu',
  financeCoordinator: 'Koordynator finansowy',
  emailTemplates: 'Szablony email',
  templateTitle: 'Nazwa szablonu',
  templateDetails: 'Szczegóły szablonu',
  save: 'Zapisz',
  templateBody: 'Zawartość szablonu',
  profile: 'Profil',
  oldPassword: 'Stare hasło',
  newPassword: 'Nowe hasło',
  repeatNewPassword: 'Powtórz nowe hasło',
  changePassword: 'Zmień hasło',
  passwordMustMatch: 'Podane hasła różnią się od siebie',
  passwordChangeSuccess: 'Hasło zostało pomyślnie zmienione',
  passwordChangeError: 'Błąd podczas próby zmiany hasła',
  data: 'Dane',
  resetUserPassword: 'Zresetuj hasło użytkownika',
  passwordResetSuccess: 'Hasło zresetowane pomyślnie',
  generalInfo: 'Informacje ogólne',
  addDocument: 'Dodaj dokument',
  noDocumentsAdded: 'Brak dodanych dokumentów',
  templateFile: 'Wzór dokumentu',
  documentName: 'Nazwa dokumentu',
  removeDocument: 'Usuń dokument',
  noCompetitionsFound: 'Brak konkursów',
  details: 'Szczegóły',
  submit: 'Wyślij zgłoszenie',
  document: 'Dokument',
  sendSubmission: 'Wyślij zgłoszenie',
  chooseFile: 'Wybierz plik',
  downloadTemplate: 'Szablon',
  deleteDocument: 'Usuń dokument',
  cancel: 'Anuluj',
  edit: 'Edytuj',
  reviewerDocument: 'Dokument recenzenta',
  authorDocument: 'Dokument uczestnika',
  fillDocument: 'Uzupełnij dokument',
  reviewers: 'Recenzenci',
  suggestedReviewers: 'Wskazani recenzenci',
  suggestReviewers: 'Wskazanie recenzentów',
  removeSuggestedReviewer: 'Usuń wskazanego recenzenta',
  addReviewer: 'Dodaj recenzenta',
  pickReviewer: 'Wybierz recenzenta',
  activeSubmissions: 'Aktywne zgłoszenia',
  archiveSubmissions: 'Archiwalne zgłoszenia',
  none: 'Brak',
  noAuthorDocumentation: 'Brak dokumentacji uczestnika',
  noReviewerDocumentation: 'Brak dokumentacji recenzenta',
  rankingList: 'Lista rankingowa',
  template: 'Wzór',
  reviewSubmission: 'Oceń zgłoszenie',
  mark: 'Oceń',
  markRequired: 'Uzupełnij ocenę',
  authorName: 'Imię i nazwisko autora',
  generalMark: 'Ocena ogólna',
  discussion: 'Dyskusja',
  comment: 'Komentarz',
  addComment: 'Dodaj komentarz',
  commentDetails: 'Treść komentarza',
  notes: 'Notatki',
  addNote: 'Dodaj notatkę',
  noteDetails: 'Treść notatki',
  attachedReviewers: 'Przypisani recenzenci',
  markedSubmissions: 'Ocenione wnioski',
  submissionsInprogress: 'Wnioski w trakcie oceny',
  activeCompetitions: 'Aktywne konkursy',
  usersCompetitions: 'Konkursy dla użytkownika',
  authorsCompetitions: 'Konkursy dla uczestnika',
  reviewersCompetitions: 'Konkursy dla recenzenta',
  alreadyParticipate: 'Zgłoszenie wysłane',
  updateSpecialty: 'Zaktualizuj specjalność recenzenta',
  instructions: 'Instrukcja',
  alreadyMarkedSubmission: 'Już oceniłeś to zgłoszenie',
  updateSpecialtySuccess: 'Specjalność recenzenta zaktualizowana',
  acceptSubmission: 'Zaakceptuj zgłoszenie',
  submissionMark: 'Ocena zgłoszenia',
  noMarks: 'Brak ocen',
  mySubmission: 'Moje zgłoszenie',
  mySubmissions: 'Moje zgłoszenia',
  toAcceptSubmissions: 'Do akceptacji',
  rejectedSubmissions: 'Odrzucone',
  toPickReviewersSubmissions: 'Bez recenzentów',
  markInProgressSubmissions: 'W trakcie oceny',
  grantInplementation: 'Realizacja grantu',
  rejectSubmission: 'Odrzuć zgłoszenie',
  choiceIsRequired: 'Wybierz jedną z opcji',
  submissionWasRejected: 'Zgłoszenie zostało odrzucone',
  marksCount: 'Liczba ocen',
  markSaved: 'Ocena zapisana pomyślnie',
  marked: 'Ocenione',
  backToRankingList: 'Powrót do listy rankingowej',
  backToUserList: 'Powrót do listy użytkowników',
  backToSubmission: 'Powrót do zgłoszenia',
  authorDocumentation: 'Dokumentacja uczestnika',
  reviewerDocumentation: 'Dokumentacja recenzenta',
  downloadUploadedFile: 'Pobierz aktualny plik',
  delete: 'Usuń',
  markAsWon: 'Przyznaj grant',
  closeSubmission: 'Zamknij zgłoszenie',
  submissionDraft: 'Do akceptacji',
  submissionInvalid: 'Nieprawidłowe',
  submissionNew: 'Przypisywanie recenzentów',
  submissionReview: 'W trakcie oceny',
  submissionWon: 'Nagrodzone',
  submissionRejected: 'Odrzucone',
  submissionClosed: 'Zamknięte',
  emailConfirmation: 'Potwierdzenie adresu email',
  inputEmailAddress: 'Wpisz adres e-mail',
  resetPasswordScreenMessage: 'Wpisz e-mail, na który wyślemy wiadomość z linkiem do zmiany hasła.',
  errorConfirmEmail: 'Nie mogliśmy potwierdzić adresu email. Spróbuj ponownie lub skontaktuj się z administratorem.',
  emailIsConfirmed: 'Adres email został potwierdzony. Możesz się teraz zalogować.',
  projectName: 'Tytuł projektu',
  unitInfo: 'Nazwa i adres jednostki, w której będzie realizowany projekt',
  estimatedFinishTime: 'Planowany czas realizacji (miesiące)',
  estimatedFunds: 'Planowane nakłady (zł)',
  academicTitle: 'Tytuł i stopień naukowy',
  prizeCandidate: 'Kandydat do nagrody',
  candidateUnitInfo: 'Nazwa i adres jednostki, w której pracuje kandydat',
  candidateAdditionalDocumentation: 'Dodatkowa dokumentacja (np. publikacja kandydata)',
  requiredConsentLabel: 'Treść wymaganej zgody',
  defaultRequiredConsent: `Zgłoszenia do konkursu mogą dotyczyć wyłącznie osób: \n- ze stopniem naukowym doktora \n- z ważnym osiągnięciem badawczym \n- niezatrudnionych w przemyśle farmaceutycznym \nPrzez zatrudnienie rozumie się powołanie w skład organów podmiotów gospodarczych sektora farmaceutycznego lub zatrudnienie w podmiotach tego sektora na podstawie umowy o pracę.`,
  consentIsRequired: 'Zgoda jest wymagana',
  send: 'Wyślij',
  submissionNumber: 'Numer zgłoszenia',
  isRequired: 'Wymagany',
  notRequired: 'Niewymagany',
  requiredInfo: '(*) - wymagane pole',
  noStagesDefined: 'Brak etapów',
  stage: 'Etap',
  stages: 'Etapy',
  noneStages: 'Brak etapów',
  addStage: 'Dodaj etap',
  stageName: 'Nazwa etapu',
  stageDetails: 'Szczegóły etapu',
  anyFile: 'Dodatkowa dokumentacja',
  multiFileInputLabelStart: 'Przeciągnij tutaj pliki lub',
  multiFileInputLabelClickHere: 'kliknij tutaj',
  multiFileInputLabelEnd: 'aby wybrać pliki.',
  invalidFileExtension: 'Plik jest niepoprawny. Dozwolone rozszerzenia plików to:',
  allowedExtensions: '.xlsx, .xls, .doc, .docx, .ppt, .pptx, .pdf',
  attachmentTemplateAllowedExtensions: '.doc, .docx',
  documentRequiredInfo: '(*) - dokument wymagany',
  visibleForRoles: 'Podgląd dla roli',
  search: 'Szukaj',
  all: 'Wszystkie',
  clearFilters: 'Wyczyść filtry',
  reports: 'Raporty',
  reportName: 'Nazwa raportu',
  edition: 'Edycja',
  competitionCategory: 'Kategoria konkursu',
  competitionCategoryProject: 'Projektowa',
  competitionCategoryPerson: 'Osobowa',
  numberOfSentSubmissions: 'Liczba nadesłanych projektów',
  numberOfSubmissionsWithGrants: 'Liczba przyznanych grantów',
  numberOfClosedSubmissions: 'Liczba zakończonych projektów',
  sumOfCosts: 'Koszt całkowity  w PLN',
  foundationInNumbers: 'Fundacja w liczbach',
  suggestedBy: 'Wskazany przez',
  sendDocuments: 'Wyślij dokumenty',
  changeDocument: 'Zmień dokument',
  restoreSubmission: 'Przywróć zgłoszenie',
  confirmAttachmentTemplateDeletion: 'Czy na pewno chcesz usunąć dokument?',
  confirmSubmissionRestore: 'Czy na pewno chcesz przywrócić zgłoszenie?',
  confirmReviewerDeletionFromSubmission: 'Czy na pewno chcesz usunąć recenzenta ze zgłoszenia?',
  confirmReviewerDeletionFromStage: 'Czy na pewno chcesz usunąć recenzenta z etapu?',
  yes: 'Tak',
  no: 'Nie',
  noCompetitionCoordinatorsToChoose: 'Brak koordynatorów konkursu do wyboru',
  pickCompetitionCoordinator: 'Wybierz koordynatora konkursu',
  competitionCoordinators: 'Koordynatorzy konkursu',
  noCompetitionCoordinatorsSelected: 'Brak wybranych koordynatorów konkursu',
  billingMethod: 'Sposób rozliczania',
  billingMethodPerReview: 'Jedna umowa i rachunek na recenzję',
  billingMethodPerCompetition: 'Jedna umowa i rachunek na konkurs',
  duplicatedFileInfo: 'Plik "{0}" został załączony {1} razy (w polach {2})',
  documentForAllReviews: 'Dokument wspólny dla wszystkich recenzji',
  confirmChangeDocumentForAllReviews: 'Próbujesz zamienić dokument wspólny dla wszystkich recenzji danego recenzenta. Czy na pewno chcesz zamienić dokument?',
  confirmDeleteDocumentFromAllReviews: 'Czy na pewno chcesz usunąć dokument ze wszystkich recenzji?',
  billingPerCompetitionInfo: 'Ten konkurs rozliczany jest jedną umową i rachunkiem. Dokumenty oznaczone jako "DOKUMENT WSPÓLNY DLA WSZYSTKICH RECENZJI" wystarczy załączyć tylko na jednym zgłoszeniu',
  acceptRequiredConsentLabel: 'Akceptuję treść poniższej zgody',
  searchPhrase: 'Szukana fraza',
  deleteUser: 'Usuń użytkownika',
  restoreUser: 'Przywróć użytkownika',
  confirmUserDeletion: 'Czy na pewno chcesz usunąć użytkownika?',
  deleted: 'Usunięty',
  atLeastOneDocumentIsForAllReviews: 'Co najmniej jeden dokument jest wspólny dla wszystkich recenzji'
};

export const pl: Translation = {
  translation: plTranslation,
};