import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';

import { useTranslator } from '../../../i18n/useTranslator';
import { SubmissionService } from '../../../services/Submissions.service';
import { ErrorService } from '../../../services/Error.service';
import { SimpleSubmission } from '../../../shared/types/SimpleSubmission';
import { SectionHeader } from '../../SectionHeader';
import { Message } from '../../Message';
import { Box } from '../../Box';
import { BoxLoader } from '../../BoxLoader';
import { List } from '../../List';
import { Link } from '../../Link';
import { DateService } from '../../../services/Date.service';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const ReviewerArchivedSubmissionsScreenComponent: React.FunctionComponent<Props> = () => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState<Array<SimpleSubmission>>([]);
  const { t } = useTranslator();

  const listHeader = [
    {
      key: 'submissionName',
      text: t('submissionName'),
    },
    {
      key: 'author',
      text: t('author'),
    },
    {
      key: 'createdAt',
      text: t('submissionDate'),
    },
  ];

  const mapRowsToListRows = () =>
    submissions.map((submission) => ({
      id: submission.id,
      items: [
        {
          key: 'submissionName',
          content: <Link to={`/reviewer/submissions/${submission.id}`}>{submission.competitionName}</Link>,
        },
        {
          key: 'author',
          content: `${submission.author.firstName} ${submission.author.lastName}`,
        },
        {
          key: 'createdAt',
          content: moment(submission.createdAt).format(DateService.DATE_FORMAT),
        },
      ],
    }));

  const fetchSubmissions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result },
        } = await SubmissionService.getArchived();

        setSubmissions(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      setLoading(false);
    })();
  };

  useEffect(fetchSubmissions, []);

  return (
    <div>
      <SectionHeader title={t('archiveSubmissions')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Box>
          {loading ? (
            <BoxLoader />
          ) : submissions.length ? (
            <List headerCells={listHeader} rows={mapRowsToListRows()} />
          ) : (
            <div>{t('none')}</div>
          )}
        </Box>
      )}
    </div>
  );
};

export const ReviewerArchivedSubmissionsScreen = withStyles(styles)(ReviewerArchivedSubmissionsScreenComponent);
