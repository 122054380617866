import React from 'react';
import { createStyles, WithStyles, withStyles, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { Select as MaterialSelect, SelectProps as MaterialSelectProps } from 'formik-material-ui';
import { ErrorMessage } from 'formik';

const styles = () => createStyles({
  error: {
    color: '#f44336',
    margin: '-16px 14px 20px 14px',
    fontSize: '0.75rem'
  },
  menuItemError: {
    color: '#f44336'
  }
});

export type SelectProps = Omit<MaterialSelectProps, 'error'> & {
  fieldName: string;
  label: string;
  items: Array<{
    label: string;
    value: string;
  }>;
  error?: string;
};

type Props = SelectProps & WithStyles<typeof styles>;

const FormikSelectComponent: React.FunctionComponent<Props> = ({
  classes,
  fieldName,
  label,
  items,
  error,
  ...restProps
}) => {
  return (
    <FormControl variant={'outlined'} error={Boolean(error)} className={error ? classes.menuItemError : ''}>
      <InputLabel htmlFor={fieldName}>{label}</InputLabel>
      <MaterialSelect label={label} {...restProps}>
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <em>{item.label}</em>
          </MenuItem>
        ))}
      </MaterialSelect>

      <ErrorMessage
        name={fieldName}
        component={"p"}
        className={classes.error}
      />
    </FormControl>
  );
};

export const FormikSelect = withStyles(styles)(FormikSelectComponent);
