import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { Autocomplete } from './inputs/Autocomplete';
import { useTranslator } from '../i18n/useTranslator';
import { TextField } from './inputs/TextField';
import { User } from '../shared/types/User';
import { Button } from './Button';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    saveButton: {
      alignSelf: 'flex-end',
      marginTop: 10,
    }
  });

type Props = WithStyles<typeof styles> & {
  options: Array<User>;
  onSaveHandler: (users: Array<User>) => void;
};

const CustomReviewerAutocompleteComponent: React.FunctionComponent<Props> = ({ classes, options, onSaveHandler }) => {
  const [reviewersToSave, setReviewersToSave] = useState<Array<User>>([]);
  const [saving, setSaving] = useState(false);

  const { t } = useTranslator();

  const handleChange = async (event: React.ChangeEvent<{}>, value: Array<User>) => {
    setReviewersToSave(value);
  };

  const handleSaveAction = async () => {
    setSaving(true);
    onSaveHandler(reviewersToSave);
    setReviewersToSave([]);
    setSaving(false);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        options={options}
        noOptionsText={t('noReviewers')}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        renderOption={(option) => (
          <>
            <span>
              {option.firstName} {option.lastName}
            </span>
          </>
        )}
        renderInput={(params) => <TextField {...params} label={t('pickReviewer')} />}
        disabled={saving}
        value={reviewersToSave}
        onChange={handleChange}
      />
      <Button className={classes.saveButton} disabled={saving || !reviewersToSave.length} onClick={handleSaveAction}>
        {t('save')}
      </Button>
    </div>
  );
};

export const CustomReviewerAutocomplete = withStyles(styles)(CustomReviewerAutocompleteComponent);
