import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const styles = ({ custom, palette }: Theme) =>
  createStyles({
    root: {
      backgroundColor: palette.success.light,
      color: palette.success.contrastText,
      padding: '10px 20px',
      borderRadius: custom.radius.r_0,
      marginBottom: 20,
    },
  });

type Props = WithStyles<typeof styles> & {
  message: string;
  show: boolean;
  className?: string;
};

const FormSuccessComponent: React.FunctionComponent<Props> = ({ classes, className, message, show }) => {
  return show ? <div className={classNames(classes.root, className)}>{message}</div> : null;
};

export const FormSuccess = withStyles(styles)(FormSuccessComponent);
