import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Form } from 'formik';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& .form-control': {
        marginBottom: 20,
      },
    },
  });

type Props = WithStyles<typeof styles>;

const FormContentComponent: React.FunctionComponent<Props> = ({ children, classes }) => {
  return <Form className={classes.root}>{children}</Form>;
};

export const FormContent = withStyles(styles)(FormContentComponent);
