import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { UnloggedLayout } from '../layouts/UnloggedLayout';
import { Box } from '../Box';
import { BoxHeader } from '../BoxHeader';
import { useTranslator } from '../../i18n/useTranslator';
import { HelpMessage } from '../HelpMessage';
import { CreatePasswordForm } from '../forms/CreatePasswordForm';
import { ErrorService } from '../../services/Error.service';
import { useLocation } from 'react-router';
import qs from 'query-string'

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const CreatePasswordScreenComponent: React.FunctionComponent<Props> = () => {
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState('');
  const [, setError] = useState('');

  const location = useLocation();
  const { t } = useTranslator();

  const retrieveToken = () => {
    (async () => {
      try {
        const query = qs.parse(location.search, { decode: false })
        const code = query.code as string
        setToken(code);
        const userId = query.id as string
        setUserId(userId);
      } catch (error) {
        setError(ErrorService.parseError(error))
      }
    })()
  }

  useEffect(retrieveToken, [])

  return (
    <UnloggedLayout>
      <Box>
        <BoxHeader title={t('resetPassword')} />
        <CreatePasswordForm token={token} userId={userId}/>
        <HelpMessage />
      </Box>
    </UnloggedLayout>
  );
};

export const CreatePasswordScreen = withStyles(styles)(CreatePasswordScreenComponent);