import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { Box } from '../../Box';
import { List } from '../../List';
import { Link } from '../../Link';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AdminReportsScreenComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  const listHeader = [
    {
      key: 'name',
      text: t('reportName'),
    },
  ];

  return (
    <div>
      <SectionHeader title={t('reports')} />
      <Box><List headerCells={listHeader} rows={
        [{
          id: '0',
          items: [
            {
              key: 'title',
              content: <Link to={`/admin/reports/foundation-in-numbers`}>{t('foundationInNumbers')}</Link>,
            }
          ],
        }]
      } /></Box>
    </div>
  );
};

export const AdminReportsScreen = withStyles(styles)(AdminReportsScreenComponent);
