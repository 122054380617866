import React, { useEffect } from 'react';
import { Route, RouteProps, withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/rootReducer';
import { RoleEnum } from '../../shared/enums/RoleEnum';

type Props = RouteProps & RouteComponentProps<void>;

const CompetitionJuryMemberRouteComponent: React.FunctionComponent<Props> = ({ component: Component, history, ...restProps }) => {
  const { role } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (role !== RoleEnum.CompetitionJuryMember) {
      history.push('/');
    }
  });

  return <Route {...restProps} render={(props) => (Component ? <Component {...props} /> : null)} />;
};

export const CompetitionJuryMemberRoute = withRouter(CompetitionJuryMemberRouteComponent);
