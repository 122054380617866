import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import MaterialAutocomplete, { AutocompleteProps as MaterialAutocompleteProps } from '@material-ui/lab/Autocomplete';

const styles = () =>
  createStyles({
    root: {},
  });

type Props = WithStyles<typeof styles> & MaterialAutocompleteProps<any, boolean, boolean, boolean>;

const AutocompleteComponent: React.FunctionComponent<Props> = ({ ...restProps }) => {
  return <MaterialAutocomplete {...restProps} />;
};

export const Autocomplete = withStyles(styles)(AutocompleteComponent);
