import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { Box } from './Box';
import { BoxHeader } from './BoxHeader';
import { useTranslator } from '../i18n/useTranslator';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & {};

const SubmissionInstructionsComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <Box>
      <BoxHeader title={t('instructions')} />
      <ol>
        <li>Przejrzyj dokumentację zgłaszającego</li>
        <li>Wpisz w dyskusji sugestie odnośnie recezentów</li>
      </ol>
    </Box>
  );
};

export const SubmissionInstructions = withStyles(styles)(SubmissionInstructionsComponent);
