export enum NotificationTypeEnum {
  Invalid = 0,
  UserRegistered = 1,
  UserGenerated = 2,
  UserPasswordChanged = 3,
  UserPasswordReset = 4,
  EmailConfirmationCodeGenerated = 5,
  CommentAdded = 6,
  SubmissionCreated = 7,
  SubmissionRejected = 8,
  SubmissionAccepted = 9,
  SubmissionClosed = 10,
  SubmissionReviewerAssigned = 11,
  SubmissionCreatedUser = 12,
  OtherAttachmentAdded = 13,
  UserPasswordResetByEmail = 14
}
