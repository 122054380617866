import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, LinearProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { Dashboard } from './layouts/Dashboard';
import { UserService } from '../services/User.service';
import { UserActions } from '../store/user/actions';
import { RODOAcceptanceDialog } from './RODOAcceptanceDialog';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const DashboardScreenWrapperComponent: React.FunctionComponent<Props> = ({ children, history, classes }) => {
  const [loading, setLoading] = useState(true);
  const [acceptedRODO, setAcceptedRODO] = useState(false);

  const dispatch = useDispatch();

  const initUser = () => {
    (async () => {
      try {
        const {
          data: { result },
        } = await UserService.getMe();

        setAcceptedRODO(result.acceptedRODO);

        dispatch(UserActions.setUser(result));

        setLoading(false);
      } catch (error) {
        history.push('/login');
      }
    })();
  };

  useEffect(initUser, []);

  return loading ? <LinearProgress /> :
                    <Dashboard>
                      <RODOAcceptanceDialog acceptedRODO={acceptedRODO}></RODOAcceptanceDialog>
                      {children}
                    </Dashboard>;
};

export const DashboardScreenWrapper = withStyles(styles)(withRouter(DashboardScreenWrapperComponent));
