import React from 'react';
import { createStyles, WithStyles, withStyles, FormControl, Typography } from '@material-ui/core';
import { CheckboxWithLabel, CheckboxWithLabelProps as FormikMaterialCheckboxWithLabelProps } from 'formik-material-ui';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import { Divider } from '../Divider';

const styles = () => createStyles({
  checkboxWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    marginBottom: 20,
    padding: '6px 13px',
    '& :first-child': {
      marginBottom: 0
    }
  },
  checkboxWrapperError: {
    color: '#f44336',
    borderColor: '#f44336',
    '& span': {
      color: '#f44336'
    }
  },
  checkboxErrorLabel: {
    color: '#f44336',
    margin: '-16px 14px 20px 14px',
    fontSize: '0.75rem'
  },
  additionalText: {
    fontFamily: 'inherit',
    whiteSpace: 'pre-wrap',
    marginBottom: '10px !important'
  },
  additionalTextDisabled: {
    color: '#00000061'
  },
  divider: {
    marginTop: '7px !important',
    marginBottom: '0 !important'
  }
});

export type CheckboxWithLabelProps = FormikMaterialCheckboxWithLabelProps &
  WithStyles<typeof styles> & {
    fieldName: string;
    error?: string;
    additionalText?: string;
  };

type Props = CheckboxWithLabelProps;

const FormikCheckboxComponent: React.FunctionComponent<Props> = ({
  classes,
  className,
  fieldName,
  error,
  additionalText,
  ...restProps
}) => {
  return (
    <FormControl error={Boolean(error)} className={className}>
      <div className={classNames(classes.checkboxWrapper, error ? classes.checkboxWrapperError : '')}>
        <CheckboxWithLabel type={'checkbox'} {...restProps} />
        {additionalText && (
          <>
            <Divider className={classes.divider} />
            <Typography>
              <pre className={classNames(classes.additionalText, restProps.disabled ? classes.additionalTextDisabled : '')}>
                {additionalText}
              </pre>
            </Typography>
          </>
        )}
      </div>
      <ErrorMessage 
        name={fieldName}
        component={"p"}
        className={classes.checkboxErrorLabel}
      />
    </FormControl>
  );
};

export const FormikCheckbox = withStyles(styles)(FormikCheckboxComponent);
