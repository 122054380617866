import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { NoteForm, NoteFormValues } from './forms/NoteForm';
import { Submission } from '../shared/types/Submission';
import { Note } from '../shared/types/Note';

const styles = () =>
  createStyles({
    root: {
      marginTop: 10,
    },
  });

type Props = WithStyles<typeof styles> & {
  submissionId: Submission['id'];
  initValues?: Note;
  onSaved: () => void;
};

const AddNoteComponent: React.FunctionComponent<Props> = ({ classes, submissionId, initValues, onSaved }) => {
  return (
    <div className={classes.root}>
      <NoteForm
        submissionId={submissionId}
        initValues={initValues}
        onSaved={onSaved}
      />
    </div>
  );
};

export const AddNote = withStyles(styles)(AddNoteComponent);
