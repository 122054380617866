import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../i18n/useTranslator';
import ClearIcon from '@material-ui/icons/Clear';
import { List } from './List';
import { ListAction } from './ListAction';
import { CustomReviewerAutocomplete } from './CustomReviewerAutocomplete';
import { User } from '../shared/types/User';
import { ErrorService } from '../services/Error.service';
import { SuggestedReviewer } from '../shared/types/SuggestedReviewer';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import CheckIcon from "@material-ui/icons/Check"
import { SuggestedReviewersVisibilityOptions } from '../shared/types/StageVisibilityOptions';
import { BoxHeader } from './BoxHeader';

const styles = () =>
  createStyles({
    box: {
      marginBottom: 20,
    },
    suggestedReviewers: {
      fontSize: '1rem',
      padding: 0
    },
    suggestedReviewersHeader: {
      height: 10
    },
    heading: {
      marginTop: 20,
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    boxHeading: {
      marginBottom: 10,
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    actions: {
      display: 'flex',
    },
    councilMember: {
      fontSize: '13px',
      fontWeight: 'bold'
    }
  });

type Props = WithStyles<typeof styles> & {
  entityId: string;
  blockHeaderText: string;
  listHeaderText: string
  suggestedReviewersVisibilityOpt: SuggestedReviewersVisibilityOptions
  fetchAvailableSuggestedReviewersHandler: (id: string) => any;
  fetchAssignedSuggestedReviewersHandler: (id: string) => any;
  removeSuggestedReviewerHandler: (id: string, userId: string) => any;
  addSuggestedReviewerHandler: (id: string, userId: string) => any;
  acceptSuggestedReviewerHandler: (id: string, userId: string) => any;
  reviewerAcceptedEvent?: (reviewerId: string) => void;
};

const SuggestedReviewersComponent: React.FunctionComponent<Props> = ({ 
  classes,
  entityId,
  blockHeaderText,
  listHeaderText,
  suggestedReviewersVisibilityOpt,
  addSuggestedReviewerHandler, 
  removeSuggestedReviewerHandler,
  acceptSuggestedReviewerHandler,
  fetchAvailableSuggestedReviewersHandler,
  fetchAssignedSuggestedReviewersHandler,
  reviewerAcceptedEvent
}) => {

  const { t } = useTranslator();

  const [options, setOptions] = useState<Array<User>>([]);
  const [suggestedReviewers, setSuggestedReviewers] = useState<Array<SuggestedReviewer>>([]);
  const { userId } = useSelector((state: RootState) => state.user);
  const [serverError, setServerError] = useState('');

  const handleSave = async (users: Array<User>) => {
    for (const reviewer of users) {
      try {
        await addSuggestedReviewerHandler(entityId, reviewer.userId);
        await fetchAssignedSuggestedReviewers();
        await fetchAvailableSuggestedReviewers();
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
    }
  };

  const fetchAvailableSuggestedReviewers = () => {
    (async () => {
      setServerError('');

      if(suggestedReviewersVisibilityOpt.allowSelectSuggestedReviewers) {
        try {
          const {
            data: { result },
          } = await fetchAvailableSuggestedReviewersHandler(entityId);
  
          setOptions(result);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      }
      
    })();
  };

  const clearCallback = (userId: string) => {
    (async () => {
      setServerError('');
      try {
          await removeSuggestedReviewerHandler(entityId, userId);
          await fetchAssignedSuggestedReviewers();
          await fetchAvailableSuggestedReviewers();
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
    })();
  }

  const fetchAssignedSuggestedReviewers = () => {
    (async () => {
      if (suggestedReviewersVisibilityOpt.showSuggestedReviewersList) {
        setServerError("");

        try {
          const {
            data: { result },
          } = await fetchAssignedSuggestedReviewersHandler(entityId);

          setSuggestedReviewers(result);
        } catch (error) {
          setServerError(ErrorService.parseError(error));
        }
      }
    })();
  };

  const acceptReviewerCallback = (userId: string) => {
    (async () => {
      if (suggestedReviewersVisibilityOpt.allowAcceptSuggestedReviewers) {
        await acceptSuggestedReviewerHandler(entityId, userId);
        await fetchAssignedSuggestedReviewers();
        reviewerAcceptedEvent && reviewerAcceptedEvent(userId);
      }
    })();
  }

  const declineReviewerCallback = (userId: string) => {
    (async () => {
      if (suggestedReviewersVisibilityOpt.allowAcceptSuggestedReviewers) {
        await removeSuggestedReviewerHandler(entityId, userId);
        await fetchAssignedSuggestedReviewers();
      }
    })();
  }

  const mapRowsToListRows = () => {
    if (suggestedReviewersVisibilityOpt.allowAcceptSuggestedReviewers) {
      return suggestedReviewers.map((suggestedReviewer) => ({
        id: suggestedReviewer.userId,
        items: [
          {
            key: 'fullName',
            content: (
              <div>
                {`${suggestedReviewer.firstName} ${suggestedReviewer.lastName}`}
                <div className={classes.councilMember}>{`${t("suggestedBy")} ${
                  suggestedReviewer.suggestedByFirstName
                } ${suggestedReviewer.suggestedByLastName}`}</div>
              </div>
            ),
          },
          {
            key: 'action',
            content:
                <div className={classes.actions}>
                  <ListAction
                    to={'#'}
                    listData={suggestedReviewer.userId}
                    onClick={acceptReviewerCallback}
                    title={t('addReviewer')}
                  >
                    <CheckIcon />
                  </ListAction>
                  <ListAction
                    to={'#'}
                    listData={suggestedReviewer.userId}
                    onClick={declineReviewerCallback}
                    title={t('removeSuggestedReviewer')}
                  >
                    <ClearIcon />
                  </ListAction>
                </div>
          },
        ],
      }));
    }
    else if (suggestedReviewersVisibilityOpt.allowSelectSuggestedReviewers) {
      return suggestedReviewers.map((suggestedReviewer) => ({
        id: suggestedReviewer.userId,
        items: [
          {
            key: 'fullName',
            content: `${suggestedReviewer.firstName} ${suggestedReviewer.lastName}`
          },
          {
            key: 'action',
            content: (suggestedReviewer.suggestedByUserId === userId ?
              (<ListAction to={'#'} listData={suggestedReviewer.userId} onClick={clearCallback} title={t('removeSuggestedReviewer')}>
                <ClearIcon />
              </ListAction>
              ) : null
            ),
          },
        ],
      }));
    }
    else {
      return suggestedReviewers.map((suggestedReviewer) => ({
        id: suggestedReviewer.userId,
        items: [
          {
            key: 'fullName',
            content: `${suggestedReviewer.firstName} ${suggestedReviewer.lastName}`
          },
          {
            key: 'action',
            content: '',
          },
        ],
      }));
    }
  };

  const listHeader = [
    {
      key: 'fullName',
      text: '',
    },
    {
      key: 'action',
      text: '',
      pullRight: true,
    },
  ];

  useEffect(fetchAssignedSuggestedReviewers, []);
  useEffect(fetchAvailableSuggestedReviewers, []);
  useEffect(fetchAssignedSuggestedReviewers, [entityId]);

  return (
    <div>
      {blockHeaderText ? <BoxHeader title={blockHeaderText} /> : null}
      {suggestedReviewersVisibilityOpt.allowSelectSuggestedReviewers ? (
        <CustomReviewerAutocomplete
          options={options}
          onSaveHandler={handleSave}
        />
      ) : null}

      {suggestedReviewersVisibilityOpt.showSuggestedReviewersList && suggestedReviewers.length ? 
      (
        <>
          {suggestedReviewersVisibilityOpt.allowSelectSuggestedReviewers && suggestedReviewers.length > 0 ? 
          (
            listHeaderText ? (<BoxHeader title={listHeaderText} />) : null
          ) : null}
          <List
            headerCells={listHeader}
            headerClassName={classes.suggestedReviewersHeader}
            className={classes.suggestedReviewers}
            rows={mapRowsToListRows()}
          />
        </>
      ) : (
        t("none")
      )}
    </div>
  );
};

export const SuggestedReviewers = withStyles(styles)(SuggestedReviewersComponent);
