import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import { Form } from '../Form';
import { FieldTextField } from '../form-fields/FieldTextField';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { email, reviewerSpecialization, name, surname, scienceUnit, phone } from '../../shared/validators';
import { FieldSelect } from '../form-fields/FieldSelect';
import { UserService } from '../../services/User.service';
import { UserFormData } from '../../shared/types/UserFormData';
import { FormError } from '../FormError';
import { ErrorService } from '../../services/Error.service';
import { RoleEnum } from '../../shared/enums/RoleEnum';

const styles = () =>
  createStyles({
    saveButton: {
      alignSelf: 'flex-end',
    },
  });

type FormValues = UserFormData;

type Props = WithStyles<typeof styles> &
  RouteComponentProps<void> & {
    userId?: string;
    initValues?: FormValues;
  };

const UserFormComponent: React.FunctionComponent<Props> = ({ classes, history, initValues, userId }) => {
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      firstName: name(t),
      lastName: surname(t),
      scientificFacility: scienceUnit(t),
      phoneNumber: phone(t),
      email: email(t),
      specialty: reviewerSpecialization(t),
    });

  const initialValues = initValues || {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    scientificFacility: '',
    email: '',
    role: RoleEnum.Author,
    specialty: '',
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setServerError('');
    setSubmitting(true);

    try {
      if (userId) {
        await UserService.update(userId, values);
      } else {
        await UserService.add(values);
      }

      history.push('/admin/users');
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }

    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ values, dirty, isValid, isSubmitting }) => (
        <FormContent>
          <FieldSelect
            name={'role'}
            label={t('role')}
            items={[
              { value: RoleEnum.Author, label: t('participant') },
              { value: RoleEnum.Reviewer, label: t('reviewer') },
              { value: RoleEnum.ScientificCouncilMember, label: t('scientificCouncil') },
              { value: RoleEnum.CompetitionCommitteeMember, label: t('competitionCommitteeMember') },
              { value: RoleEnum.CompetitionJuryMember, label: t('competitionJuryMember') },
              { value: RoleEnum.FinanceCoordinator, label: t('financeCoordinator') },
              { value: RoleEnum.BoardMember, label: t('management') },
              { value: RoleEnum.Admin, label: t('admin') },
              { value: RoleEnum.CompetitionCoordinator, label: t('competitionCoordinator') }
            ]}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FieldTextField name={'firstName'} label={t('name')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FieldTextField name={'lastName'} label={t('surname')} fullWidth />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FieldTextField disabled={Boolean(userId)} name={'email'} label={t('email')} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FieldTextField name={'phoneNumber'} label={t('phone')} fullWidth />
            </Grid>
          </Grid>

          {values.role === RoleEnum.Author || values.role === RoleEnum.Reviewer ? (
            <FieldTextField name={'scientificFacility'} label={t('scienceUnit')} />
          ) : null}
          {values.role === RoleEnum.Reviewer ? <FieldTextField name={'specialty'} label={t('reviewerSpecialization')} /> : null}

          <FormError show={Boolean(serverError)} error={t('serverError')} />

          <Button className={classes.saveButton} type={'submit'} loading={isSubmitting} disabled={!dirty || isSubmitting}>
            {userId ? t('save') : t('addUser')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const UserForm = withStyles(styles)(withRouter(UserFormComponent));
