import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Form } from '../Form';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { FieldTextField } from '../form-fields/FieldTextField';
import { FormError } from '../FormError';
import { ErrorService } from '../../services/Error.service';
import { SubmissionService } from '../../services/Submissions.service';
import { Submission } from '../../shared/types/Submission';
import { Comment } from '../../shared/types/Comment';
import { CommentStatusEnum } from '../../shared/enums/CommentStatusEnum';
import { Autosave } from '../Autosave';

const styles = () =>
  createStyles({
    control: {
      marginBottom: 10,
    },
    addButton: {
      alignSelf: 'flex-end',
    },
  });

type Props = WithStyles<typeof styles> & {
  submissionId: Submission['id'];
  initValues?: Comment;
  onSaved: () => void;
};

type CommentFormValues = {
  comment: string;
};

const CommentFormComponent: React.FunctionComponent<Props> = ({ classes, submissionId, initValues, onSaved }) => {
  const [serverError, setServerError] = useState('');
  const [autosaving, setAutosaving] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState<CommentFormValues>();
  const [formHelpers, setFormHelpers] = useState<FormikHelpers<CommentFormValues>>();

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) => Yup.object().shape({});

  const [initialValues, setInitialValues] = useState<CommentFormValues>({
    comment: initValues ? initValues.value : '',
  });

  const handleSaveComment = () => {
    (async () => {
      if (!dataToSubmit || autosaving) {
        return;
      }
      
      try {
        const formData = new FormData();

        formData.append("value", dataToSubmit.comment);
        formData.append("parentId", submissionId);
        formData.append('status', CommentStatusEnum.Submitted.toString());

        await SubmissionService.addComment(formData);

        formHelpers?.resetForm();
        onSaved();
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      finally {
        setDataToSubmit(undefined);
      }
    })();
  };

  useEffect(handleSaveComment, [dataToSubmit, autosaving]);

  const handleFormSubmit = (data: CommentFormValues, formHelpers: FormikHelpers<CommentFormValues>) => {
    setServerError("");
    setDataToSubmit(data);
    setFormHelpers(formHelpers);
  };

  const handleAutosave = async (values: CommentFormValues) => {
    setAutosaving(true);

    try {
      const formData = new FormData();

      formData.append("value", values.comment);
      formData.append("parentId", submissionId);
      formData.append('status', CommentStatusEnum.Autosaved.toString());

      await SubmissionService.addComment(formData);
      
      setInitialValues(values);
    }
    finally {
      setAutosaving(false);
    }
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleFormSubmit}>
      {({ isSubmitting, values }) => (
        <FormContent>
          <FieldTextField
            className={classes.control}
            multiline
            name={'comment'}
            placeholder={t('commentDetails')}
            rows={3}
            size={'small'}
            fullWidth
          />

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <Button
            className={classes.addButton}
            type={'submit'}
            loading={isSubmitting}
            disabled={!values.comment || isSubmitting}
          >
            {t('addComment')}
          </Button>
        
          <Autosave submitting={isSubmitting} onSave={handleAutosave} />
        </FormContent>
      )}
    </Form>
  );
};

export const CommentForm = withStyles(styles)(CommentFormComponent);
