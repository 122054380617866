import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import MaterialInputLabel, { InputLabelProps as MaterialInputLabelProps } from '@material-ui/core/InputLabel';

const styles = () => createStyles({});

type Props = MaterialInputLabelProps & WithStyles<typeof styles>;

const InputLabelComponent: React.FunctionComponent<Props> = ({ ...restProps }) => {
  return <MaterialInputLabel {...restProps} />;
};

export const InputLabel = withStyles(styles)(InputLabelComponent);
