import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles, RadioGroup, FormControlLabel, Radio, FormControl } from '@material-ui/core';

import { useTranslator } from '../i18n/useTranslator';
import { Button } from './Button';
import { ErrorService } from '../services/Error.service';
import { FormError } from './FormError';
import { SubmissionStatusEnum } from '../shared/enums/SubmissionStatusEnum';
import { SubmissionService } from '../services/Submissions.service';
import { Submission } from '../shared/types/Submission';

const styles = () =>
  createStyles({
    root: {},
    error: {
      marginBottom: 10,
    },
    radioGroup: {
      marginBottom: 20,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    fieldset: {
      width: '100%',
    },
    saveButton: {
      alignSelf: 'flex-end',
    },
  });

type Props = WithStyles<typeof styles> & {
  submissionId: Submission['id'];
  onUpdated: () => void;
};

const ReviewSubmissionAdminActionsComponent: React.FunctionComponent<Props> = ({ classes, submissionId, onUpdated }) => {
  const [serverError, setServerError] = useState('');
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState<number | null>(null);

  const { t } = useTranslator();

  const handleSubmit = async () => {
    if (!value) {
      return;
    }

    setSaving(true);

    try {
      await SubmissionService.updateStatus(submissionId, value);

      onUpdated();
    } catch (error) {
      setServerError(ErrorService.parseError(error));
    }

    setSaving(false);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form}>
        <FormControl className={classes.fieldset} component='fieldset' error={false}>
          <RadioGroup
            className={classes.radioGroup}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setServerError('');
              setValue(Number(e.target.value));
            }}
          >
            <FormControlLabel value={SubmissionStatusEnum.Won} control={<Radio color='primary' />} label={t('markAsWon')} />
{/*             <FormControlLabel value={SubmissionStatusEnum.Rejected} control={<Radio color='primary' />} label={t('rejectSubmission')} />
            <FormControlLabel value={SubmissionStatusEnum.Closed} control={<Radio color='primary' />} label={t('closeSubmission')} /> */}
          </RadioGroup>
        </FormControl>
        <FormError className={classes.error} show={Boolean(serverError)} error={serverError} />
        <Button
          className={classes.saveButton}
          loading={saving}
          disabled={
            saving ||
            (value !== SubmissionStatusEnum.Won && value !== SubmissionStatusEnum.Rejected && value !== SubmissionStatusEnum.Closed)
          }
          onClick={handleSubmit}
        >
          {t('save')}
        </Button>
      </form>
    </div>
  );
};

export const ReviewSubmissionAdminActions = withStyles(styles)(ReviewSubmissionAdminActionsComponent);
