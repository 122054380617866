import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { AccountMultipleOutline, TrophyVariantOutline, At, FileChartOutline } from 'mdi-material-ui';

import { SidebarMenuItem } from '../SidebarMenuItem';
import { useTranslator } from '../../i18n/useTranslator';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AdminSidebarContentComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <div>
      <SidebarMenuItem icon={TrophyVariantOutline} label={t('competitions')} to={'/admin/competitions'} />
      <SidebarMenuItem icon={AccountMultipleOutline} label={t('users')} to={'/admin/users'} />      
      <SidebarMenuItem icon={At} label={t('emailTemplates')} to={'/admin/templates'} />
      <SidebarMenuItem icon={FileChartOutline} label={t('reports')} to={'/admin/reports'} />
    </div>
  );
};

export const AdminSidebarContent = withStyles(styles)(AdminSidebarContentComponent);
