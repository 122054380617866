import React, { useEffect, useState, useRef } from 'react';
import { createStyles, WithStyles, withStyles, Theme, Grid } from '@material-ui/core';
import { CloudDownloadOutlined, DeleteOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { useTranslator } from '../i18n/useTranslator';
import { FileInput } from './inputs/FileInput';
import { API_URL } from '../services/Api.service';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { RootState } from '../store/rootReducer';
import { Button } from './Button';
import { red, green } from '@material-ui/core/colors';
import { SubmissionStage } from '../shared/types/SubmissionStage';
import { StageService } from '../services/Stage.service';
import { ErrorService } from '../services/Error.service';
import { Alert } from '@material-ui/lab';
import { DuplicateAttachment } from '../shared/types/DuplicateAttachment';
import { getDuplicateSubmissionAttachments } from '../shared/helpers/getDuplicateAttachments';
import { Attachment } from '../shared/types/Attachment';
import { AxiosResponse } from 'axios';
import { Autosave } from './Autosave';
import { AttachmentStatusEnum } from '../shared/enums/AttachmentStatusEnum';
import { ConfirmationDialog } from './ConfirmationDialog';

const styles = ({ palette }: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 20,
      paddingTop: 20,
      '&:not(:first-child)': {
        borderTop: '1px solid rgba(0,0,0,0.05)',
      },
      '& > :first-child > *': {
        flex: 1,
      },
    },
    sendButton: {
      marginTop: 20,
    },
    sendButtonContainer: {
      display: 'flex',
      alignItems: 'end',
      flexDirection: 'column',
    },
    documentName: {
      fontSize: 14,
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    templateLinkContainer: {
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center'
    },
    templateLink: {
      display: 'flex',
      color: palette.primary.main,
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    iconDownload: {
      fontSize: 20,
      color: green[500],
      marginLeft:5
    },    
    iconDelete: {
      fontSize: 20,
      color: red[500],
      marginLeft:5
    },      
    rightContainer: {
      textAlign: 'right',
    },
    downloadUploadedFileButton: {
      marginTop: 3,
    },
    changeDocumentContainer: {
      marginLeft: 5
    },
    noDocumentationMessage: {
      fontStyle: 'italic',
      color: 'gray',
      textAlign: 'center'
    },
    alertMessage: {
      '&:not(:last-child)': {
        marginBottom: 10
      }
    },
    changeDocumentFileInput: {
      marginLeft: 5
    },
    badge: {
      marginTop: 10,
    }
  });

type Props = WithStyles<typeof styles> & {
  submissionStage: SubmissionStage;
  docsAssignedTo: RoleEnum;
  readonly: Boolean;
  showSpecimen?: Boolean;
  onChanged?: () => void;
  onStageAttachmentsChanged?: (attachments: SubmissionAttachment[]) => void;
};

const SubmissionStageDocumentationRowComponent: React.FunctionComponent<Props> = ({
  classes,
  submissionStage,
  docsAssignedTo,
  readonly,
  onChanged,
  onStageAttachmentsChanged,
  showSpecimen = true
}) => {
  const { role } = useSelector((state: RootState) => state.user);
  const { t } = useTranslator();

  const [serverError, setServerError] = useState('');
  const [saving, setSaving] = useState(false);

  const [duplicateAttachments, setDuplicateFileNames] = useState<Array<DuplicateAttachment>>([]);
  const [shouldValidateFiles, setShouldValidateFiles] = useState(false);
  const [stageAttachments, setStageAttachments] = useState<Array<SubmissionAttachment>>([]);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState<Map<string, File | null>>(new Map());
  const [attachmentsToUpdate, setAttachmentsToUpdate] = useState<Map<string, File | null>>(new Map());
  const [autosaving, setAutosaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState('');
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
  
  const initialAttachments = useRef<Map<string, File | null>>(new Map()).current;

  const handleDownloadTemplate = (templateId: string) => () => {
    if (role === RoleEnum.Admin || 
      role === RoleEnum.CompetitionCoordinator || 
      submissionStage.submissionId === undefined) {
        window.open(`${API_URL}/AttachmentSpecimen/${templateId}`);
    } else {
      window.open(`${API_URL}/AttachmentSpecimen/${templateId}/${submissionStage.submissionId}/${submissionStage.id}`);
    }
  };

  const handleFileChange = (templateId: string, file: File | null) => {
    setAttachmentsToUpload(map => new Map(map.set(templateId, file)));
  };

  const handleAdminFileChange = (attachmentId: string, file: File | null) => {
    setAttachmentsToUpdate(map => new Map(map.set(attachmentId, file)));
  };

  const fetchStageAttachments = () => {
    (async () => {
      try {
        if (docsAssignedTo) {
          const { data: { result: stageAttachments } } = await StageService.getStageAttachmentsForRole(submissionStage.id, docsAssignedTo);
          setStageAttachments(stageAttachments);
          onStageAttachmentsChanged && onStageAttachmentsChanged(stageAttachments);
        }
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
    })();
  };

  useEffect(fetchStageAttachments, [submissionStage]);

  const handleDownloadAttachment = (id: string) => () => {
    window.open(`${API_URL}/Attachment/${id}`);
  };

  const saveAttachments = async () => {
    const promises: Promise<AxiosResponse<any>>[] = [StageService.submitAttachments(submissionStage.id)];

    attachmentsToUpload.forEach((file, templateId) => {
      if (!file) {
        return;
      }
      const formData = new FormData();

      formData.append("file", file);
      formData.append("parentId", submissionStage.submissionId);
      formData.append("templateId", templateId);
      formData.append('status', AttachmentStatusEnum.Submitted.toString());

      promises.push(StageService.addFile(submissionStage.id, formData));
    });

    return Promise.all(promises);
  };

  const saveFiles = () => {
    (async () => {
      if (!submitting || autosaving) {
        return;
      }

      try {
        await saveAttachments();

        onChanged && onChanged();
      }
      catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      finally {
        setSubmitting(false);
      }
    })();
  };

  useEffect(saveFiles, [submitting, autosaving]);

  const handleFormSubmit = () => {
    setShouldValidateFiles(true);
    if (!hasValidDocuments()) {
      return;
    }

    setServerError("");
    setSubmitting(true);
  };

  const handleAutosave = async (
    data?: any,
    attachmentsToAdd?: { key: string; value: File | null; }[],
    mark?: number
  ) => {
    setAutosaving(true);

    try {
      await autosaveAttachments(attachmentsToAdd);
    }
    finally {
      setAutosaving(false);
    }
  };

  const autosaveAttachments = async (
    attachmentsToAdd?: { key: string; value: File | null; }[]
  ) => {
    if (!attachmentsToAdd) {
      return;
    }

    const promises: Promise<AxiosResponse<any>>[] = [];

    attachmentsToAdd.forEach(file => {
      if (!file.value) {
        return;
      }

      const formData = new FormData();

      formData.append('file', file.value);
      formData.append('parentId', submissionStage.submissionId);
      formData.append('templateId', file.key);
      formData.append('status', AttachmentStatusEnum.Autosaved.toString());

      promises.push(StageService.addFile(submissionStage.id, formData));
    });

    await Promise.all(promises);

    if (promises.length) {
      onChanged && onChanged();
      setAttachmentsToUpload(map => {
        attachmentsToAdd.forEach(attachment => {
          map.delete(attachment.key);
        });
        return map;
      });
    }
  };

  const updateAttachments = async () => {
    const promises: Promise<AxiosResponse<any>>[] = [];

    attachmentsToUpdate.forEach((file, attachmentId) => {
      if (!file) {
        return;
      }
      const formData = new FormData();

      formData.append("file", file);
      formData.append("parentId", submissionStage.submissionId);

      promises.push(StageService.updateFile(attachmentId, formData));
    });

    return Promise.all(promises);
  };

  const updateFiles = () => {
    (async () => {
      setSaving(true);
      try {
        await updateAttachments();

        onChanged && onChanged();
      }
      catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      finally {
        setSaving(false);
      }
    })();
  };

  const hasValidDocuments = () => {
    for (const attachment of stageAttachments) {
      if (
        attachment.role === role && (
            !attachmentsToUpload.get(attachment.templateId) &&
            !attachment.attachments.length &&
            attachment.isRequired === true
        )
      ) {
        return false;
      }
    }

    return true;
  };

  const deleteFile = async (id: string) => {
    await StageService.removeFile(id);

    fetchStageAttachments();
  };

  const handleClose = (confirmed: boolean) => {
    (async () => {
      setIsConfirmDialogOpen(false);
        
      confirmed && attachmentToDelete && await deleteFile(attachmentToDelete);
      setAttachmentToDelete('');
    })()
  };

  const handleAttachmentDelete = (id: string) => async () => {
    const dialogTitle = t('confirmAttachmentTemplateDeletion');
    setConfirmationDialogTitle(dialogTitle);
    setAttachmentToDelete(id);
    setIsConfirmDialogOpen(true);
  };

  const renderDownloadButton = (
    attachment: Attachment,
    templateName: string,
    displaySenderName?: boolean
  ): JSX.Element => {
    const senderFullName = displaySenderName ? ` (${attachment.senderFirstName} ${attachment.senderLastName})` : '';

    return (
      <div
        className={classNames(classes.templateLink, classes.downloadUploadedFileButton)}
        onClick={handleDownloadAttachment(attachment.id)}
      >
        {t('downloadDocument')} {templateName}{senderFullName}<CloudDownloadOutlined className={classes.iconDownload}/>
      </div>
    );
  };

  useEffect(() => {
    setDuplicateFileNames([]);
    
    if (stageAttachments) {
      const duplicates = getDuplicateSubmissionAttachments(stageAttachments, attachmentsToUpload);
      setDuplicateFileNames(duplicates);
    }
  }, [attachmentsToUpload, stageAttachments]);

  const hasAttachmentsToSubmit = () => {
    const hasAttachmentsToUpload = Array.from(attachmentsToUpload).some(([templateId, file]) => Boolean(file));
    const hasAutosavedAttachments = stageAttachments.some(attachment => attachment.attachments.some(attach => attach.status === AttachmentStatusEnum.Autosaved));
    
    return hasAttachmentsToUpload || hasAutosavedAttachments;
  }

  const hasAttachmentsToUpdate = () =>
    Array.from(attachmentsToUpdate).some(([templateId, file]) => Boolean(file));

  const renderSubmitButton = (): JSX.Element => {
    return (
      <>
        {readonly ? 
          (role === RoleEnum.Admin || role === RoleEnum.CompetitionCoordinator) &&
          Boolean(stageAttachments.length) && (
            <div className={classes.sendButtonContainer}>
              <Button
                className={classes.sendButton}
                loading={saving}
                disabled={saving || !hasAttachmentsToUpdate()}
                onClick={updateFiles}
              >
                {t("save")}
              </Button>
            </div>
          ) : (
            <div className={classes.sendButtonContainer}>
              <Button
                className={classes.sendButton}
                loading={submitting}
                disabled={submitting || !hasAttachmentsToSubmit()}
                onClick={handleFormSubmit}
              >
                {t("send")}
              </Button>
              <div className={classes.sendButton}>{t("requiredInfo")}</div>
            </div>
          )}
      </>
    );
  };

  const getNoDocumentationMessage = (): string => {
    switch (docsAssignedTo) {
      case RoleEnum.Author:
        return t('noAuthorDocumentation');
      case RoleEnum.Reviewer:
        return t('noReviewerDocumentation');
      default:
        return '';
    }
  };

  return (
    <>
      {Boolean(duplicateAttachments.length) && 
      (role === RoleEnum.Author && docsAssignedTo === RoleEnum.Author || 
      role === RoleEnum.Reviewer && docsAssignedTo == RoleEnum.Reviewer) && (
        <Alert severity={"warning"}>
          {duplicateAttachments.map(attachment => (
            <div className={classes.alertMessage}>
              {t('duplicatedFileInfo')
                .replace('{0}', attachment.fileName)
                .replace('{1}', attachment.templateNames.length.toString())
                .replace('{2}', attachment.templateNames.map(a => `"${a}"`).join(', '))}
            </div>
          ))}
        </Alert>
      )}
      <div>
        {stageAttachments.length  ? (
          <>
            {stageAttachments.map((attachment) => {
              const shouldRenderMiddleColumn = attachment.hasSpecimenFile && showSpecimen;

              return (
                <Grid container className={classes.row}>
                  {(role === RoleEnum.Admin || role === RoleEnum.CompetitionCoordinator) ? (
                    <Grid container alignItems={"center"} justify={"space-between"}>
                      <Grid item xs={4}>
                        <div className={classes.documentName}>{attachment.templateName}</div>
                      </Grid>
                      {attachment.attachments.length ? (
                        <Grid container item xs={8} alignItems={"center"} spacing={1}>
                          {attachment.attachments.map(attach => (
                            <>
                              <Grid item xs={6}>
                                {renderDownloadButton(attach, attachment.templateName, true)}
                              </Grid>
                              <Grid item xs={6} className={classes.rightContainer}>
                                {' '}
                                <FileInput
                                  classes={{root: classes.changeDocumentFileInput}}
                                  size={'small'}
                                  label={t('changeDocument')}
                                  value={attachmentsToUpdate.get(attach.id) ?? null}
                                  onChange={(value: File | null) => handleAdminFileChange(attach.id, value)}
                                />
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      ) : (
                        <Grid item className={classes.rightContainer}>
                          <span>{t('none')}</span>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <>
                      <Grid container alignItems={"center"} justify={"space-between"}>
                        <Grid item xs={shouldRenderMiddleColumn ? 4 : 6}>
                          <div className={classes.documentName}>
                            {attachment.templateName}
                          </div>
                        </Grid>

                        {shouldRenderMiddleColumn && (
                          <Grid item xs={4}>
                            <div className={classes.templateLinkContainer}>
                              <span
                                className={classes.templateLink}
                                onClick={handleDownloadTemplate(attachment.templateId)}
                              >
                                {t("downloadTemplate")} {attachment.templateName}
                                <CloudDownloadOutlined className={classes.iconDownload} />
                              </span>
                            </div>
                          </Grid>
                        )}

                        <Grid item xs={shouldRenderMiddleColumn ? 4 : 6}>
                          <div className={classes.rightContainer}>
                            {!attachment.attachments.length ? (
                              readonly ? (
                                <span>{t("none")}</span>
                              ) : (
                                <FileInput
                                  error={
                                    shouldValidateFiles &&
                                    attachment.isRequired &&
                                    !attachmentsToUpload.get(attachment.templateId)
                                  }
                                  helperText={
                                    shouldValidateFiles &&
                                    attachment.isRequired &&
                                    !attachmentsToUpload.get(attachment.templateId)
                                      ? t("fillDocument")
                                      : ""
                                  }
                                  size={"small"}
                                  label={t("document") + (attachment.isRequired ? " (*)" : "")}
                                  value={attachmentsToUpload.get(attachment.templateId) ?? null}
                                  onChange={(value: File | null) => handleFileChange(attachment.templateId, value)}
                                />
                              )
                            ) : (
                              <>
                                {(role === RoleEnum.Reviewer || role === RoleEnum.Author) && (
                                  renderDownloadButton(attachment.attachments[0], attachment.templateName)
                                )}

                                {(role === RoleEnum.BoardMember ||
                                  role === RoleEnum.FinanceCoordinator ||
                                  role === RoleEnum.ScientificCouncilMember ||
                                  role === RoleEnum.CompetitionCommitteeMember ||
                                  role === RoleEnum.CompetitionJuryMember) && (
                                    attachment.attachments.map(attach =>
                                      renderDownloadButton(attach, attachment.templateName, true)
                                    )
                                )}

                                {readonly ? 
                                  null : (
                                    <div
                                      className={classNames(classes.templateLink, classes.downloadUploadedFileButton)}
                                      onClick={handleAttachmentDelete(attachment.attachments[0].id)}
                                    >
                                      {t("delete")}
                                      <DeleteOutlined className={classes.iconDelete} />
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}

            {!readonly && (role === RoleEnum.Author || role === RoleEnum.Reviewer) && (
              <Autosave
                initialAttachments={initialAttachments}
                currentAttachments={attachmentsToUpload}
                submitting={submitting}
                onSave={handleAutosave}
              />
            )}
          </>
        ) : (
          <div className={classes.noDocumentationMessage}>
            <span>{getNoDocumentationMessage()}</span>
          </div>
        )}

        {renderSubmitButton()}
      </div>
      <ConfirmationDialog open={isConfirmDialogOpen} onClose={(confirmed) => handleClose(confirmed)} dialogTitle={confirmationDialogTitle} />
    </>
  );
};

export const SubmissionStageDocumentationRow = withStyles(styles)(SubmissionStageDocumentationRowComponent);
