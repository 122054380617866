import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { StageForm } from '../../forms/StageForm';
import { Box } from '../../Box';
import { useHistory } from 'react-router';
import { StageService } from '../../../services/Stage.service';
import { SubmissionStage } from '../../../shared/types/SubmissionStage';
import { ScreenToolbar } from '../../ScreenToolbar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { RoleEnum } from '../../../shared/enums/RoleEnum';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AdminAddStageScreenComponent: React.FunctionComponent<Props> = ({classes}) => {
  const [activeStage, setActiveStage] = useState<SubmissionStage>();

  const { t } = useTranslator();
  const history = useHistory();
  const { submissionId } = history.location.state as any;
  
  const { role } = useSelector((state: RootState) => state.user);
  const path = role === RoleEnum.Admin ? 'admin' 
    : role === RoleEnum.CompetitionCoordinator ? 'competition-coordinator' 
    : '';

  const fetchActiveStage = () => {
    (async () => {
      const {
        data: { result },
      } = await StageService.getActiveStage(submissionId);

      setActiveStage(result);
    })();
  };
  useEffect(fetchActiveStage, [])

  return (
    <div>
      <SectionHeader title={t('addStage')} />
      <ScreenToolbar
          backLink={{
            to: `/${path}/submissions/${submissionId}`,
            title: t("backToSubmission"),
          }}
        />
      <Box>
        <StageForm submissionId={submissionId} activeStageName={activeStage?.name} />
      </Box>
    </div>
  );
};

export const AdminAddStageScreen = withStyles(styles)(AdminAddStageScreenComponent);
