import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 30,
    },
    title: {
      margin: 0,
      fontSize: 22,
    },
  });

type Props = WithStyles<typeof styles> & {
  title?: string;
  right?: JSX.Element;
};

const SectionHeaderComponent: React.FunctionComponent<Props> = ({ classes, title, right }) => {
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{title}</h3> <div>{right}</div>
    </div>
  );
};

export const SectionHeader = withStyles(styles)(SectionHeaderComponent);
