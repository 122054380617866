import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { List } from '../../List';
import { Box } from '../../Box';
import { ErrorService } from '../../../services/Error.service';
import { CompetitionService } from '../../../services/Competition.service';
import { Competition } from '../../../shared/types/Competition';
import { Message } from '../../Message';
import { DateService } from '../../../services/Date.service';
import { BoxLoader } from '../../BoxLoader';
import { Link } from '../../Link';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AuthorCompetitionsScreenComponent: React.FunctionComponent<Props> = () => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [competitions, setCompetitions] = useState<Array<Competition>>([]);

  const { t } = useTranslator();

  const listHeader = [{
    key: 'name',
    text: t('competitionName'),
  }, {
    key: 'startDate',
    text: t('startDate'),
  }, {
    key: 'endDate',
    text: t('endDate'),
  }];

  const mapRowsToListRows = () =>
    competitions.map((competition) => ({
      id: competition.id,
      items: [{
        key: 'name',
        content: (
          <Link
            to={
              competition.submissionId
                ? `/author/submissions/${competition.submissionId}`
                : `/author/competitions/${competition.id}/submit`
            }
          >
            {competition.name}
          </Link>
        ),
      }, {
        key: 'startDate',
        content: moment(competition.startDate).format(DateService.DATE_FORMAT),
      }, {
        key: 'endDate',
        content: moment(competition.endDate).format(DateService.DATE_FORMAT),
      }],
    }));

  const fetchCompetitions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result },
        } = await CompetitionService.getActive();

        setCompetitions(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchCompetitions, []);

  return (
    <div>
      <SectionHeader title={t('competitions')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Box>
          {loading ? (
            <BoxLoader />
          ) : (
            <>
              {competitions.length ? <List headerCells={listHeader} rows={mapRowsToListRows()} /> : <span>{t('noCompetitionsFound')}</span>}{' '}
            </>
          )}
        </Box>
      )}
    </div>
  );
};

export const AuthorCompetitionsScreen = withStyles(styles)(AuthorCompetitionsScreenComponent);
