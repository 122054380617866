import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { Box } from '../../Box';
import { CompetitionForm } from '../../forms/CompetitionForm';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AdminAddCompetitionScreenComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <div>
      <SectionHeader title={t('addCompetition')} />
      <Box>
        <CompetitionForm />
      </Box>
    </div>
  );
};

export const AdminAddCompetitionScreen = withStyles(styles)(AdminAddCompetitionScreenComponent);
