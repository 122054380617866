import React, { useState } from 'react';

import { createStyles, withStyles, WithStyles, Grid } from '@material-ui/core';
import { SubmissionOtherAttachment } from '../shared/types/SubmissionOtherAttachment';
import { InsertDriveFileOutlined, CloudDownloadOutlined, DeleteOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import { API_URL } from '../services/Api.service';
import { SubmissionService } from '../services/Submissions.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { FileInput } from './inputs/FileInput';
import { useTranslator } from '../i18n/useTranslator';
import { Message } from './Message';
import { blue } from '@material-ui/core/colors';
import { AllowedMimeTypes } from '../shared/AllowedMimeTypes';

const styles = () =>
  createStyles({
    icon: {
      color: '#6c757d',
      margin: '4px 15px 7px 0',
      'font-size': '22px'
    },
    name: {
      'font-weight': '600',
      overflow: 'hidden',
      'overflow-wrap': 'break-word',
      'margin-right': '15px'
    },
    'action-buttons': {
      margin: '0 0 0 auto',
      'font-size': '22px'
    },
    'action-button': {
      width: '40px',
      cursor: 'pointer'
    },
    'download-icon': {
      color: blue[500]
    },
    'delete-icon': {
      color: '#dc3545'
    },
    row: {
      display: 'flex',
      'flex-direction': 'row',
      'align-items': 'center',
      'min-height': '56px',
      padding: '8px 20px',
      'font-size': '13px',
      '&:nth-child(2n)': {
        'background-color': '#f1f1f1'
      }
    },
    gridContainer: {
      justifyContent: 'flex-end'
    },
    leftContainer: {
      display: 'inline-flex',
      alignItems: 'center'
    },
    badge: {
      marginTop: 10,
    }
  });

type Props = WithStyles<typeof styles> & {
  submissionOtherAttachments?: Array<SubmissionOtherAttachment>;
  attachmentsToUpdate: { [key in string]: File | null };
  onDelete?: () => void;
  onChange?: (id: string, file: File | null) => void;
};

const SubmissionOtherAttachmentsComponent: React.FunctionComponent<Props> = ({classes, submissionOtherAttachments, attachmentsToUpdate, onDelete, onChange}) => {
  const [fileMimeTypeError, setFileMimeTypeError] = useState(false);
  const { t } = useTranslator();
  const { userId, role } = useSelector((state: RootState) => state.user);

  const handleDownloadAttachment = (id: string) => () => {
    window.open(`${API_URL}/Attachment/${id}`);
  };

  const handleAttachmentDelete = (id: string) => async () => {
    await SubmissionService.removeFile(id);

    onDelete && onDelete();
  };

  const handleAttachmentUpdate = (id: string, file: File | null) => {
    setFileMimeTypeError(false);

    if (file && AllowedMimeTypes.AdditionalDocumentation.indexOf(file.type) < 0) {
      setFileMimeTypeError(true);
      return;
    }

    onChange && onChange(id, file);
  };

  return (
    <div>
      {fileMimeTypeError ? <Message type={'ERROR'}>{t('invalidFileExtension')} {t('allowedExtensions')}</Message> : null}
      {submissionOtherAttachments?.map((attachment) => (
        <div className={classes.row}>
          <Grid container className={classes.gridContainer} spacing={1}>
            <Grid item xl={8}>
              <div className={classes.leftContainer}>
                <InsertDriveFileOutlined className={classes.icon}/>
                <div className={classes.name}>{attachment.fileName} ({attachment.senderFirstName} {attachment.senderLastName})</div>
                <div className={classes['action-buttons']}>
                  <span onClick={handleDownloadAttachment(attachment.id)}>
                    <CloudDownloadOutlined  className={classNames(classes['download-icon'], classes['action-button'])}/>
                  </span>
                  {userId === attachment.senderId ? (
                    <span onClick={handleAttachmentDelete(attachment.id)}>
                    <DeleteOutlined className={classNames(classes['delete-icon'], classes['action-button'])}/>
                    </span>
                  ) : null}
                </div>
              </div>
            </Grid>
            <Grid item xl={4}>
              {(role === RoleEnum.Admin || role === RoleEnum.CompetitionCoordinator) ? (
                attachment ? ( 
                  <FileInput
                    size={'small'}
                    label={t('changeDocument')}
                    value={attachmentsToUpdate[attachment.id]}
                    onChange={(value: File | null) => handleAttachmentUpdate(attachment.id, value)}
                    allowedMimeTypes={AllowedMimeTypes.AdditionalDocumentation}
                  />
                ) : ( 
                  <span>{t('none')}</span>
                )
              ) : null}
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
    
  );
};

export const SubmissionOtherAttachments = withStyles(styles)(SubmissionOtherAttachmentsComponent);