import React, { useState, useEffect } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { useTranslator } from '../i18n/useTranslator';
import { BoxHeader } from './BoxHeader';
import { Autocomplete } from './inputs/Autocomplete';
import { User } from '../shared/types/User';
import { TextField } from './inputs/TextField';
import { FormError } from './FormError';
import { ErrorService } from '../services/Error.service';
import { UserService } from '../services/User.service';
import { CompetitionService } from '../services/Competition.service';
import { List } from './List';
import { ListAction } from './ListAction';
import { DeleteOutlined } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Button } from './Button';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { RouteComponentProps, withRouter } from 'react-router';

const styles = () =>
    createStyles({
        competitionCoordinatorsListWrapper: {
            margin: '15px 10px'
        },
        competitionCoordinatorsList: {
            fontSize: '1rem',
            padding: 10,
            maxWidth: 250
        },
        iconDelete: {
            fontSize: 20,
            color: red[500],
            marginLeft: 5
        },
        saveButton: {
            alignSelf: 'flex-end',
        }
    });

type Props = WithStyles<typeof styles> & 
RouteComponentProps<void> & {
    competitionId?: string;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    isUpdate: boolean;
};

const CompetitionCoordinatorsSelectorComponent: React.FunctionComponent<Props> = ({ 
    competitionId,
    classes,
    setFieldValue,
    isUpdate,
    history
}) => {
    const [serverError, setServerError] = useState('');
    const [loading, setLoading] = useState(false);
    const [availableCompetitionCoordinators, setAvailableCompetitionCoordinators] = useState<Array<User>>([]);
    const [selectedCompetitionCoordinators, setSelectedCompetitionCoordinators] = useState<Array<User>>([]);
    const [competitionCoordinatorsToSave, setCompetitionCoordinatorsToSave] = useState<Array<User>>([]);
    const { role, userId } = useSelector((state: RootState) => state.user);
    const { t } = useTranslator();

    useEffect(() => {
        fetchCompetitionCoordinators();
    }, []);

    useEffect(() => {
        setFieldValue && setFieldValue('competitionCoordinatorUserIds', competitionCoordinatorsToSave.map(user => user.userId));
    }, [competitionCoordinatorsToSave, setFieldValue]);

    const fetchCompetitionCoordinators = () => {
        (async () => {
            setServerError('');
    
            try {
                const { data: { result } } = await UserService.getCompetitionCoordinators();
                let availableCoordinators = result;
                setAvailableCompetitionCoordinators(availableCoordinators);
        
                if (competitionId){
                    const { data: { result } } = await CompetitionService.getCompetitionCoordinators(competitionId);
                    let selectedCoordinators = result;
                    setSelectedCompetitionCoordinators(selectedCoordinators);
            
                    availableCoordinators = availableCoordinators.filter(user => 
                        !selectedCoordinators.find(selectedUser => selectedUser.userId === user.userId)
                    );
                    setAvailableCompetitionCoordinators(availableCoordinators);
                }
        
            } catch (error) {
                setServerError(ErrorService.parseError(error));
            }
        })();
    };
    
    const deleteCompetitionCoordinator = (userIdToDelete: string) => {
        (async () => {
            setServerError('');

            try {
                if (competitionId){
                    await CompetitionService.deleteCompetitionCoordinator(competitionId, userIdToDelete);

                    if (userId === userIdToDelete) {
                        history.push('/competition-coordinator/competitions');
                        return;
                    }

                    fetchCompetitionCoordinators();
                }
            } catch (error) {
                setServerError(ErrorService.parseError(error));
            }
        })();
    };
    
    const mapRowsToCoordinatorListRows = () =>
        selectedCompetitionCoordinators.map((competitionCoordinator) => ({
            id: competitionCoordinator.userId,
            items: [{
                key: 'fullName',
                content: `${competitionCoordinator.firstName} ${competitionCoordinator.lastName}`
            }, {
                key: 'action',
                content:
                <ListAction 
                    title={t('delete')} 
                    to='#'
                    onClick={() => deleteCompetitionCoordinator(competitionCoordinator.userId)}
                >
                    <DeleteOutlined className={classes.iconDelete} />
                </ListAction>
            }],
        }));
    
    const handleSave = async () => {
        setLoading(true);
        setServerError('');
    
        try {
            if (competitionId) {
                await CompetitionService.addCompetitionCoordinators(competitionId, competitionCoordinatorsToSave.map(user => user.userId));
            } 

            setCompetitionCoordinatorsToSave([]);
            fetchCompetitionCoordinators();
        } catch (error) {
            setServerError(ErrorService.parseError(error));
        }
    
        setLoading(false);
    };

  return (
    <>
      <BoxHeader title={t('competitionCoordinators')} />
        <Autocomplete
            multiple
            options={availableCompetitionCoordinators}
            noOptionsText={t('noCompetitionCoordinatorsToChoose')}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            renderOption={(option) => (
                <span>
                    {option.firstName} {option.lastName}
                </span>
            )}
            renderInput={(params) => <TextField {...params} label={t('pickCompetitionCoordinator')} />}
            value={competitionCoordinatorsToSave}
            onChange={(_, value) => setCompetitionCoordinatorsToSave(value)}
        />

        
        <div className={classes.competitionCoordinatorsListWrapper}>
            {selectedCompetitionCoordinators.length ? (
                <List
                    className={classes.competitionCoordinatorsList}
                    rows={mapRowsToCoordinatorListRows()}
                />
            ) : (
                isUpdate && 
                <span>
                    {t('noCompetitionCoordinatorsSelected')}
                </span>
            )}
        </div>

        {isUpdate && (
            <Button
                className={classes.saveButton} 
                onClick={handleSave}
                loading={loading}
                disabled={!competitionCoordinatorsToSave.length}
            >
                {t('save')}
            </Button>
        )}

        <FormError show={Boolean(serverError)} error={t(serverError)} />
    </>
  );
};

export const CompetitionCoordinatorsSelector = withStyles(styles)(withRouter(CompetitionCoordinatorsSelectorComponent));
