import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { UnloggedLayout } from '../layouts/UnloggedLayout';
import { Box } from '../Box';
import { BoxHeader } from '../BoxHeader';
import { useTranslator } from '../../i18n/useTranslator';
import { HelpMessage } from '../HelpMessage';
import { Button } from '../Button';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const CreatePasswordSuccessScreenComponent: React.FunctionComponent<Props> = () => {
  const { t } = useTranslator();

  return (
    <UnloggedLayout>
      <Box>
        <BoxHeader title={t('resetPassword')} />
        <div>{t('passwordChangeSuccess')}</div>
        <br/>
        <Button to={'/login'} fullWidth variant={'outlined'}>
          {t('login')}
        </Button>  
        <HelpMessage />
      </Box>
    </UnloggedLayout>
  );
};

export const CreatePasswordSuccessScreen = withStyles(styles)(CreatePasswordSuccessScreenComponent);