import { ApiService } from './Api.service';
import { ApiResponse } from '../shared/types/ApiResponse';
import { SimpleSubmission } from '../shared/types/SimpleSubmission';
import { Competition } from '../shared/types/Competition';
import { Submission } from '../shared/types/Submission';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { Comment } from '../shared/types/Comment';
import { Note } from '../shared/types/Note';
import { User } from '../shared/types/User';
import { Mark } from '../shared/types/Mark';
import { SubmissionStatusEnum } from '../shared/enums/SubmissionStatusEnum';
import { SubmissionFull } from '../shared/types/SubmissionFull';
import { SuggestedReviewer } from '../shared/types/SuggestedReviewer';
import { SubmissionOtherAttachment } from '../shared/types/SubmissionOtherAttachment';
import { SubmissionsCount } from '../shared/types/SubmissionsCount';

export const SubmissionService = {
  resource: 'Submission',
  add: (competitionId: Competition['id'], submissionProjectData: Submission['project']) => {
    return ApiService.post<any>(`${SubmissionService.resource}/${competitionId}`, submissionProjectData, { withCredentials: true });
  },
  autosave: (competitionId: Competition['id'], submissionProjectData: Submission['project']) => {
    return ApiService.post<any>(`${SubmissionService.resource}/${competitionId}/autosave`, submissionProjectData, { withCredentials: true });
  },
  addFile: (data: FormData) => {
    return ApiService.post(`${SubmissionService.resource}/attachments`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  removeFile: (id: string) => {
    return ApiService.delete(`${SubmissionService.resource}/attachments/${id}`, {
      withCredentials: true,
    });
  },
  updateFile: (id: string, data: FormData) => {
    return ApiService.put(`${SubmissionService.resource}/attachments/${id}`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  getOne: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<Submission>>(`${SubmissionService.resource}/${id}`, { withCredentials: true });
  },
  getOneFull: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<SubmissionFull>>(`${SubmissionService.resource}/${id}/full`, { withCredentials: true });
  },
  getAutosaved: (competitionId: Competition['id']) => {
    return ApiService.get<ApiResponse<Submission>>(`${SubmissionService.resource}/${competitionId}/autosave`, { withCredentials: true });
  },
  getMy: () => {
    return ApiService.get<ApiResponse<Array<SimpleSubmission>>>(`${SubmissionService.resource}/me`, { withCredentials: true });
  },
  getAllAttachments: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<SubmissionAttachment>>>(`${SubmissionService.resource}/attachments/${id}/all`, {
      withCredentials: true,
    });
  },
  getReviewerAttachments: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<SubmissionAttachment>>>(`${SubmissionService.resource}/attachments/${id}/reviewer`, {
      withCredentials: true,
    });
  },
  getAuthorAttachments: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<SubmissionAttachment>>>(`${SubmissionService.resource}/attachments/${id}/author`, {
      withCredentials: true,
    });
  },
  getOtherAttachments: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<SubmissionOtherAttachment>>>(`${SubmissionService.resource}/attachments/${id}/other`, {
      withCredentials: true
    });
  },
  submitAttachments: (submissionId: Submission['id']) => {
    return ApiService.put(`${SubmissionService.resource}/attachments/${submissionId}/submit`, {}, {
      withCredentials: true
    });
  },
  submitOtherAttachments: (submissionId: Submission['id']) => {
    return ApiService.put(`${SubmissionService.resource}/attachments/${submissionId}/submit-other`, {}, {
      withCredentials: true
    });
  },
  getActive: () => {
    return ApiService.get<ApiResponse<Array<SimpleSubmission>>>(`${SubmissionService.resource}/active`, {
      withCredentials: true,
    });
  },
  getArchived: () => {
    return ApiService.get<ApiResponse<Array<SimpleSubmission>>>(`${SubmissionService.resource}/inactive`, {
      withCredentials: true,
    });
  },
  mark: (data: FormData) => {
    return ApiService.put(
      `${SubmissionService.resource}/mark`,
      data,
      {
        withCredentials: true,
      }
    );
  },
  getInReview: (id: Competition['id']) => {
    return ApiService.get<ApiResponse<Array<any>>>(`${SubmissionService.resource}/in-review?competitionId=${id}`, {
      withCredentials: true,
    });
  },
  getWon: (id: Competition['id']) => {
    return ApiService.get<ApiResponse<Array<any>>>(`${SubmissionService.resource}/won-submissions?competitionId=${id}`, {
      withCredentials: true,
    });
  },
  getDrafts: (id: Competition['id']) => {
    return ApiService.get<ApiResponse<Array<any>>>(`${SubmissionService.resource}/draft?competitionId=${id}`, {
      withCredentials: true,
    });
  },
  getRejected: (id: Competition['id']) => {
    return ApiService.get<ApiResponse<Array<any>>>(`${SubmissionService.resource}/rejected?competitionId=${id}`, {
      withCredentials: true,
    });
  },
  getNew: (id: Competition['id']) => {
    return ApiService.get<ApiResponse<Array<any>>>(`${SubmissionService.resource}/new?competitionId=${id}`, {
      withCredentials: true,
    });
  },
  getSubmissionsCount: (id: Competition['id']) => {
    return ApiService.get<ApiResponse<SubmissionsCount>>(`${SubmissionService.resource}/submissions-count?competitionId=${id}`)
  },
  addComment: (data: FormData) => {
    return ApiService.put<ApiResponse<Comment>>(
      `${SubmissionService.resource}/comments`,
      data,
      { withCredentials: true }
    );
  },
  getComments: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<Comment>>>(`${SubmissionService.resource}/${id}/comments`, { withCredentials: true });
  },
  addNote: (data: FormData) => {
    return ApiService.put<ApiResponse<Note>>(
      `${SubmissionService.resource}/notes`,
      data,
      { withCredentials: true }
    );
  },
  getNotes: (id: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<Note>>>(`${SubmissionService.resource}/${id}/notes`, { withCredentials: true });
  },
  deleteNote: (id: Submission['id'], noteId: Note['id']) => {
    return ApiService.delete(`${SubmissionService.resource}/${id}/notes/${noteId}`, { withCredentials: true });
  },
  getReviewers: (submissionId: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<User>>>(`${SubmissionService.resource}/${submissionId}/reviewers`, {
      withCredentials: true,
    });
  },
  addReviewer: (submissionId: Submission['id'], userId: User['userId']) => {
    return ApiService.post(
      `${SubmissionService.resource}/${submissionId}/reviewers?userId=${userId}`,
      {},
      {
        withCredentials: true,
      }
    );
  },
  deleteReviewer: (submissionId: Submission['id'], userId: User['userId']) => {
    return ApiService.delete(`${SubmissionService.resource}/${submissionId}/reviewers?userId=${userId}`, { withCredentials: false });
  },
  addSuggestedReviewer: (submissionId: Submission['id'], userId: User['userId']) => {
    return ApiService.post(
      `${SubmissionService.resource}/${submissionId}/reviewers/suggested?userId=${userId}`,
      {},
      {
        withCredentials: true,
      }
    );
  },
  acceptSuggestedReviewer: (submissionId: Submission['id'], userId: User['userId']) => {
    return ApiService.post(
      `${SubmissionService.resource}/${submissionId}/reviewers/accept?userId=${userId}`,
      {},
      { withCredentials: true }
    );
  },
  getAvailableSuggestedReviewers: (submissionId: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<User>>>(`${SubmissionService.resource}/${submissionId}/reviewers/suggested/available/`, { withCredentials: true });
  },
  removeSuggestedReviewer: (submissionId: string, userId: string) => {
    return ApiService.delete(`${SubmissionService.resource}/${submissionId}/reviewers/suggested?userId=${userId}`, 
    { withCredentials: true });
  },
  getSuggestedReviewers: (submissionId: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<SuggestedReviewer>>>(`${SubmissionService.resource}/${submissionId}/reviewers/suggested`, { withCredentials: true });
  },

  getMark: (submissionId: Submission['id']) => {
    return ApiService.get<ApiResponse<Mark>>(`${SubmissionService.resource}/${submissionId}/mark`, {
      withCredentials: true,
    });
  },

  getAllMarks: (submissionId: Submission['id']) => {
    return ApiService.get<ApiResponse<Array<Mark>>>(`${SubmissionService.resource}/${submissionId}/mark/all`, {
      withCredentials: true,
    });
  },

  updateStatus: (submissionId: Submission['id'], status: SubmissionStatusEnum) => {
    return ApiService.put(`${SubmissionService.resource}/${submissionId}/status?status=${status}`, {}, { withCredentials: true });
  },

  addSubmissionDetails: (id: string, data: Submission['project']) => {
    return ApiService.post(`${SubmissionService.resource}/${id}`, data, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  updateSubmissionDetails: (id: string, data: Submission['project']) => {
    return ApiService.put(`${SubmissionService.resource}/${id}`, data, {
      withCredentials: true,
    });
  },

  notifyCreated: (id: Submission['id']) => {
    return ApiService.put(
      `${SubmissionService.resource}/${id}/notify-created`,
      {},
      {
        withCredentials: true,
      }
    );
  },
  
  canReviewSubmission: (id: Submission['id'], userId: User['userId']) => {
    return ApiService.get<ApiResponse<boolean>>(`${SubmissionService.resource}/${id}/canReview?userId=${userId}`, {
      withCredentials: true
    });
  },
};
