import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { Submission } from '../shared/types/Submission';
import { Note } from '../shared/types/Note';
import { SubmissionService } from '../services/Submissions.service';
import { DeleteOutlined } from '@material-ui/icons';

const styles = () =>
  createStyles({
    deleteButton: {
        color: 'red',
        display: 'inline-flex',
        position: 'relative',
        top: '7px',
        margin: '0 0 0 1%'
      }
  });

type Props = WithStyles<typeof styles> & {
  submissionId: Submission['id'];
  noteId: Note['id'];
  className?: string;
  onDeleted: () => void;
}

const DeleteNoteComponent: React.FunctionComponent<Props> = ({ classes, submissionId, noteId, onDeleted }) => {
  const handleDelete = async () => {
    await SubmissionService.deleteNote(submissionId, noteId);

    onDeleted();
  }

  return (
    <div className={classes.deleteButton}>
        <span onClick = {handleDelete}>
            <DeleteOutlined />
        </span>
    </div>
  );
}

export const DeleteNote = withStyles(styles)(DeleteNoteComponent);