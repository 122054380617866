import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { Sidebar } from '../Sidebar';

const sidebarWidth = 235;

export const dashboardPaddingLeftRight = 24;

const styles = () =>
  createStyles({
    root: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    sidebar: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      width: sidebarWidth,
    },
    container: {
      marginLeft: sidebarWidth,
      width: 'auto',
      paddingLeft: dashboardPaddingLeftRight,
      paddingRight: dashboardPaddingLeftRight,
    },
  });

type Props = WithStyles<typeof styles>;

const DashboardComponent: React.FunctionComponent<Props> = ({ children, classes }) => {
  return (
    <div className={classes.root}>
      <Sidebar className={classes.sidebar} />
      <div className={classes.container}>{children}</div>
    </div>
  );
};

export const Dashboard = withStyles(styles)(DashboardComponent);
