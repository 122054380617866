import React, { Fragment } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useTranslator } from '../i18n/useTranslator';
import { List } from './List';
import { Link } from './Link';
import { RootState } from '../store/rootReducer';
import { RoleEnum } from '../shared/enums/RoleEnum';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & {
  rows: Array<any>;
};

const GrantImplementationRankingListComponent: React.FunctionComponent<Props> = ({ rows }) => {
  const { t } = useTranslator();
  const { role } = useSelector((state: RootState) => state.user);

  const getRolePath = () => {
    switch (role) {
      case RoleEnum.BoardMember:
        return 'board-member';
      case RoleEnum.FinanceCoordinator:
        return 'finance-coordinator';
      case RoleEnum.ScientificCouncilMember:
        return 'scientific-council-member';
      case RoleEnum.CompetitionCommitteeMember:
        return 'competition-committee-member';
      case RoleEnum.CompetitionJuryMember:
        return 'competition-jury-member';
      case RoleEnum.Admin:
        return 'admin';
      case RoleEnum.CompetitionCoordinator:
        return 'competition-coordinator';
    }

    return '';
  };

  const listHeader = [
    {
      key: 'authorName',
      text: t('authorName'),
    },    
    {
      key: 'projectName',
      text: t('projectName'),
    },
    {
      key: 'unitInfo',
      text: t('unitInfo'),
    },
    {
      key: 'estimatedFinishTime',
      text: t('estimatedFinishTime'),
    },
    {
      key: 'estimatedFunds',
      text: t('estimatedFunds'),
    },
    {
      key: 'reviewers',
      text: t('reviewers'),
    },
    {
      key: 'stage',
      text: t('stage'),
    }
  ];

  const mapRowsToListRows = () =>
    rows.map((row) => ({
      id: row.id,
      items: [
        {
          key: 'authorName',
          content: <Link to={`/${getRolePath()}/submissions/${row.id}`}>{`${row.author.firstName} ${row.author.lastName}`}</Link>,
        },
        {
          key: 'projectName',
          content: row.project.title || '-',
        },
        {
          key: 'unitInfo',
          content: row.project.scientificFacility || '-',
        },
        {
          key: 'estimatedFinishTime',
          content: row.project.duration,
        },
        {
          key: 'estimatedFunds',
          content: row.project.cost,
        },
        {
          key: 'reviewers',
          content: row.reviewers !== undefined ? row.reviewers.split(',').map((item: any) => {
            return <Fragment>{item}<br/></Fragment>
          }
          ) : '-',
        },
        {
          key: 'stage',
          content: row.stage ? `${row.stage}` : '-',
        }       
      ],
    }));

  return (
    <div>
      <List headerCells={listHeader} rows={mapRowsToListRows()} />
    </div>
  );
};

export const GrantImplementationRankingList = withStyles(styles)(GrantImplementationRankingListComponent);
