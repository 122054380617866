import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import { List } from '../../List';
import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { Box } from '../../Box';
import { UserService } from '../../../services/User.service';
import { User } from '../../../shared/types/User';
import { Message } from '../../Message';
import { ErrorService } from '../../../services/Error.service';
import { BoxLoader } from '../../BoxLoader';

const styles = () =>
  createStyles({});

type Props = WithStyles<typeof styles>;

const ReviewersScreenComponent: React.FunctionComponent<Props> = () => {
  const [serverError, setServerError] = useState('');
  const [users, setUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslator();

  const listHeader = [
    {
      key: 'fullName',
      text: t('fullName')
    },
    {
      key: 'email',
      text: t('email')
    },
    {
      key: 'phoneNumber',
      text: t('phone')
    },
    {
      key: 'scientificFacility',
      text: t('scienceUnit')
    },
    {
      key: 'specialty',
      text: t('specialty')
    }
  ];

  const mapRowsToListRows = () =>
    users.map((user) => ({
      id: user.userId,
      items: [
        {
          key: 'fullName',
          content: `${user.firstName} ${user.lastName}`
        },
        {
          key: 'email',
          content: user.email
        },
        {
          key: 'phoneNumber',
          content: user.phoneNumber
        },
        {
          key: 'scientificFacility',
          content: user.scientificFacility
        },
        {
          key: 'specialty',
          content: user.specialty
        }
      ]
    }));

  const fetchReviewers = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result }
        } = await UserService.getReviewersGrid();

        setUsers(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }

      setLoading(false);
    })();
  };

  useEffect(fetchReviewers, []);

  return (
    <div>
      <SectionHeader title={t('reviewers')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
          <Box> {loading ? 
            <BoxLoader /> : 
            <List headerCells={listHeader} rows={mapRowsToListRows()} />} 
          </Box>
        )}
    </div>
  );
};

export const ReviewersScreen = withStyles(styles)(ReviewersScreenComponent);