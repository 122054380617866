import React from 'react';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import Color from 'color';

const styles = ({ custom, palette }: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${Color(palette.primary.main).alpha(0.2).rgb().string()}`,
      position: 'relative',
      borderRadius: custom.radius.r_0,
      marginTop: 10,
      '&:not(:last-child)': {
        marginBottom: 30,
      },
    },
    title: {
      padding: '0 10px',
      backgroundColor: palette.common.white,
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 12,
      position: 'absolute',
      letterSpacing: 0.4,
      top: -8,
      left: 10,
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
  title?: string;
};

const BoxSectionComponent: React.FunctionComponent<Props> = ({ classes, className, children, title }) => {
  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
};

export const BoxSection = withStyles(styles)(BoxSectionComponent);
