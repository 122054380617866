import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import { Form } from '../Form';
import { FieldTextField } from '../form-fields/FieldTextField';
import { useTranslator, TFunction } from '../../i18n/useTranslator';
import { Button } from '../Button';
import { FormContent } from '../FormContent';
import { email } from '../../shared/validators';
import { ErrorService } from '../../services/Error.service';
import { UserService } from '../../services/User.service';
import { FormError } from '../FormError';
import classNames from 'classnames';

const styles = () =>
  createStyles({
    root: {
      textAlign: "left",
      padding: "0 0 10px 0",
    },
  });

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

type FormValues = {
  email: string;
};

const ResetPasswordFormComponent: React.FunctionComponent<Props> = ({ history, classes }) => {
  const [serverError, setServerError] = useState('');

  const { t } = useTranslator();

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      email: email(t),
    });

  const initialValues = {
    email: ''
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setServerError('');
    setSubmitting(true);

    try {
      await UserService.resetPasswordByEmailToken({email: values.email});
      console.log(values.email);
      history.push('/password/reset/email', { email: values.email });
      
    } catch (error) {
      setServerError(ErrorService.parseError(error));
      console.log(error);
    }

    setSubmitting(false);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <FormContent>

          <div className={classNames(classes.root)}>
            {t('resetPasswordScreenMessage')}
          </div>
          
          <FieldTextField name={'email'} label={t('inputEmailAddress')} type={'email'} />

          <FormError show={Boolean(serverError)} error={t(serverError)} />

          <Button type={'submit'} loading={isSubmitting}>
            {t('send')}
          </Button>
        </FormContent>
      )}
    </Form>
  );
};

export const ResetPasswordForm = withStyles(styles)(withRouter(ResetPasswordFormComponent));
