import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid, FormControlLabel, Checkbox } from '@material-ui/core';

import { TextField } from './inputs/TextField';
import { FileInput } from './inputs/FileInput';
import { Button } from './Button';
import { useTranslator } from '../i18n/useTranslator';
import { Select } from './inputs/Select';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { Autocomplete } from './inputs/Autocomplete';
import { BillingMethodEnum } from '../shared/enums/BillingMethodEnum';
import { AttachmentTemplate } from '../shared/types/AttachmentTemplate';

const styles = () =>
  createStyles({
    newDocumentRow: {
      marginTop: 20,
    },
    newDocumentRowButtons: {
      marginTop: 10,
    },
    newDocumentRowCancelButton: {
      marginLeft: 5,
    },
    checkboxWrapper: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      padding: '7px 10.5px'
    },
    checkbox: {
      padding: '0 7px 0 0'
    },
    checkboxFormControlLabel: {
      margin: 0
    }
  });

type Props = WithStyles<typeof styles> & {
  billingMethod?: BillingMethodEnum;
  addFileServiceHandler: (file: FormData) => Promise<void>;
  onNewFileAdded: () => void;
};

const AddNewAttachmentTemplateComponent: React.FunctionComponent<Props> = ({ 
  classes,
  billingMethod,
  onNewFileAdded,
  addFileServiceHandler
}) => {
  const defaultVisibilityRoles: RoleEnum[] = [
    RoleEnum.ScientificCouncilMember,
    RoleEnum.BoardMember,
    RoleEnum.CompetitionCommitteeMember,
    RoleEnum.CompetitionJuryMember
  ];

  const defaultAttachmentTemplate: AttachmentTemplate = {
    id: '',
    name: '',
    role: RoleEnum.Author,
    isRequired: true,
    specimen: null,
    visibilityRoles: defaultVisibilityRoles,
    isForAllReviews: false
  };

  const [isAddingNewDocument, setIsAddingNewDocument] = useState(false);
  const [savingNewDocumentFile, setSavingDocumentFile] = useState(false);
  const [newAttachmentTemplate, setNewAttachmentTemplate] = useState<AttachmentTemplate>(defaultAttachmentTemplate);

  const { t } = useTranslator();

  useEffect(() => {
    if (billingMethod === BillingMethodEnum.PerCompetition) {
      return;
    }

    setNewAttachmentTemplate({
      ...newAttachmentTemplate,
      isForAllReviews: false
    })
  }, [billingMethod])

  const handleAddNewDocument = async () => {
    setSavingDocumentFile(true);

    const formData = new FormData();

    formData.append('name', newAttachmentTemplate.name);
    formData.append('role', newAttachmentTemplate.role!);
    formData.append('isRequired', newAttachmentTemplate.isRequired!.toString());
    formData.append('isForAllReviews', newAttachmentTemplate.isForAllReviews.toString());

    newAttachmentTemplate.visibilityRoles.forEach(r => formData.append('visibilityRoles', r));

    if (newAttachmentTemplate.specimen) {
      formData.append('specimenFile', newAttachmentTemplate.specimen);
    }

    await addFileServiceHandler(formData);

    setSavingDocumentFile(false);
    setIsAddingNewDocument(false);
    setNewAttachmentTemplate(defaultAttachmentTemplate);

    onNewFileAdded();
  };

  const handleCancelAddNewDocument = () => {
    setNewAttachmentTemplate(defaultAttachmentTemplate);
    setIsAddingNewDocument(false);
  };

  const handleVisibilityRolesChange = async (event: React.ChangeEvent<{}>, value: Array<RoleEnum>) => {
    setNewAttachmentTemplate({
      ...newAttachmentTemplate,
      visibilityRoles: value
    });
  };

  return isAddingNewDocument ? (
    <div className={classes.newDocumentRow}>
      <Grid container>
        <Grid container spacing={2} item xs={9} alignContent={'flex-start'}>
          <Grid item xs={4}>
            <TextField
              label={t('documentName')}
              fullWidth
              size={'small'}
              value={newAttachmentTemplate.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewAttachmentTemplate({ ...newAttachmentTemplate, name: e.target.value })}
            />
          </Grid>
          <Grid container spacing={2} item xs={8}>
            <Grid item xs={4}>
              <FileInput
                label={t('template')}
                fullWidth
                value={newAttachmentTemplate.specimen ?? null}
                onChange={(value: File | null) => setNewAttachmentTemplate({ ...newAttachmentTemplate, specimen: value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                name={'role'}
                label={t('role')}
                fullWidth
                size={'small'}
                value={newAttachmentTemplate.role}
                items={[
                  { label: t('Author'), value: RoleEnum.Author },
                  { label: t('Reviewer'), value: RoleEnum.Reviewer },
                ]}
                onChange={(e: React.ChangeEvent<any>) => setNewAttachmentTemplate({ ...newAttachmentTemplate, role: e.target.value })}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                name={'isRequired'}
                label={t('isRequired')}
                fullWidth
                size={'small'}
                value={newAttachmentTemplate.isRequired}           
                items={[
                  { label: t('isRequired'), value: "true" },
                  { label: t('notRequired'), value: "false" },
                ]}
                onChange={(e: React.ChangeEvent<any>) => setNewAttachmentTemplate({ ...newAttachmentTemplate, isRequired: e.target.value })}
              />          
            </Grid>
          </Grid>
          {billingMethod === BillingMethodEnum.PerCompetition &&
          newAttachmentTemplate.role === RoleEnum.Reviewer && (
            <Grid item xs={4}>
              <div className={classes.checkboxWrapper}>
                <FormControlLabel
                  className={classes.checkboxFormControlLabel}
                  control={
                    <Checkbox
                      defaultChecked={newAttachmentTemplate.isForAllReviews}
                      onChange={(e) => setNewAttachmentTemplate({ ...newAttachmentTemplate, isForAllReviews: e.target.checked })}
                      className={classes.checkbox}
                    />}
                  label={t('documentForAllReviews')} 
                />
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            multiple
            options={[
              RoleEnum.ScientificCouncilMember,
              RoleEnum.BoardMember,
              RoleEnum.CompetitionCommitteeMember,
              RoleEnum.CompetitionJuryMember
            ]}
            getOptionLabel={(option) => `${t(option)}`}
            renderOption={(option) => (
              <span>
                {t(option)}
              </span>
            )}
            renderInput={(params) => <TextField {...params} label={t('visibleForRoles')} />}
            value={newAttachmentTemplate.visibilityRoles}
            onChange={handleVisibilityRolesChange}
          />    
        </Grid>

        
      </Grid>
      <div className={classes.newDocumentRowButtons}>
        <Button size={'small'} loading={savingNewDocumentFile} disabled={savingNewDocumentFile} onClick={handleAddNewDocument}>
          {t('addDocument')}
        </Button>
        <Button className={classes.newDocumentRowCancelButton} size={'small'} variant={'outlined'} onClick={handleCancelAddNewDocument}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  ) : (
    <Button size={'small'} onClick={() => setIsAddingNewDocument(true)}>
      {t('addDocument')}
    </Button>
  );
};

export const AddNewAttachmentTemplate = withStyles(styles)(AddNewAttachmentTemplateComponent);
