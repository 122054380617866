import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, Switch } from 'react-router';

import { ScientificCouncilMemberRoute } from '../../routes/ScientificCouncilMemberRoute';
import { DashboardScreenWrapper } from '../../DashboardScreenWrapper';
import { ScientificCouncilMemberCompetitionsScreen } from '../ScientificCouncilMember/ScientificCouncilMemberCompetitionsScreen';
import { CompetitionRankingListScreen } from '../Common/CompetitionRankingListScreen';
import { SubmissionDetailsScreen } from '../Common/ScientificCouncilMemberSubmissionDetailsScreen';
import { ReviewersScreen } from '../Common/ReviewersScreen';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & RouteComponentProps<void>;

const ScientificCouncilMemberScreensComponent: React.FunctionComponent<Props> = ({ match }) => {
  return (
    <DashboardScreenWrapper>
      <Switch>
        <ScientificCouncilMemberRoute exact path={`${match.path}/competitions`} component={ScientificCouncilMemberCompetitionsScreen} />
        <ScientificCouncilMemberRoute
          exact
          path={`${match.path}/competitions/:id/ranking-list`}
          component={CompetitionRankingListScreen}
        />
        <ScientificCouncilMemberRoute
          exact
          path={`${match.path}/submissions/:id`}
          component={SubmissionDetailsScreen}
        />
        <ScientificCouncilMemberRoute
          exact
          path={`${match.path}/reviewers`}
          component={ReviewersScreen}
        />
      </Switch>
    </DashboardScreenWrapper>
  );
};

export const ScientificCouncilMemberScreens = withStyles(styles)(ScientificCouncilMemberScreensComponent);
