import React, { useEffect, useState } from 'react';
import { Route, RouteProps, withRouter, RouteComponentProps } from 'react-router-dom';

type Props = RouteProps & RouteComponentProps<void>;

const PrivateRouteComponent: React.FunctionComponent<Props> = ({ component: Component, history, ...restProps }) => {
  const [isUserLoggedIn] = useState(true);

  useEffect(() => {
    if (!isUserLoggedIn) {
      history.push('/login');
    }
  });

  return <Route {...restProps} render={(props) => (Component ? <Component {...props} /> : null)} />;
};

export const PrivateRoute = withRouter(PrivateRouteComponent);
