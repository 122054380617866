import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import moment from 'moment';

import { SectionHeader } from '../../SectionHeader';
import { Box } from '../../Box';
import { useTranslator } from '../../../i18n/useTranslator';
import { SubmissionsList } from '../../SubmissionsList';
import { SubmissionService } from '../../../services/Submissions.service';
import { SimpleSubmission } from '../../../shared/types/SimpleSubmission';
import { ErrorService } from '../../../services/Error.service';
import { BoxLoader } from '../../BoxLoader';
import { Message } from '../../Message';
import { DateService } from '../../../services/Date.service';
import { Link } from '../../Link';
import { SubmissionStatusEnum } from '../../../shared/enums/SubmissionStatusEnum';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles>;

const AuthorSubmissionsScreenComponent: React.FunctionComponent<Props> = () => {
  const [serverError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState<Array<SimpleSubmission>>([]);
  const { t } = useTranslator();

  const mapRowsToListRows = () =>
    submissions.map((submission) => ({
      id: submission.id,
      items: [
        {
          key: 'submissionName',
          content: <Link to={
            submission.status === SubmissionStatusEnum.Autosaved && submission.isCompetitionActive
              ? `/author/competitions/${submission.competitionId}/submit`
              : `/author/submissions/${submission.id}`
          }>{submission.competitionName}</Link>,
        },
        {
          key: 'submissionDate',
          content: <div>{moment(submission.createdAt).format(DateService.DATE_FORMAT)}</div>,
        },
      ],
    }));

  const fetchSubmissions = () => {
    (async () => {
      setServerError('');
      setLoading(true);

      try {
        const {
          data: { result },
        } = await SubmissionService.getMy();

        setSubmissions(result);
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      }
      setLoading(false);
    })();
  };

  useEffect(fetchSubmissions, []);

  return (
    <div>
      <SectionHeader title={t('submissions')} />
      {serverError ? (
        <Message type={'ERROR'}>{t(serverError)}</Message>
      ) : (
        <Box>{loading ? <BoxLoader /> : <SubmissionsList rows={mapRowsToListRows()} />}</Box>
      )}
    </div>
  );
};

export const AuthorSubmissionsScreen = withStyles(styles)(AuthorSubmissionsScreenComponent);
