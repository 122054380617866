import React, { useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import { useTranslator } from '../../../i18n/useTranslator';
import { SectionHeader } from '../../SectionHeader';
import { Box } from '../../Box';
import { NotificationTemplateService } from '../../../services/NotificationTemplate.service';
import { NotificationTemplate } from '../../../shared/types/NotificationTemplate';
import { EmailTemplateForm } from '../../forms/EmailTemplateForm';
import { NotificationTypeEnum } from '../../../shared/enums/NotificationTypeEnum';

const styles = () =>
  createStyles({
    legendTitle: {
      marginBottom: 10,
    },
    legendItem: {
      fontWeight: 600,
      display: 'inline-block',
      marginBottom: 10,
      '&:not(:last-child)': {
        marginRight: 10,
      },
    },
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{
    type: string;
  }>;

const AdminEmailTemplateDetailsScreenComponent: React.FunctionComponent<Props> = ({ classes, match }) => {
  const [template, setTemplate] = useState<NotificationTemplate>();
  const legends: { [key: string]: Array<string> } = {
    [NotificationTypeEnum.Invalid]: ['ApplicationUrl'],
    [NotificationTypeEnum.UserRegistered]: ['RegisteredUserEmail', 'RegisteredUserName', 'ApplicationUrl'],
    [NotificationTypeEnum.UserGenerated]: ['NewPassword', 'ApplicationUrl'],
    [NotificationTypeEnum.UserPasswordChanged]: ['ApplicationUrl'],
    [NotificationTypeEnum.UserPasswordReset]: ['NewPassword','ApplicationUrl'],
    [NotificationTypeEnum.EmailConfirmationCodeGenerated]: ['ActivationLink', 'ApplicationUrl'],
    [NotificationTypeEnum.CommentAdded]: ['Id', 'SubmissionId', 'Edition', 'CompetitionName', 'Comment', 'ApplicationUrl'],
    [NotificationTypeEnum.SubmissionCreated]: ['AuthorFirstName', 'AuthorLastName', 'CompetitionName', 'ApplicationUrl'],
    [NotificationTypeEnum.SubmissionRejected]: ['CompetitionName', 'ApplicationUrl'],
    [NotificationTypeEnum.SubmissionAccepted]: ['CompetitionName', 'ApplicationUrl'],
    [NotificationTypeEnum.SubmissionClosed]: ['CompetitionName', 'ApplicationUrl'],
    [NotificationTypeEnum.SubmissionReviewerAssigned]: ['AuthorFirstName', 'AuthorLastName', 'CompetitionName', 'ApplicationUrl'],
    [NotificationTypeEnum.SubmissionCreatedUser]: ['AuthorFirstName', 'AuthorLastName', 'CompetitionName', 'ApplicationUrl'],
    [NotificationTypeEnum.OtherAttachmentAdded]: ['Id', 'SubmissionId', 'Edition', 'CompetitionName', 'ApplicationUrl'],
    [NotificationTypeEnum.UserPasswordResetByEmail]: ['NewPasswordLink', 'ApplicationUrl']
  };

  const { t } = useTranslator();

  const fetchTemplate = () => {
    (async () => {
      const {
        data: { result },
      } = await NotificationTemplateService.getOne(match.params.type);

      setTemplate(result);
    })();
  };

  useEffect(fetchTemplate, []);

  return (
    <div>
      <SectionHeader title={t('templateDetails')} />
      {template ? (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box>
              <EmailTemplateForm templateId={template.id} initValues={template} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <div className={classes.legendTitle}>Lista dynamicznie uzupełnianych wartości:</div>
              <div>
                {legends[template.type].map((item) => (
                  <span className={classes.legendItem}>[{item}]</span>
                ))}
                {!legends[template.type].length ? <strong>{t('none')}</strong> : null}
              </div>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export const AdminEmailTemplateDetailsScreen = withStyles(styles)(withRouter(AdminEmailTemplateDetailsScreenComponent));
