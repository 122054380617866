import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import MaterialButton, { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import H from 'history';
import classNames from 'classnames';

import { Link } from './Link';
import { CircularProgress } from './CircularProgress';

const styles = () =>
  createStyles({
    sizeSmall: {
      fontSize: 12,
    },
  });

type Props = MaterialButtonProps &
  WithStyles<typeof styles> & {
    to?: H.LocationDescriptor;
    loading?: boolean;
    size?: 'small' | 'medium' | 'large';
  };

const ButtonComponent: React.FunctionComponent<Props> = ({ to, loading, disabled, children, size, classes, ...restProps }) => {
  const defaultProps = {
    variant: 'contained' as 'contained',
    color: 'primary' as 'primary',
  };

  const loader = <CircularProgress thickness={5} size={24} />;

  return to ? (
    <Link clearStylings to={to}>
      <MaterialButton
        className={classNames({ [classes.sizeSmall]: size === 'small' })}
        disabled={loading || disabled}
        size={size}
        {...defaultProps}
        {...restProps}
      >
        {loading ? loader : children}
      </MaterialButton>
    </Link>
  ) : (
    <MaterialButton
      className={classNames({ [classes.sizeSmall]: size === 'small' })}
      disabled={loading || disabled}
      size={size}
      {...defaultProps}
      {...restProps}
    >
      {loading ? loader : children}
    </MaterialButton>
  );
};

export const Button = withStyles(styles)(ButtonComponent);
