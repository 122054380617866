import React, { useState, useRef, useEffect } from 'react';
import { createStyles, WithStyles, withStyles, Grid, Divider } from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { BoxSection } from './BoxSection';
import { LabelValue } from './LabelValue';
import { useTranslator, TFunction } from '../i18n/useTranslator';
import { SubmissionDocumentation } from './SubmissionDocumentation';
import { Button } from './Button';
import { Competition } from '../shared/types/Competition';
import { DateService } from '../services/Date.service';
import { RootState } from '../store/rootReducer';
import { UserService } from '../services/User.service';
import { SubmissionService } from '../services/Submissions.service';
import { ErrorService } from '../services/Error.service';
import { FormError } from './FormError';
import { SubmissionForm, SubmissionFormValues } from './forms/SubmissionForm';
import { Submission } from '../shared/types/Submission';
import { SubmissionStatusEnum } from '../shared/enums/SubmissionStatusEnum';
import { Form } from "./Form";
import { FormContent } from "./FormContent";
import * as Yup from 'yup';
import { projectName, unitInfo, academicTitle as academicTitleValidator, estimatedFinishTime, estimatedFunds, acceptedRequiredConsent } from '../shared/validators';
import { CompetitionCategoryEnum } from '../shared/enums/CompetitionCategoryEnum';
import { RoleEnum } from '../shared/enums/RoleEnum';
import { SubmissionProject } from '../shared/types/SubmissionProject';
import { Autosave } from './Autosave';
import { AxiosResponse } from 'axios';
import { SubmissionAttachment } from '../shared/types/SubmissionAttachment';
import { AttachmentStatusEnum } from '../shared/enums/AttachmentStatusEnum';
import { AttachmentTemplate } from '../shared/types/AttachmentTemplate';
import { FormikHelpers } from 'formik';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    section: {},
    row: {
      marginBottom: 12,
    },
    error: {
      marginTop: 10,
      marginBottom: 0,
    },    
    sectionsContainer: {
      marginBottom: 20,
    },
    sendDocumentsButton: {
      alignSelf: 'flex-end',
      marginTop: 20,
    }, 
    sendButton: {
      alignSelf: 'flex-end',
    },
  });

  type SubmissionDetailsForm = SubmissionFormValues & {
    submissionId: Submission["id"];
  };

type Props = WithStyles<typeof styles> &
  RouteComponentProps<void> & {
    attachmentTemplates?: Array<AttachmentTemplate>;
    competition: Competition;
    submission?: Submission;
    attachments: Array<SubmissionAttachment>;
    onChanged?: () => void;
  };

const SubmitSubmissionComponent: React.FunctionComponent<Props> = ({
  classes,
  competition,
  history,
  attachmentTemplates,
  attachments,
  submission,
  onChanged
}) => {
  const [attachmentsToUpload, setAttachmentsToUpload] = useState<Map<string, File | null>>(new Map());
  const [shouldValidateFiles, setShouldValidateFiles] = useState(false);
  const [serverError, setServerError] = useState('');
  const [submissionId, setSubmissionId] = useState(submission?.id);
  const [autosaving, setAutosaving] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState<SubmissionDetailsForm>();

  const initialAttachments = useRef<Map<string, File | null>>(new Map()).current;
  
  const { t } = useTranslator();
  const { firstName, lastName, email, phoneNumber, scientificFacility } = useSelector((state: RootState) => state.user);

  const [initialValues, setInitialValues] = useState<SubmissionDetailsForm>({
    submissionId: submission ? submission.id : '',
    projectName: submission ? submission.project.title : '',
    unitInfo: submission ? submission.project.scientificFacility : '',
    estimatedFinishTime: submission?.project.duration,
    estimatedFunds: submission?.project.cost,
    academicTitle: submission ? submission.project.academicTitle : '',
    requiredConsent: competition.requiredConsent,
    acceptedRequiredConsent: submission ? submission.project.acceptedRequiredConsent : false
  });

  const handleFileChange = (templateId: string, file: File | null) => {
    setAttachmentsToUpload(map => new Map(map.set(templateId, file)));
  };

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      projectName: projectName(t),
      unitInfo: unitInfo(t),
      academicTitle: academicTitleValidator(t),
      estimatedFinishTime: estimatedFinishTime(t, competition.category === CompetitionCategoryEnum.Project),
      estimatedFunds: estimatedFunds(t, competition.category === CompetitionCategoryEnum.Project),
      acceptedRequiredConsent: acceptedRequiredConsent(t, competition.category === CompetitionCategoryEnum.Person)
    });

  const hasInvalidDocuments = () => {
    let invalid = attachmentTemplates?.some(template =>
      !attachmentsToUpload.get(template.id) && 
      !attachments.find(attach => attach.templateId)?.attachments.length &&
      template.isRequired);
    
    invalid = invalid || attachments.some(attachment =>
      !attachmentsToUpload.get(attachment.templateId) &&
      !attachment.attachments.length &&
      attachment.isRequired);

    return invalid;
  };

  const saveAttachments = async (submissionId: Submission['id']) => {
    const promises: Promise<AxiosResponse<any>>[] = [SubmissionService.submitAttachments(submissionId)];

    attachmentsToUpload.forEach((file, templateId) => {
      if (!file) {
        return;
      }
      const formData = new FormData();

      formData.append('file', file);
      formData.append('parentId', submissionId);
      formData.append('templateId', templateId);
      formData.append('status', AttachmentStatusEnum.Submitted.toString());

      promises.push(SubmissionService.addFile(formData));
    });

    return Promise.all(promises);
  };

  const handleSubmissionDetailsSubmit = async (data: SubmissionDetailsForm): Promise<string> => {
    const submissionProject = {
      title: data.projectName,
      scientificFacility: data.unitInfo,
      academicTitle: data.academicTitle,
      duration: data.estimatedFinishTime === "" ? null : data.estimatedFinishTime,
      cost: data.estimatedFunds === "" ? null : data.estimatedFunds,
      requiredConsent: data.requiredConsent,
      acceptedRequiredConsent: data.acceptedRequiredConsent
    } as SubmissionProject;
    
    const {
      data: {
        result: { id },
      },
    } = await SubmissionService.add(competition.id, submissionProject);

    return id;
  };

  const handleSaveSubmission = () => {
    (async () => {
      if (!dataToSubmit || autosaving) {
        return;
      }

      try {
        const id = await handleSubmissionDetailsSubmit(dataToSubmit);

        await saveAttachments(id);
        await SubmissionService.notifyCreated(id);

        history.push('/author/submissions');
      } catch (error) {
        setServerError(ErrorService.parseError(error));
      } finally {
        setDataToSubmit(undefined);
      }
    })();
  };

  useEffect(handleSaveSubmission, [dataToSubmit, autosaving]);

  const handleFormSubmit = (data: SubmissionDetailsForm, { setSubmitting }: FormikHelpers<SubmissionDetailsForm>) => {
    setShouldValidateFiles(true);
    if (hasInvalidDocuments() || autosaving) {
      setSubmitting(false);
      return;
    }

    setServerError("");
    setDataToSubmit(data);
  };

  const handleSubmissionDetailsAutosave = async (data: SubmissionDetailsForm): Promise<string> => {
    const submissionProject = {
      title: data.projectName,
      scientificFacility: data.unitInfo,
      academicTitle: data.academicTitle,
      duration: data.estimatedFinishTime === "" ? null : data.estimatedFinishTime,
      cost: data.estimatedFunds === "" ? null : data.estimatedFunds,
      requiredConsent: data.requiredConsent,
      acceptedRequiredConsent: data.acceptedRequiredConsent
    } as SubmissionProject;
    
    const {
      data: {
        result: { id },
      },
    } = await SubmissionService.autosave(competition.id, submissionProject);

    return id;
  };

  const handleAutosaveSubmission = async (
    data?: SubmissionDetailsForm,
    attachmentsToAdd?: { key: string; value: File | null; }[]
  ) => {
    setAutosaving(true);

    try {
      let id = submissionId;
      if (data) {
        id = await handleSubmissionDetailsAutosave(data);
        setInitialValues(data);
      }
      else if (!id) {
        id = await handleSubmissionDetailsAutosave(initialValues);
      }

      setSubmissionId(id);

      await autosaveAttachments(id, attachmentsToAdd);
    }
    finally {
      setAutosaving(false);
    }
  };

  const autosaveAttachments = async (
    submissionId: string,
    attachmentsToAdd?: { key: string; value: File | null; }[]
  ) => {
    if (!attachmentsToAdd) {
      return;
    }

    const promises: Promise<AxiosResponse<any>>[] = [];

    attachmentsToAdd.forEach(file => {
      if (!file.value) {
        return;
      }

      const formData = new FormData();

      formData.append('file', file.value);
      formData.append('parentId', submissionId);
      formData.append('templateId', file.key);
      formData.append('status', AttachmentStatusEnum.Autosaved.toString());

      promises.push(SubmissionService.addFile(formData));
    });

    await Promise.all(promises);
    
    if (promises.length) {
      onChanged && onChanged();
      setAttachmentsToUpload(map => {
        attachmentsToAdd.forEach(attachment => {
          map.delete(attachment.key);
        });
        return map;
      });
    }
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.sectionsContainer}>
        <BoxSection className={classes.section} title={t('competitionDetails')}>
          <Grid className={classes.row} container spacing={2}>
            <Grid item xs={6}>
              <LabelValue label={t('competitionName')} value={competition.name} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabelValue label={t('startDate')} value={moment(competition.startDate).format(DateService.DATE_FORMAT)} />
            </Grid>
            <Grid item xs={6}>
              <LabelValue label={t('endDate')} value={moment(competition.endDate).format(DateService.DATE_FORMAT)} />
            </Grid>
          </Grid>
        </BoxSection>
        <BoxSection className={classes.section} title={t('participant')}>
          <Grid className={classes.row} container spacing={2}>
            <Grid item xs={6}>
              <LabelValue label={t('firstName')} value={firstName} />
            </Grid>
            <Grid item xs={6}>
              <LabelValue label={t('lastName')} value={lastName} />
            </Grid>
          </Grid>
          <Grid className={classes.row} container spacing={2}>
            <Grid item xs={6}>
              <LabelValue label={t('email')} value={email} />
            </Grid>
            <Grid item xs={6}>
              <LabelValue label={t('phone')} value={phoneNumber || '-'} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabelValue label={t('scienceUnit')} value={scientificFacility || '-'} />
            </Grid>
          </Grid>
        </BoxSection>
        <BoxSection className={classes.section} title={t("submission")}>
          <Form
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema(t)}
          >
            {() => (
              <FormContent>
                  <React.Fragment>
                    <SubmissionForm competitionCategory={competition.category} />
                    <Divider />
                  </React.Fragment>
                {(Boolean(attachments.length) || attachmentTemplates) && (
                  <SubmissionDocumentation
                    shouldValidate={shouldValidateFiles}
                    attachments={attachmentsToUpload}
                    submissionAttachments={attachments}
                    attachmentTemplates={attachmentTemplates}
                    submissionStatus={submission?.status ?? SubmissionStatusEnum.Autosaved}
                    submissionId={submissionId}
                    competetionIsActive={true}
                    docsAssignedTo={RoleEnum.Author}
                    onFileChange={handleFileChange}
                    onAttachmentRemoved={onChanged}
                  />
                )}
                <FormError
                  className={classes.error}
                  show={Boolean(serverError)}
                  error={serverError}
                />
                <Button
                  className={classes.sendDocumentsButton}
                  loading={Boolean(dataToSubmit)}
                  disabled={Boolean(dataToSubmit)}
                  onClick={() => setShouldValidateFiles(true)}
                  type="submit"
                >
                  {t("send")}
                </Button>
                <div className={classes.sendDocumentsButton}>
                  {t("requiredInfo")}
                </div>
                
                <Autosave
                  initialAttachments={initialAttachments}
                  currentAttachments={attachmentsToUpload}
                  submitting={Boolean(dataToSubmit)}
                  onSave={handleAutosaveSubmission}
                />
              </FormContent>
            )}
          </Form>
        </BoxSection>
      </div>
      <FormError show={Boolean(serverError)} error={serverError} />
    </div>
  );
};

export const SubmitSubmission = withStyles(styles)(withRouter(SubmitSubmissionComponent));
