import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';

import { FormikCheckbox } from '../formik-controls/FormikCheckbox';
import classNames from 'classnames';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & {
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  additionalText?: string;
};

const FieldCheckboxComponent: React.FunctionComponent<Props> = ({ className, label, name, disabled, additionalText }) => {
  const form = useFormikContext<any>();

  return (
    <Field
      className={classNames('form-control', className)}
      component={FormikCheckbox}
      type={"checkbox"}
      name={name}
      fieldName={name}
      Label={{ label }}
      disabled={disabled}
      error={form.touched[name] ? form.errors[name] : ''}
      additionalText={additionalText}
    />
  );
};

export const FieldCheckbox = withStyles(styles)(FieldCheckboxComponent);
